import { RouteConfig } from "vue-router";

//communication
import CampaignList from "@/views/Communication/Campaign/CampaignList.vue";
import CampaignCreate from "@/views/Communication/Campaign/CampaignCreate.vue";

import CommunicationIndex from "@/views/Communication/CommunicationIndex.vue";
import UrlShortener from "@/views/Communication/UrlShortener.vue";
import Extractor from "@/views/Extractor.vue";
import EmailTemplateList from "@/views/Communication/EmailTemplate/EmailTemplateList.vue";
import EmailTemplateForm from "@/views/Communication/EmailTemplate/EmailTemplateForm.vue";

import SmsTemplateList from "@/views/Communication/SmsTemplate/SmsTemplateList.vue";
import SmsTemplateForm from "@/views/Communication/SmsTemplate/SmsTemplateForm.vue";

//prosepection
import ClientsView from "@/views/Sell/ClientsView.vue";
import HandShakeList from "@/views/Sell/HandShakeList.vue";
import SellDashboard from "@/views/Sell/SellDashboard.vue";
import HandShakeForm from "@/views/Sell/HandShakeForm.vue";
import DetailsClientView from "@/views/Sell/DetailsClientView.vue";
import CompanyView from "@/views/Sell/CompanyView.vue";
import DetailsCompanyView from "@/views/Sell/DetailsCompanyView.vue";

const prefix = "/crm";
const crmRoutes: Array<RouteConfig> = [
  {
    path: "/",
    name: "crm-index",
    component: SellDashboard,
  },

  //prospection
  {
    path: "/handshake/list",
    name: "handshake-list",
    component: HandShakeList,
  },
  {
    path: "/handshake/form/:uuid?/:clientId?/:entrepriseId?",
    name: "handshake-form",
    component: HandShakeForm,
  },

  {
    path: "/prospects",
    name: "prospection-clients",
    component: ClientsView,
  },
  {
    path: "/prospects/:id",
    name: "details-clients",
    component: DetailsClientView,
  },

  {
    path: "/entreprise",
    name: "company",
    component: CompanyView,
  },

  {
    path: "/entreprise/:id",
    name: "detail-company",
    component: DetailsCompanyView,
  },
  //communication
  {
    path: "/sms",
    name: "sms",
    // component: CommunicationIndex,
    component : () => import(/* webpackChunkName: "about" */ '@/views/Communication/Sms/SmsSender.vue'),
  },

  {
    path: "/email/template/list",
    name: "email-template-list",
    component: EmailTemplateList,
  },
  {
    path: "/email/template/form/:uuid?",
    name: "email-template-form",
    component: EmailTemplateForm,
  },
  {
    path: "/template/sms/list",
    name: "sms-template-list",
    component: SmsTemplateList,
  },

  {
    path: "/template/sms/form/:uuid?",
    name: "sms-template-form",
    component: SmsTemplateForm,
  },

  {
    path: "/campaign/list",
    name: "campaign-list",
    component: CampaignList,
  },

  {
    path: "/campaign/create/:id?",
    name: "campaign-create",
    component: CampaignCreate,
  },

  //chat
  {
    path: "/chat-whatsapp",
    name: "chat-whatsapp",
    // component: UserList,
    component: () => import("@/views/Chat/ChatIndex.vue"),
  },

  {
    path: "/url/shortener",
    name: "url-shortener",
    component: UrlShortener,
  },
  {
    path: "/extractor",
    name: "extractor",
    component: Extractor,
  },
];
crmRoutes.forEach((route) => {
  route.path = prefix + route.path;
});
export default crmRoutes;
