<template>
  <div class="d-flex my-1">
    <div class="d-flex border-dashed rounded" style="background: #F5F8FA">
      <div class="cursor-pointer border-right border-1 rounded-left" v-for="option in options"
           :class="{ 'bg-primary fw-bolder' : value === option[property], 'px-5 py-3': size === 'lg','px-2 py-1': size === 'sm'  }"
           @click="setValue(option[property])">
        {{ option[label] }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Component, Prop, Vue} from "vue-property-decorator";


@Component({})
export default class ChoiceInput extends Vue {

  @Prop() value?: boolean
  @Prop({default: 'lg'}) size?: string
  @Prop({default: []}) options?: string
  @Prop() label!: string
  @Prop() property!: string

  setValue(val: boolean) {
    this.$emit('input', val)
  }
}
</script>

<style scoped>

</style>
