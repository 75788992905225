<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <date-range-picker ref="picker" :opens="'left'" :locale-data="localData" :singleDatePicker="false"
                               :autoApply="false" :ranges="ranges" @update="load" :timePicker="false"
                               :showWeekNumbers="true"
                               :showDropdowns="true" v-model="interval">
              <template v-slot:input="picker" style="min-width: 350px;">
                <template>
                  {{ readableDate(picker.startDate, 'dmY') }} ❖ {{ readableDate(picker.endDate, 'dmY') }}
                </template>
              </template>
            </date-range-picker>
          </div>

        </div>
      </div>
    </template>
    <template v-slot:content>
      <div class="d-flex fs-2 justify-content-between align-items-center mb-5">
        <div class="d-flex align-items-center">
        </div>
        <div>
          <div class="btn btn-primary btn-sm mx-1">
            Prime Nette : {{ currency(netSum) }}
          </div>
          <div class="btn btn-primary btn-sm mx-1">
            Prime TTC : {{ currency(ttcSum) }}
          </div>

          <div class="btn btn-success btn-sm mx-1">
            Encaissement : {{ currency(paySum) }}
          </div>

          <div class="btn btn-success btn-sm mx-1">
            Commissions : {{ currency(commissionSum) }}
          </div>


          <div class="btn btn-danger btn-sm mx-1">
            Restant : {{ currency(ttcSum - paySum) }}
          </div>
        </div>
      </div>
      <b-tabs active-nav-item-class="" active-tab-class="bg-white p-5"
              nav-wrapper-class="mt-10  border-0 nav-line-tabs nav-line-tabs-2x fs-5 fw-bolder flex-nowrap flex-grow-1">
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-arrow-alt-up fs-2 me-2"></i>
              Emissions
            </div>
          </template>
          <template #default>
            <div>
              <div class="d-flex justify-content-between mb-5">
                <div class="d-flex align-items-center">
                  <button :disabled="loadingEmission" class="mx-2 btn btn-primary d-flex align-items-center">
                    <b-spinner small label="Spinning" class="me-2" v-if="loadingEmission"></b-spinner>
                    <download-excel :data="emissions" :fields="emissionData"
                                    :worksheet="'Emissions_'+readable(interval.start,'dMy')+'_au_'+readable(interval.end,'dMy')"
                                    :name="'Emissions_'+readable(interval.start,'dMy')+'_au_'+readable(interval.end,'dMy')+'.xls'">
                      Télécharger
                    </download-excel>
                  </button>
                  <b-dropdown right tag="div" variant="secondary" class="mx-2">
                    <template v-slot:button-content>
                      Colonnes
                    </template>
                    <b-dropdown-text class="w-650px mh-500px overflow-auto">
                      <div class="d-flex flex-wrap">
                        <label class="d-flex align-items-center py-2 px-5 w-200px"
                               v-for="(column,index) in emissionData">
                        <span class="form-check form-check-sm form-check-custom me-2 form-check-solid ">
                          <input type="checkbox" v-model="column.show" class="form-check-input widget-9-check"/>
                        </span>
                          <span v-html="index">
                        </span>
                        </label>
                      </div>
                    </b-dropdown-text>
                  </b-dropdown>
                  <b-dropdown right tag="div" variant="secondary" class="mx-2" @hidden="load">
                    <template v-slot:button-content>
                      <b-spinner small label="Spinning" class="me-2" v-if="branchCategories.length === 0"></b-spinner>
                      <span class="badge badge-danger badge-sm">{{ filter.branches.length }}</span>
                      Branches
                    </template>
                    <b-dropdown-text class="w-400px mh-500px overflow-auto p-0">
                      <Loading v-if="branchCategories.length === 0"></Loading>
                      <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="" fill
                              nav-wrapper-class=""
                              v-else>
                        <b-tab :title="cat.label" :title-link-class="'py-4'" :key="cat.id"
                               v-for="cat in branchCategories">
                          <div class="d-flex flex-wrap px-5 py-2">
                            <label class="d-flex align-items-center w-50 py-3 cursor-pointer"
                                   v-for="(branch,index2) in cat.branches">
                        <span class="form-check form-check-sm form-check-custom me-2 form-check-solid ">
                          <input type="checkbox" :checked="filter.branches.includes(branch.slug)"
                                 class="form-check-input widget-9-check" @change="toggleBranch(branch)"/>
                        </span>
                              {{ branch.label }}
                            </label>
                          </div>

                        </b-tab>
                      </b-tabs>

                    </b-dropdown-text>
                  </b-dropdown>
                  <b-dropdown right tag="div" variant="secondary" class="mx-2" @hidden="load">
                    <template v-slot:button-content>
                      <b-spinner small label="Spinning" class="me-2" v-if="insurers.length === 0"></b-spinner>
                      <span class="badge badge-danger badge-sm">{{ filter.insurers.length }}</span>
                      Assureurs
                    </template>
                    <b-dropdown-text class="w-400px mh-500px overflow-auto p-0">
                      <Loading v-if="insurers.length === 0"></Loading>
                      <div class="d-flex flex-wrap px-5 py-2" v-else>
                        <label class="d-flex align-items-center w-50 py-3 cursor-pointer"
                               v-for="(insurer,index2) in insurers">
                        <span class="form-check form-check-sm form-check-custom me-2 form-check-solid ">
                          <input type="checkbox" :checked="filter.insurers.includes(insurer.slug)"
                                 @change="toggleInsurer(insurer)" class="form-check-input widget-9-check"/>
                        </span>
                          {{ insurer.label }}
                        </label>
                      </div>
                    </b-dropdown-text>
                  </b-dropdown>
                  <BooleanInput @input="load" v-model="filter.payed" true-label="Soldé" false-label="Tout"/>
<!--                  <ChoiceInput class="ms-5" label="label" property="code" v-model="filter.repayed"-->
<!--                               :options="[{label:'Reversé',code: true},{label:'Non Reversé',code:false},{label: 'Tout', code:null}]"/>-->
                  <div class="ms-5">
                    <div>
                      <input v-model="maxAmount" @blur="load" placeholder="Montant Reversement" type="number"
                             class="form form-control form-control-lg text-end">
                    </div>
                  </div>
                  <div class="ms-5">
                    <div class="btn btn-primary" @click="load">
                      Filtrer
                    </div>
                  </div>
                </div>


                <div class="d-flex justify-content-between">
                  <button :disabled="iscalculating || loadingEmission"
                          class="btn btn-primary me-2 d-flex align-items-center"
                          @click="launchRecalculation">
                    <b-spinner small label="Spinning" class="me-2" v-if="iscalculating"></b-spinner>
                    Recalculer commissions
                  </button>

                  <b-dropdown right tag="div" variant="secondary" class="mx-2"
                              :disabled="loadingEmission || iscalculating || ids.length === 0">
                    <template v-slot:button-content>
                      <span class="badge badge-danger badge-sm">{{ ids.length }}</span>
                      Reverser
                    </template>
                    <b-dropdown-text class="w-350px mh-500px overflow-auto p-0">
                      <div class="p-10 py-2  fw-bolder text-center mt-5">
                        <div class="fs-2">
                          Reverser {{ ids.length }} avenants
                        </div>
                        <div v-if="filter.insurers.length > 0"
                             class="border border-dashed border-success text-success bg-light-success px-5 py-2 rounded my-5 fs-2">
                          à
                          {{ insurerLabelByUuid(filter.insurers[0]) }}
                        </div>
                        <div v-else
                             class="border border-dashed border-danger text-danger bg-light-danger px-5 py-2 rounded my-5 fs-2">
                          Sélectionnez un assureur
                        </div>
                        <div class="fs-2">
                          Pour un montant de
                        </div>
                        <div
                            class="border border-dashed border-danger text-danger bg-light-danger px-5 py-2 rounded my-5 fs-2">
                          {{ currency(repaymentAmount) }}
                        </div>
                        <div class="my-5">
                          <button
                              :disabled="filter.insurers.length !== 1 || loadingEmission || iscalculating || ids.length === 0"
                              class="btn btn-primary"
                              @click="repay()">
                            Confirmer Reversement
                          </button>
                        </div>
                      </div>
                    </b-dropdown-text>
                  </b-dropdown>


                </div>
              </div>
              <div class="w-100 overflow-auto">
                <table class="table table-striped gy-3 gs-3 position-relative">
                  <thead>
                  <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                    <th>
                      <div class="form-check form-check-sm form-check-custom form-check-solid">
                        <input type="checkbox" :checked="ids.length>0"
                               :class="{'opacity-25' : ids.length !== emissions.length && ids.length !== 0}"
                               class="form-check-input"
                               @click="toggleAll">
                      </div>
                    </th>
                    <th>
                      #
                    </th>
                    <template v-for="(column,index) in emissionData">
                      <th v-if="column.show" :class="[column.class]">
                        <div :class="[column.class,'text-center']"
                             v-html="index">
                        </div>
                      </th>
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr v-for="(avenant,index) in emissions">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid"
                             v-if="avenant.balance===0">
                          <input type="checkbox" :checked="ids.includes(avenant.id)" class="form-check-input"
                                 @change="toggleAvenant(avenant)">
                        </div>
                        <div v-else class="d-flex justify-content-center align-items-center rounded bg-light-danger">
                          <i class="fad fa-times fs-2 text-danger"></i>
                        </div>
                      </td>
                      <td>
                        {{ index + 1 }}
                      </td>

                      <template v-for="(column,index2) in emissionData">
                        <td v-if="column.show"
                            :set="value = column.field ? column.callback(avenant[column.field]) : column.callback(avenant)">
                          <component v-if="value && value.component"
                                     :is="value.component"
                                     v-bind="value.props || {}">
                          </component>
                          <div v-else-if="typeof value === 'number'"
                               :class="[column.class, 'text-end']"
                               v-html="currency(value, false, false)">
                          </div>
                          <div v-else-if="value && value.html"
                               :class="column.class"
                               v-html="value.html">
                          </div>
                          <div v-else
                               :class="column.class"
                               v-html="value">
                          </div>
                        </td>
                      </template>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <div v-if="loadingEmission">
                  <Loading></Loading>
                </div>
              </div>
            </div>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-arrow-alt-down fs-2 me-2"></i>
              Encaissements
            </div>
          </template>
          <template #default>
            <table class="table table-striped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Date</th>
                <th>Type</th>
                <th>Initiateur</th>
                <th>Contrat</th>
                <th>Client</th>
                <th>Montant</th>
                <!-- <th>Reste à payer</th> -->
                <th>Statut</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="loadingPayment">
                <td colspan="12">
                  <Loading></Loading>
                </td>

              </tr>
              <template v-else>
                <tr v-for="payment in payments">
                  <td>
                    {{ readableDate(payment.createdAt, 'dMy H:i') }}
                    <!-- {{ helper.dateToHtml(avenant.dateEmission) }} -->
                  </td>
                  <td>
                    {{ payment.type }}
                  </td>
                  <td>
                    <user-view v-model="payment.initiator"/>

                  </td>
                  <td>
                    <!-- {{payment.avenant.branch}} -->
                    {{ insurerLabelByUuid(payment.avenant.insurer) }}
                    ◆
                    {{ branchLabelByUuid(payment.avenant.branch) }}
                  </td>
                  <td>
                    <!-- {{}} -->
                  </td>
                  <td>
                    {{ currency(payment.amount) }}
                  </td>
                  <td>
                                        <span class="badge" :class="{
                                            'badge-success': payment.status === CONSTANTS.PAYMENT_STATUS.SUCCEED,
                                            'badge-danger': payment.status === CONSTANTS.PAYMENT_STATUS.FAILED,
                                            'badge-secondary': payment.status === CONSTANTS.PAYMENT_STATUS.WAITING,
                                        }">
                                            {{ payment.status }}
                                        </span>

                  </td>
                  <td>

                  </td>

                </tr>
              </template>
              </tbody>
            </table>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-coins fs-2 me-2"></i>
              Relicats
            </div>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-file-contract fs-2 me-2"></i>
              Reversements
            </div>
          </template>

          <template #default>
            <table class="table table-stripped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Date</th>
                <th>Risque</th>
                <th>Assureur</th>
                <th>Police</th>
                <th>Attestation</th>
                <th>Carte Brune</th>
                <th>Assuré</th>
                <th>Effet</th>
                <th>Echéance</th>
                <th>Prime Nette</th>
                <th>Prime TTC</th>
                <th>Payé</th>
                <th>Commission</th>
                <th>Transmis</th>
              </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </template>
        </b-tab>
        <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
          <template #title>
            <div class="d-flex align-items-center ">
              <i class="fad fa-sort-alt fs-2 me-2"></i>
              Bilan
            </div>
          </template>

          <template #default>
            <div style="width:100%;overflow:auto">
              <table class="table table-striped gy-3 gs-3">
                <thead>
                <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                  <th>Date</th>
                  <th>Risque</th>
                  <th>Assureur</th>
                  <th>Police</th>
                  <th>Attestation</th>
                  <th>Carte Brune</th>
                  <th>Assuré</th>
                  <th>Actif</th>
                  <th>Opération</th>
                  <th>Provenance</th>
                  <th>Effet</th>
                  <th>Echéance</th>
                  <th>Prime Nette</th>
                  <th>Prime TTC</th>
                  <th>Payé</th>
                  <th>Reste à Payer</th>
                  <th>Mode de règlement (+ID)</th>
                  <th>Transmis le</th>
                  <th>Versé le</th>
                  <th>Commission</th>
                  <th>Reçu le</th>
                  <th>Mode de paiement</th>
                  <th>Fichiers physiques</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="loadingEmission">
                  <td colspan="14">
                    <Loading></Loading>
                  </td>
                </tr>
                <template v-else>
                  <tr v-for="avenant in emissions">
                    <td>
                      <!-- {{ helper.dateToHtml(avenant.dateEmission) }} -->
                      {{ readableDate(avenant.dateEmission, 'dMy') }}
                    </td>

                    <td>
                      {{ branchLabelByUuid(avenant.branch) }}
                    </td>
                    <td>
                      {{ insurerLabelByUuid(avenant.insurer) }}
                    </td>
                    <td>
                      {{ avenant.police }}
                    </td>
                    <td>
                      <!-- attestation -->
                    </td>
                    <td>
                      <!-- carte brune -->
                    </td>
                    <td>
                      <!-- assuré -->
                      {{ avenant.customer.identifier }}
                    </td>
                    <td>
                      <!-- immatriculation -->
                      {{ avenant.assetLabels.join(',') }}
                    </td>
                    <td>
                      {{ avenant.type }}
                    </td>
                    <td>
                      <user-view v-model="avenant.source"/>
                    </td>
                    <td style="text-align:right">
                      {{ readableDate(avenant.dateEffet, 'dmY') }}
                    </td>
                    <td style="text-align:right">
                      <template v-if="avenant.dateEcheance">
                        {{ readableDate(avenant.dateEcheance, 'dmY') }}
                      </template>
                    </td>
                    <td>
                      {{ currency(primeNette(avenant)) }}
                    </td>
                    <td>
                      {{ currency(avenant.primeTTC) }}
                    </td>
                    <td>
                      {{ currency(avenant.amountPayed) }}
                    </td>
                    <td>
                      {{ currency(avenant.balance, true) }}
                    </td>

                    <td>
                      <!-- mode de reglement -->
                    </td>
                    <td style="text-align:right">
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- versé le -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- commission -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- commission recu le -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- Mode de paiement -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>
                    <td>
                      <!-- fichier physique -->
                      <template v-if="avenant.dateDeclaration">
                        {{ readableDate(avenant.dateDeclaration, 'dMy') }}
                      </template>
                      <template v-else>
                        <i class="fad fa-empty-set"></i>
                      </template>
                    </td>

                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </template>
        </b-tab>
      </b-tabs>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import {api} from "@/services/Api";
import {helper} from "@/services/Helper";
import InputNumber from "@/components/InputNumber.vue";
import Popup from "@/entity/Popup";
import UserView from "@/components/UserView.vue";

import BranchCategory from '@/entity/BranchCategory';
import CONSTANTS from '@/Utils/constant';
import Loading from "@/components/Loading.vue";
import Avenant from '@/entity/Avenant';
import Insurer from '@/entity/Insurer';
import PaymentTransaction from '@/entity/PaymentTransaction';
import AccountOperation from '@/entity/AccountOperation';
import DateRangePicker from "vue2-daterange-picker";
import {h} from "vue";
import Commission from "@/entity/Commission";
import Reduction from "@/entity/Reduction";
import CommissionModel from "@/entity/CommissionModel";
import ReductionModel from "@/entity/ReductionModel";
import axios from "axios";
import User from "@/entity/User";
import PremiumDetailModel from "@/entity/PremiumDetailModel";
import Branch from "@/entity/Branch";
import BooleanInput from "@/components/BooleanInput.vue";
import ChoiceInput from "@/components/ChoiceInput.vue";

@Component({
  components: {ChoiceInput, BooleanInput, InputNumber, Base, Loading, UserView, DateRangePicker}
})
export default class IncomeState extends Vue {
  cotationCount = 0;
  cotationCompleted = 0;
  cotationProcessed = 0;
  contactCount = 0;

  maxAmount: any = null
  filter = {
    startDate: CONSTANTS.dateConstants.first_day_current_month(),
    endDate: CONSTANTS.dateConstants.last_day_current_month(),
    page: 1,
    platform: null as any,
    branches: [] as any[],
    insurers: [] as any[],
    payed: false,
    repayed: null,
    amount: null as any,
  }

  localData = {
    direction: 'ltr',
    format: 'mm/dd/yyyy',
    separator: ' - ',
    applyLabel: 'Valider',
    cancelLabel: 'Annuler',
    weekLabel: ' ',
    customRangeLabel: 'Custom Range',
    daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    firstDay: 0
  }
  ranges: any = false;
  columns = []

  ids: number[] = []

  repaymentAmount = 0

  toggleAvenant(avenant: Avenant) {
    const index = this.ids.findIndex((i) => {
      return i === avenant.id
    })
    if (index === -1) {
      if (avenant.balance === 0) {
        this.ids.push(avenant.id)
        this.repaymentAmount += avenant.primeTTC
      }
    } else {
      this.ids.splice(index, 1)
      this.repaymentAmount -= avenant.primeTTC

    }
  }

  toggleAll() {
    if (this.ids.length === this.emissions.length) {
      this.ids = []
      this.repaymentAmount = 0
    } else {
      this.ids = []
      this.repaymentAmount = 0
      this.emissions.forEach((e: any) => {
        this.toggleAvenant(e)
      })
    }
  }

  get calculateRepaymentAmount() {
    let ttc = 0
    this.emissions.forEach((avenant: Avenant) => {
      if (this.ids.includes(avenant.id)) {
        ttc += avenant.primeTTC
      }
    })
    return ttc
  }

  loadRanges() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    yesterday.setHours(0, 0, 0, 0);


    this.ranges = this.$store.getters.loadRanges()
    this.$forceUpdate()
  }

  toggleBranch(branch: Branch) {
    const index = this.filter.branches.findIndex((b: string) => {
      return b === branch.slug
    })
    if (index !== -1) {
      this.filter.branches.splice(index, 1)
    } else {
      this.filter.branches.push(branch.slug)
    }
  }


  toggleInsurer(insurer: Insurer) {
    const index = this.filter.insurers.findIndex((b: string) => {
      return b === insurer.slug
    })
    if (index !== -1) {
      this.filter.insurers.splice(index, 1)
    } else {
      this.filter.insurers.push(insurer.slug)
    }
  }

  // Date,Actif,Risque,Assureur,Assuré,Numeronopération,police,provenance,emission,effet,accessoir compagnie,accessoir wiassur,taxe,,fga,prime nette,ttc,encaissé,solde,comissions...,reversé?
  emissionData: any = {
    'ID': {
      field: 'id',
      callback: (value: any) => {
        return '' + value;
      },
      rank: 1
    },
    'Contrat': {
      field: 'contractUuid',
      callback: (value: any) => {
        // return '' + value;

        const url = this.$router.resolve({
          name: 'contract-show',
          params: {contractUuid: value}
        }).href;
        return {
          html: `<a target="_blank" href="${url}" class="btn btn-primary btn-icon btn-xs"><i class="fad fa-external-link"></i></a>`
        };
      },
      rank: 1
    },
    'Date': {
      field: 'dateEmission',
      callback: (value: any) => {
        return helper.readable(value, 'dmY H:i:s');
      }
    },
    'Source': {
      field: 'source',
      callback: (value: any) => {
        return value;
      }
    },
    'Emetteur': {
      field: 'emitBy',
      callback: (value: any) => {
        // return {
        //   component: 'user-view',
        //   props: {
        //     value: value,
        //   }
        // };
        return value;
      }
    },
    'Partenaire': {
      field: 'partner',
      callback: (partner: any) => {
        if (partner) {
          if (typeof partner === 'string') {
            return partner
          } else if (!helper.empty(partner.name)) {
            return partner.name
          } else if (!helper.empty(partner.uuid)) {
            return partner.uuid
          }
        }
        return '-';
      }
    },
    'Actifs': {
      field: 'assetLabels',
      callback: (value: any) => {
        if (Array.isArray(value)) {
          if (value.length === 1) {
            return value[0]
          } else if (value.length > 1) {
            return value[0] + ' (+' + (value.length - 1) + ')'
          } else {
            return '-'
          }
        }
        return value
      }
    },
    'Identifiants': {
      field: 'assetIdentifiers',
      callback: (value: any) => {
        if (Array.isArray(value)) {
          if (value.length === 1) {
            return value[0]
          } else if (value.length > 1) {
            return value[0] + ' (+' + (value.length - 1) + ')'
          } else {
            return '-'
          }
        }
        return value
      }
    },
    'Risque': {
      field: 'branch',
      callback: (value: any) => {
        return this.branchLabelByUuid(value)
      }
    },
    'Assureur': {
      field: 'insurer',
      callback: (value: any) => {
        if (typeof value === 'object' && value.label) {
          return value.label
        }
        return this.insurerLabelByUuid(value)
      }
    },
    'Police': {
      field: 'police',
      callback: (value: any) => {
        return ' ' + value
      }
    },
    'Assure': {
      field: 'customer',
      callback: (value: any) => {
        if (typeof value === 'object' && value.fullname) {
          return value.fullname
        }
        return value
      }
    },
    'Operation': {
      field: 'type',
      callback: (value: any) => {
        return value
      }
    },

    'Effet': {
      field: 'dateEffet',
      callback: (value: any) => {
        return helper.readable(value, 'dmY');
      }
    },
    'Echeance': {
      field: 'dateEcheance',
      callback: (value: any) => {
        return helper.readable(value, 'dmY');
      }
    },
    'Prime TTC': {
      field: 'primeTTC',
      callback: (value: any) => {
        return value
      }
    },
    'Payer': {
      // field: 'dateEmission',
      callback: (value: any) => {
        return value.primeTTC + value.balance;
      }
    },
    'Solde': {
      field: 'balance',
      callback: (value: any) => {
        return Math.abs(value);
      },
      class: 'text-danger fw-bolder border-right'
    },
  } as any
  perPage = 50;
  // fdcm = CONSTANTS.dateConstants.first_day_current_month()
  maxPage = 1;
  interval = {
    startDate: CONSTANTS.dateConstants.first_day_current_month(),
    endDate: CONSTANTS.dateConstants.last_day_current_month()
  }
  emissions: Avenant[] = []
  payments: PaymentTransaction[] = []
  branchCategories: BranchCategory[] = []
  insurers: Insurer[] = []
  searching = false
  searched: any = {
    branch: null,
    customer: null,
    police: null,
    emitMin: null,
    emitMax: null,
    effetMin: null,
    effetMax: null,
    expireMin: null,
    expireMax: null,
    createMin: null,
    createMax: null,
    page: 1,
    perPage: 50
  }
  page = 1;
  loading = false
  gettingCount = false
  gettingCustomer = false;
  gettingComplete = false;


  resetSearch() {
    this.searched = {
      branch: null,
      customer: null,
      police: null,
      emitMin: null,
      emitMax: null,
      effetMin: null,
      effetMax: null,
      expireMin: null,
      expireMax: null,
      createMin: null,
      createMax: null,
      page: 1,
      perPage: 50
    }
  }

  isLoading = false

  async search() {
    this.load()
  }

  get sortedEmissionData() {
    return Object.fromEntries(
        Object.entries(this.emissionData).sort(([, a]: any, [, b]: any) => a.rank - b.rank)
    );
  }

  async mounted() {
    this.loadRanges()

    let isi = localStorage.getItem('incomeStateInterval')
    if (helper.empty(isi)) {
      localStorage.setItem('incomeStateInterval', JSON.stringify(this.interval))
    } else {
      this.interval = JSON.parse(isi!)
    }

    if (this.$route.query) {
      if (this.$route.query.branch && !this.filter.branches.includes(this.$route.query.branch)) {
        this.filter.branches.push(this.$route.query.branch)
      }
      if (this.$route.query.insurer && !this.filter.insurers.includes(this.$route.query.insurer)) {
        this.filter.branches.push(this.$route.query.insurer)
      }
      if (!helper.empty(this.$route.query.startDate)) {
        this.filter.startDate = new Date(this.$route.query.startDate as any)
        this.interval.startDate = new Date(this.$route.query.startDate as any)
      }
      if (!helper.empty(this.$route.query.endDate)) {
        this.filter.endDate = new Date(this.$route.query.endDate as any)
        this.interval.endDate = new Date(this.$route.query.endDate as any)

      }
      if (!helper.empty(this.$route.query.platform)) {
        this.filter.platform = this.$route.query.platform as any
      }
    }

    for (let column in this.emissionData) {
      this.$set(this.emissionData[column], 'show', true)
    }

    this.loadRequirements();
    // await Promise.all([this.loadBranches(), this.loadInsurers()])
    // this.load()
  }

  commissionModels: CommissionModel[] = []
  reductionModels: ReductionModel[] = []
  premiumDetailModels: PremiumDetailModel[] = []

  async loadRequirements() {
    const res = await api.get(api.core, 'avenant/state/requirements')
    if (res && res.data) {
      this.branchCategories = []
      res.data.categories.forEach((c: any) => {
        const occ = new BranchCategory(c)
        this.branchCategories.push(occ)
      })
      res.data.insurers.forEach((i: any) => {
        const occ = new Insurer(i)
        this.insurers.push(occ)
      })


      res.data.premiumDetailModels.forEach((pdm: any) => {
        const occ = new PremiumDetailModel(pdm)
        this.premiumDetailModels.push(occ)
        const branch: any = this.branchByUuid(occ.branchId)
        let parameter = '<div class="fw-bolder badge rounded" style="background:' + (branch && branch.color ? branch.color : 'gray') + '">' + this.branchLabelByUuid(occ.branchId) + '</div><div>' + occ.label + '</div>'

        if (!this.emissionData.hasOwnProperty(parameter)) {
          this.$set(this.emissionData, parameter, {
            field: '',
            callback: (avenant: Avenant) => {
              for (let pd of avenant.premiumDetails) {
                if (pd && pd.model && pd.model.label === occ.label) {
                  return pd.premium
                }
              }
              return 0
            },
            class: 'fw-bolder',
            show: false
          })
        }
      })


      res.data.commissions.forEach((i: any) => {
        const occ = new CommissionModel(i)
        this.commissionModels.push(occ)
        let parameter = occ.label
        if (!helper.removeAccent(occ.label).toLowerCase().includes('commission')) {
          parameter = 'Commission ' + occ.label;
        }
        if (!this.emissionData.hasOwnProperty(parameter)) {
          this.$set(this.emissionData, parameter, {
            field: '',
            callback: (avenant: Avenant) => {
              for (let com of avenant.commissions) {
                if (com && com.model && com.model.id === occ.id) {
                  return com.premium
                }
              }
              return 0
            },
            class: 'fw-bolder text-success',
            show: true
          })
        }
      })
      res.data.reductions.forEach((i: any) => {
        const occ = new ReductionModel(i)
        this.reductionModels.push(occ)


        let parameter = occ.label
        if (!helper.removeAccent(occ.label).toLowerCase().includes('reduction')) {
          parameter = 'Reduction ' + occ.label;
        }
        if (!this.emissionData.hasOwnProperty(parameter)) {
          this.$set(this.emissionData, parameter, {
            field: '',
            callback: (avenant: Avenant) => {
              for (let reduction of avenant.reductions) {
                if (reduction && reduction.model && reduction.model.id === occ.id) {
                  return reduction.premium
                }
              }
              return 0
            },
            show: true,
            class: 'text-info fw-bolder'
          })
        }

      })


    }
  }


  stopEmissionLoading = false

  async load() {
    this.filter.startDate = new Date(this.interval.startDate)
    this.filter.amount = this.maxAmount;
    this.filter.endDate = new Date(this.interval.endDate)
    this.loadingEmission = false
    this.emissionPage = 1
    this.ids = []
    this.repaymentAmount = 0
    localStorage.setItem('incomeStateInterval', JSON.stringify(this.interval))
    this.loadEmissions()
  }

  branchLabelByUuid(uuid: string) {
    for (let category of this.branchCategories) {
      for (let branch of category.branches) {
        if (branch.uuid === uuid || branch.id === uuid) {
          return category.label + ' ' + branch.label
        }
      }
    }
  }

  branchByUuid(uuid: string) {
    for (let category of this.branchCategories) {
      for (let branch of category.branches) {
        if (branch.uuid === uuid || branch.id === uuid) {
          branch.category = category
          return branch
        }
      }
    }
  }


  insurerLabelByUuid(uuid: string) {
    for (let insurer of this.insurers) {
      if (insurer.uuid === uuid || insurer.slug === uuid) {
        return insurer.label
      }
    }
  }

  primeNette(avenant: Avenant) {
    for (let pd of avenant.premiumDetails) {
      // console.log(helper.slugify(pd.model.label.toLowerCase()) + ' ' + pd.premium)
      if (helper.slugify(pd.model.label.toLowerCase()) == 'prime-nette') {
        return pd.premium
      }
    }
    return 0
  }

  getDetail(avenant: Avenant, name: string) {
    for (let pd of avenant.premiumDetails) {
      if (helper.slugify(pd.model.label.toLowerCase()) == name.toLowerCase()) {
        return pd.premium
      }
    }
    return 0
  }

  get netSum() {
    let sum = 0
    this.emissions.forEach((a: Avenant) => {
      sum += this.primeNette(a)
    })
    return Math.round(sum)
  }

  get ttcSum() {
    let sum = 0
    this.emissions.forEach((a: Avenant) => {
      sum += a.primeTTC
    })
    return Math.round(sum)
  }

  get paySum() {
    let sum = 0
    this.payments.forEach((p: PaymentTransaction) => {
      if (p.status === CONSTANTS.PAYMENT_STATUS.SUCCEED) {
        sum += p.amount
      }
    })
    return Math.round(sum)
  }

  get commissionSum() {
    let sum = 0
    this.emissions.forEach((e: Avenant) => {
      e.commissions.forEach((c: any) => {
        sum += c.premium
      })
    })
    // this.data.commissions.
    return Math.round(sum)
  }

  async loadBranches() {
    this.isLoading = true
    this.branchCategories = await this.$store.getters.branchCategories()
    this.isLoading = false
  }

  async loadInsurers() {
    this.insurers = await this.$store.getters.insurers()
  }

  loadingPayment = false

  async loadPayments() {
    this.loadingPayment = true
    const start = helper.dateToHtml(this.filter.startDate)
    const end = helper.dateToHtml(this.filter.endDate)
    const res = await api.get(api.core, 'payment/list/' + start + '/' + end)
    if (res && res.data) {
      this.payments = []
      res.data.avenants.forEach((a: Avenant) => {
        const avenant = new Avenant(a)
        avenant.account.operations.forEach((o: AccountOperation) => {
          const transaction = new PaymentTransaction(o.transaction)
          transaction.avenant = avenant
          this.payments.push(transaction)
        })
      })
      // console.log(this.payments)
      // res.data.payments.forEach((p: PaymentTransaction) => {
      //     this.payments.push(new PaymentTransaction(p))
      // })

    }
    this.loadingPayment = false

  }

  loadingEmission = false

  emissionPage = 1

  async loadEmissions() {
    this.emissions = []
    this.payments = []
    this.filter.page = 1
    try {
      this.cancelTokenSource.cancel('Axios request cancelled due to inputValue change');
    } catch (e) {
    }
    this.loadEmissionsByPage()
  }

  iscalculating = false

  async launchRecalculation() {
    if (!this.iscalculating) {
      this.filter.page = 1
      this.recalculate()
    }
  }

  async recalculate() {
    this.iscalculating = true
    const res = await api.post(api.core, 'avenant/recalculate', this.filter, {
      cancelToken: this.cancelTokenSource.token,
    })
    if (res && res.data) {
      if (res.data.count > 0) {
        this.filter.page++
        this.recalculate()
      } else {
        this.iscalculating = false
        this.load()
      }
    } else {
      this.filter.page = 1
      this.iscalculating = false
    }
  }

  cancelTokenSource: any

  async loadEmissionsByPage() {
    // let end: any = helper.dateToHtml(this.interval.end)
    this.loadingEmission = true
    this.cancelTokenSource = axios.CancelToken.source();
    const res = await api.post(api.core, 'avenant/emission/list', this.filter, {
      cancelToken: this.cancelTokenSource.token,
    })
    if (res && res.data && res.data.avenants) {
      res.data.avenants.forEach((a: any) => {
        const avenant = new Avenant(a)
        // if (avenant.dateEmission! >= this.interval.start && avenant.dateEmission! < end) {
        this.emissions.push(avenant)
        // }
        avenant.account.operations.forEach((o: AccountOperation) => {
          const transaction = new PaymentTransaction(o.transaction)
          transaction.avenant = avenant
          this.payments.push(transaction)
        })
      })
      if (res.data.avenants.length > 0) {
        this.filter.page = this.filter.page + 1
        if (res.data.amount > 0) {
          this.filter.amount = res.data.amount
        }
        this.loadEmissionsByPage()
      } else {
        this.filter.page = 1
        this.loadingEmission = false
      }
    }
  }


  repayPage = 1
  repaymentUuid: any = null

  async repay(page = 1) {

    console.log('Page ' + page)
    this.$store.commit('loading')
    const start = (page - 1) * this.perPage
    const ids = this.ids.slice(start, start + this.perPage)
    console.log('from ' + start + ' to ' + (start + this.perPage))
    if (ids.length > 0) {
      const res = await api.post(api.core, 'avenant/generate/repayment', {
        insurer: this.filter.insurers[0],
        amount: this.maxAmount,
        repayment: this.repaymentUuid,
        ids: ids,
      })
      if (res && res.data) {
        this.repaymentUuid = res.data.repayment.uuid
        if (res.data.count > 0) {
          this.repay(page + 1)
        } else {
          this.$store.commit('stopLoading')
        }
      }
    } else {
      this.$store.commit('stopLoading')

      this.$router.push({name: 'accounting-repayment', params: {uuid: this.repaymentUuid}})
    }
  }
}
</script>
<style scoped>
td,
th {
  white-space: nowrap;
}
</style>
