<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{name: 'dashboard'}" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Dashboard') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center py-1">
            <div @click="resetCommission" class="btn-sm btn btn-primary ms-5"
                 v-b-modal.commissionModal>
              <i class="fad fa-plus"></i>
              {{ trans('Créer une commssion') }}
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>

      <div class="card">
        <div class="card-body ">
          <table class="table table-striped gy-7 gs-7">
            <thead>
            <tr class="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
              <th>{{ trans('Status') }}</th>
              <th>{{ trans('Label') }}</th>
              <th>{{ trans('Calcul') }}</th>
              <th>{{trans('Sur le bordereau')}}</th>
              <th>{{ trans('Actions') }}</th>
            </tr>
            </thead>
            <tr v-if="isLoading">
              <td colspan="4">
                <Loading></Loading>
              </td>
            </tr>
            <draggable :animation="200" :list="commissionModels" @end="updateRanks"
                       filter=".action-button" ghost-class="bg-light-primary" group="pdm" tag="tbody" v-else>
              <tr v-for="com in commissionModels">
                <td>
                  <div class="form-check form-switch form-check-custom form-check-solid ms-5">
                    <input class="form-check-input h-20px w-30px" type="checkbox" @change="toggle(com)"
                           :checked="com.isEnable"/>
                  </div>
                </td>
                <td>{{ com.label }}</td>
                <td>
                  <router-link target="_blank" :to="{ name : 'engine-method-editor',
                                    params:{methodId: com.method.id,methodSlug: com.method.code,engineVersion:engineVersion }}"
                               class="text-primary text-decoration-underline fw-bolder" v-if="com.method">
                    {{ com.method.label }}
                  </router-link>
                </td>
                <td>
                  <div class="badge badge-light-success" v-if="com.showOnSlip">
                    Oui
                  </div>
                  <div class="badge badge-light-danger" v-else>
                    Non
                  </div>
                </td>
                <td>
                  <div @click="setCommission(com)" class="btn btn-xs btn-icon btn-primary"
                       v-b-modal.commissionModal>
                    <i class="fad fa-edit"></i>
                  </div>
                  <!-- <div @click="" class="btn btn-xs btn-icon  btn-light-danger"
                     v-if="hasRole('ROLE_ADMIN')">
                  <i class="fad fa-trash"></i>
                </div> -->
                </td>
              </tr>
            </draggable>
          </table>
          <div>

          </div>
        </div>
      </div>
      <b-modal :title="trans('Commission')" centered id="commissionModal" ref="commissionModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'name'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'name'" :state="!helper.empty(commission.label)"
                            v-model="commission.label"></b-form-input>
            </div>
            <div class="col-auto my-2 flex-grow-1">
              <label :for="'type'" class="required form-label">{{ trans('Calcul') }}</label>
              <div class="d-flex">
                <b-input-group>
                  <b-form-select :state="!helper.empty(commission.method)"
                                 class="form-control form-select"
                                 v-model="commission.method">
                    <b-form-select-option :value="null">{{ trans('Select_a_type') }}
                    </b-form-select-option>
                    <b-form-select-option :key="'method_'+key" :value="method"
                                          v-for="(method,key) in methods">
                      {{ trans(method.label) }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-input-group-append>
                    <div class="btn btn-sm btn-icon btn-primary h-100"
                         style="border-top-left-radius: 0;border-bottom-left-radius: 0"
                         v-b-modal="'methodModal'">
                      <i class="fad fa-plus"></i>
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Afficher sur le bordereau') }}</label>
              <div>
                <BooleanInput v-model="commission.showOnSlip"/>
              </div>
            </div>

          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editCommissionModel" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal :title="trans('Calcul')" centered id="methodModal" ref="methodModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label class="required form-label">{{ trans('Nom') }}</label>
              <div>
                <b-form-input :state="!helper.empty(method.label)" class="form-control" type="text"
                              v-model="method.label">
                </b-form-input>
              </div>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label class="form-label">{{ trans('Description') }}</label>
              <div>
                <textarea class="form-control" v-model="method.description"></textarea>
              </div>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="editMethod()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import commissionModel from "@/entity/CommissionModel";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import Loading from "@/components/Loading.vue";
import EngineMethod from "@/entity/EngineMethod";
import Commission from '@/entity/Commission';
import CommissionModel from '@/entity/CommissionModel';
import BooleanInput from "@/components/BooleanInput.vue";

@Component({
  components: {BooleanInput, Loading, Base}
})
export default class CommissionList extends Vue {

  engineVersion = null
  commission = new commissionModel()
  commissionModels: commissionModel[] = []
  methods: EngineMethod[] = []
  isLoading = false
  method = new EngineMethod()

  async mounted() {
    await this.loadCommissionModels()
    this.loadMethods()
  }

  async toggle(com: commissionModel) {
    this.$store.commit('loading')
    await api.get(api.core, 'commission/model/toggle/' + com.id)
    this.$store.commit('stopLoading')
    this.loadCommissionModels()
  }

  resetCommission() {
    this.commission = new CommissionModel()
  }

  async updateRanks() {
    const popup = new Popup('', 'Reorganisation en cours ...', 'warning', 'fad fa-cogs', true)
    const ranks: any = []
    let i = 1
    this.commissionModels.forEach((c: commissionModel) => {
      ranks.push({id: c.id, ranking: i})
      i++
    })
    const res = await api.post(api.core, 'commission/model/update/ranks', {ranks})
    this.loadCommissionModels()
    popup.hide()
  }

  setCommission(commission: CommissionModel) {
    if (commission.method) {
      for (let method of this.methods) {
        if (commission.method.id === method.id) {
          commission.method = method
        }
      }
    }
    this.commission = commission
  }

  async loadCommissionModels() {
    this.isLoading = true
    const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-download-alt', true)
    const res = await api.get(api.core, 'commission/model/list/' + this.$route.params.branchSlug)
    if (res && res.data && res.data.commissionModels) {
      if (res.data.version) {
        this.engineVersion = res.data.version
      }
      this.commissionModels = []
      res.data.commissionModels.forEach((com: commissionModel) => {
        const occ = new commissionModel(com)
        this.commissionModels.push(occ)
      })
    }
    popup.hide()
    this.isLoading = false
  }

  async editCommissionModel() {
    const popup = new Popup('Chargement ...', '', 'secondary', 'fad fa-cloud-download-alt', true)
    const comm: any = this.commission
    // delete comm.commission
    const res = await api.post(api.core, 'commission/model/edit/' + this.$route.params.branchSlug, {commissionModel: comm});
    (this.$refs.commissionModal as any).hide()
    popup.hide()
    this.loadCommissionModels()
  }

  async loadMethods() {
    const method = {id: this.method.id, label: this.method.label, description: this.method.description}
    let url = 'engine/method/list/branch/and/global/methods/' + this.$route.params.branchSlug;

    const res = await api.post(api.core, url, {method})

    if (res && res.data && res.data.methods) {
      // console.log('res.data.methods', res)
      this.methods = []
      res.data.methods.forEach((m: any) => {
        const occ = new EngineMethod(m)
        this.methods.push(occ)
      })
      res.data.globals.forEach((m: any) => {
        const occ = new EngineMethod(m)
        occ.label = 'GLOBAL ' + occ.label
        this.methods.push(occ)
        const comm = this.commissionModels.find((comm: CommissionModel) => {
          return comm.method && comm.method.id === m.id
        })
        if (comm) {
          comm.method = occ
        }
      })
    }
  }

  async editMethod() {
    this.$store.commit('loading')
    const method = {id: this.method.id, label: this.method.label, description: this.method.description}
    let url = 'engine/method/edit';

    const res = await api.post(api.core, url, {method})
    if (this.$refs.methodModal) {
      (this.$refs.methodModal as any).hide()
    }
    this.$store.commit('stopLoading')
    this.loadCommissionModels()
    this.loadMethods()
  }

}

</script>
