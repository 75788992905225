<template>
  <Base>
  <template v-slot:toolbar>
    <div class="toolbar" id="kt_toolbar">
      <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
        <div class="d-flex align-items-center me-3">
          <slot name="leftToolbar">
            <span class="h-20px border-gray-200 border-start mx-4"></span>
            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              <li class="breadcrumb-item text-muted">
                <router-link :to="{ name: 'prospection-index' }" class="text-muted text-hover-primary">
                  <i class="fad fa-user"></i>
                  {{ trans("Information sur l'entreprise") }}
                </router-link>
              </li>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </template>

  <template v-slot:content>
    <Loading v-if="isLoading"></Loading>

    <template v-else-if="company" >
    <div  class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
     
        <template >

          <div class="d-flex flex-wrap flex-sm-nowrap mb-5" >
            <div class="me-7 mb-4">
              <div class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <!-- <img :src="uploadRoot + customer.photo" alt="image" class="object-cover" v-if="customer.photo"> -->
                <div
                  class="w-100px h-100px h-lg-175px w-lg-160px d-flex align-items-center justify-content-center shadow-xs rounded">
                  <i class="fad fa-building fs-5x"></i>
                  <!--                                    <img  alt="image" class="object-cover" style="opacity: 0">-->
                </div>
                <div
                  class="bg-success position-absolute translate-middle bottom-0 start-100 mb-6 rounded-circle border border-4 border-white h-20px w-20px">
                </div>
              </div>
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div class="d-flex flex-column">
                  <div class="d-flex align-items-center mb-2">
                    <div class="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {{ company.name }} {{ company.identifier ? ' - ' + company.identifier : '' }}
                    </div>
                    <div class="btn btn-icon btn-primary ms-5 btn-xs"  v-b-modal="'modal-center'">
                      <i class="fad fa-edit"></i>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-2 pe-2">
                    <div class="d-flex align-items-center me-3">
                      <i class="fad fa-phone-alt fs-2 me-3"></i>
                      <div class=" badge badge-primary fs-7">
                        - {{ company.number }}
                      </div>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="fad fa-at fs-2 me-3"></i>
                      <div class=" badge badge-primary fs-7">
                        - {{ company.email }}
                      </div>
                    </div>
                  </div>
                  <div class=" my-4">
  
                  </div>
                  <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                    <a v-if="company.membersNumber"
                      class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center"
                      href="#">
                      <i class="fad fa-user me-2"></i>
                      {{ company.membersNumber }} employees</a>
                    <a v-if="company.localisation"
                      class="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2 d-flex align-items-center"
                      href="#">
                      <i class="fad fa-map-marker me-2"></i>
                      {{ company.localisation }}
                    </a>
                    <a v-if="company.size"
                      class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 d-flex align-items-center"
                      href="#">
                      <i class="fad fa-building me-2"></i>
                      {{ company.size }}
                    </a>
                  </div>
                </div>
                <div class="d-flex my-4 align-items-center">
                  <div class="mx-5 fw-bolder">
  
                    <div class="mx-5 fw-bolder">
                      Gestionnaire
                      <br />
                      <div >
                        <VueMultiselect :options="managers" :selectedLabel="'Ok'" :tagPlaceholder="trans('Gestionnaire')"
                          placeholder="Gestionnaire" :close-on-select="true" select-label="+" :value="company.manager"
                          @input="updateManager" tag-placeholder="+" track-by="uuid">
                          <template slot="singleLabel" slot-scope="props">
                            <user-view v-model="props.option" :oneLine="true"></user-view>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div>
                              <div class="fs-7">
                                {{ props.option.name }} {{ props.option.prename }}
                              </div>
                              <div class="fs-8 mt-1">
                                {{ props.option.email }}
                              </div>
                            </div>
                          </template>
                        </VueMultiselect>
                        <!--                      <user-view v-model="contract.manager"></user-view>-->

                        <router-link :to="{
                          name: 'handshake-form',
                          params: {
                            clientId: 'null',
                            uuid: 'null',
                            entrepriseId: company?.id,
                          },
                        }">
                          <button type="button" class="btn btn-sm btn-success w-100 mt-5">
                            <i class="fad fa-phone"></i> Effectuer une prise de
                            contact
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>
  
          <b-tabs  active-nav-item-class="" active-tab-class="" nav-wrapper-class="nav nav-stretch border-0 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap
                            flex-grow-1" v-model="tabIndex">
            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-layer-group fs-2 me-2"></i>

                  {{ trans("Agents en contact") }}
                 
                </div>
              </template>
            </b-tab>

            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-info-circle fs-2 me-2"></i>

                  <span>
                    {{ trans("Informations supplementaire") }}
                  </span>
                 
                 
                </div>
              </template>
            </b-tab>

            <b-tab :title-link-class="'nav-link text-active-primary me-6 pt-2 pb-5'">
              <template #title>
                <div class="d-flex align-items-center ">
                  <i class="fad fa-phone fs-2 me-2"></i>

                  {{ trans("Prises de de contact effectué") }}
                 
                </div>
              </template>
            </b-tab>
      

          </b-tabs>


         
          


        </template>
      
      </div>
    </div>


    <div class="card" key="avenants">
      <div class=" card-body">
        <template>
          <div v-if="tabIndex === 0">

          
            <!-- <Loading v-if="loadingUsers"/> -->
            <table class="table table-striped gy-3 gs-3">
              <thead>
              <tr class="fw-bolder fs-6 text-gray-800 border-bottom border-gray-200">
                <th>Utilisateur</th>
                <!-- <th>Email</th> -->
                <th>Contact</th>
                <!-- <th>Agence</th> -->
                <th>Prise de contact effectué</th>
                <!-- <th>Derniere connexion</th> -->
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="employe in company.uniqProspects">
                <tr style="height: 1px" :key="'p_'+employe.id">
                  <td>
                    {{ employe.name }}
                  </td>
                  <!-- <td>
                    {{ employe. }}
                  </td> -->
                  <td>
                    {{ helper.phoneFormat(employe?.mobile) }}
                   
                  </td>
                 
                  <td>
                    {{ employe.handshakes.length }}
                  </td>

                  <!-- <td>
                    {{ readableDate(partner.lastToken, 'dmY H:i') }}
                  </td> -->

                  <td class="text-end">
                    <div class="d-flex align-items-center">

                      <router-link class="me-2" :to="{
                        name: 'details-clients',
                        params: { id: employe.id },
                      }">
                          <button class="btn btn-sm btn-icon btn-primary">
                            <i class="fad fa-eye"></i>
                          </button>
      
                        </router-link>
      
      
      
                        <router-link class="mx-2" :to="{
                        name: 'handshake-form',
                        params: { clientId: employe?.id, uuid: 'null' },
                      }">
                          <button class="btn btn-sm btn-icon btn-success">
                            <i class="fad fa-phone"></i>
                          </button>
                        </router-link>


                    </div>
                  
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
         
          <div v-if="tabIndex === 1">


            <div class="row g-4 ">


                <template v-for="(additionnaInfo, index2) in company?.additionalInfos" >
                  <div class="col-4" :key="index2">
                    <div class="card h-100 shadow-sm hover-elevate-up">
                      <div class=" p-4">
                        <div class="d-flex align-items-center justify-content-between mb-2">
  
                          <div class="flex-grow-1">
                            <h5 class="mb-1">{{ additionnaInfo.label ?? 'Non defini' }} </h5>
                            <span class="text-muted fs-7">{{ additionnaInfo.type == 'FILE' ? additionnaInfo.type : additionnaInfo.value }}</span>
                          </div>
                          <!-- <a :href="file.link" target="_blank"> -->
                          <button v-if="additionnaInfo.type === 'FILE'" class="btn btn-sm btn-icon btn-primary"  @click="$bvModal.show('fileModal' + index)">
                            <i class="fad fa-eye "></i>
                          </button>
                          <!-- </a> -->
                        </div>
    
                      


                        <b-modal size="lg" :title="additionnaInfo.label" :id="'fileModal' + index" hide-footer>
                          
                            <iframe  id="iframe" class="flex-1 w-100" :src="uploadRoot+additionnaInfo.value" style="height:70vh" 
                                 frameborder="0"></iframe>
                        
                          <template #modal-footer>
                            <button class="btn btn-primary" @click="$bvModal.hide('fileModal' + index)">Fermer</button>
                          </template>

                        </b-modal>
                      </div>
                    </div>
                  </div>
    
                </template>
    
    
    
    
    
    
            </div>
          
          </div>


          <div v-if="tabIndex === 2">

          
            <!-- <Loading v-if="loadingUsers"/> -->
            <div class="table-responsive">
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr class="fw-bolder text-muted">
                    <th class="w-25px">
                      <!-- <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                          type="checkbox"
                          value="1"
                        />
                      </div> -->
                    </th>
                    <th class="min-w-150px">{{ trans("Prise de contact") }}</th>
                    <th class="min-w-140px">{{ trans("Agents de contact") }}</th>
                    <!--              <th v-if="!compact">-->
                    <!--                Prochaine activité-->
                    <!--              </th>-->
                    <th class="min-w-120px">{{ trans("Statut") }}</th>
                    <th class="min-w-100px text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr v-if="rgs.length === 0">
                  <td colspan="9">
                    <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                                  border-bottom-dashed border-gray-300">
                      <i class="fad fa-empty-set fs-4x"></i>
                      <span class="mt-5 fs-2x text-gray-400">
                        {{ trans('Aucun resultat') }}
                      </span>
                    </div>
                  </td>
                </tr>
  
                -->
                  <tr
                    v-for="(handshake, index) in totalHandshakes "
                    :key="index"
                  >
                    <td class="">
                      <!-- <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          value="1"
                        />
                      </div> -->
                    </td>
                    <td>
                      <div class="d-flex flex-column">
                        <span>
                          {{
                            handshake.type == "a_distance"
                              ? "A distance"
                              : handshake.type == "rendezvous"
                              ? "Rendez-vous"
                              : null
                          }}
                        </span>
                        <span style="text-transform: uppercase" class="fw-bolder">
                          {{ handshake.title }}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-column">
  
                        <span>
                          {{ handshake.uniqProspect.name }}
                        </span>
                      </div>
                    </td>
  
                    <td>
                      <span class="btn btn-success btn-sm"
                        >Effectué par {{ handshake.createBy }} <br />
                        le {{ helper.readable(handshake.createdAt) }}</span
                      >
                    </td>
  
                    <!--              <td v-if="!compact">-->
                    <!--              </td>-->
                    <td class="text-gray-400 fw-bolder text-end">
                      <div>
                        <button
                          @click="ViewHandShake(handshake.id)"
                          class="btn btn-icon btn-primary btn-sm"
                        >
                          <i class="fad fa-eye"></i>
                        </button>
  
                        <b-modal :id="'viewHandShake' + handshake.id" title="Details de la prospection" size="xl">
                          <h2 style="text-transform: uppercase">
                            {{ handshake.title }}
                          </h2>
  
                          <div class="row g-2">
                            <div class="col">
                              <div
                                class="row justify-content-center align-items-center g-2 mt-5"
                              >
                                <div class="col">
                                  <p class="fw-bolder">
                                    Propection
                                    {{
                                      handshake.type == "a_distance"
                                        ? "à distance"
                                        : handshake.type == "rendezvous"
                                        ? "par Rendez-vous"
                                        : null
                                    }}
                                  </p>
  
                                  <div>
                                    {{ handshake.createBy }} <br />
                                    le {{ helper.readable(handshake.createdAt) }}
                                  </div>
                                </div>
                                <div class="col">
                                  <p>Type de client</p>
                                  <h5>
                                    {{ handshake.category }}
                                  </h5>
                                </div>
                              </div>
  
                              <div class="mt-5">
                                <h3>Notes</h3>
  
                                <div
                                  v-for="(item, index) in handshake.notes"
                                  class="border-bottom border-top py-5"
                                  :key="index"
                                >
                                  <p>
                                    {{ item.createBy }} <br />
                                    le {{ helper.readable(item.createdAt) }}
                                  </p>
                                  <iframe
                                    :srcdoc="item.comment"
                                    width="100%"
                                    height="100%"
                                  ></iframe>
                                </div>
                              </div>
                            </div>
  
                            <div
                              class="col-4 d-flex flex-column align-items-center"
                            >
                              <h1 style="font-size: 6rem">
                               
  
                                {{
                                  handshake.uniqProspect?.status == "HOT"
                                    ? "😍"
                                    : handshake.uniqProspect?.status == "WARM"
                                      ? "😑"
                                      : handshake.uniqProspect?.status == "COLD"
                                        ? "🥶"
                                        : handshake.uniqProspect?.status == "UNVALID"
                                          ? "😒"
                                          : "🫥"
                                }}
                              </h1>
                              <h1 class="text-center">
                                {{ handshake.uniqProspect.name }}
                              </h1>
  
                              <div>
                              
  
                                <div class="d-flex flex-wrap">
                                  <div
                                    class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                                  >
                                    {{
                                      helper.phoneFormat(handshake.uniqProspect?.mobile)
                                    }}
                                    <div
                                      class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0"
                                    >
                                      <i class="fad fa-phone"></i>
                                    </div>
  
  
                                  
                                  </div>
                                </div>
  
                                <router-link class="me-2" :to="{
                                  name: 'details-clients',
                                  params: { id: handshake.uniqProspect.id },
                                }">
                                    <button class="btn  btn-primary mt-5">
                                     Voir plus 
                                    </button>
                
                                  </router-link>
                              </div>
                            </div>
                          </div>
                        </b-modal>
  
                        <router-link
                          class="mx-2"
                          :to="{
                            name: 'handshake-form',
                            params: { uuid: handshake.id, idClient: 'null' },
                          }"
                        >
                          <button class="btn btn-icon btn-secondary btn-sm mx-2">
                            <i class="fad fa-edit"></i>
                          </button>
                        </router-link>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
       

        </template>
      </div>
    </div>
  </template>

    <b-modal size="lg" id="modal-center" @ok="handleOk" centered title="Modifier l'entreprise">
      <div class="my-4">
        <form ref="form" v-if="company">
          <div class="row">
            <div class="col">
              <b-form-group id="input-group-2" label="Nom de l'entreprise" label-for="input-2">
                <input type="text" v-model="company.name" class="mb-2 form-control"
                  placeholder="Ex: Entreprise Ivoirienne SARL">
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-2" label="Numéro de téléphone" label-for="input-2">
                <input type="tel" v-model="company.number" class="mb-2 form-control"
                  placeholder="Ex: +225 07 12 34 56 78">
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b-form-group id="input-group-2" label="Taille de l'entreprise" label-for="input-2">
                <b-form-select class="form-control form-select" v-model="company.size">
                  <b-form-select-option value="">{{ trans("Sélectionnez la taille de l'entreprise")
                    }}</b-form-select-option>
                  <b-form-select-option value="TPE">{{ trans("TPE") }}</b-form-select-option>
                  <b-form-select-option value="PME">{{ trans("PME") }}</b-form-select-option>
                  <b-form-select-option value="GRAND_COMPTE">{{ trans("Grand Compte") }}</b-form-select-option>
                  <b-form-select-option value="OTHER">{{ trans("Autre") }}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-2" label="Secteur d'activité" label-for="input-2">
                <input type="text" v-model="company.sector" class="mb-2 form-control"
                  placeholder="Ex: Agroalimentaire, BTP, FinTech">
              </b-form-group>
            </div>

            <div class="col">
              <b-form-group id="input-group-2" label="SIRET / Numéro d'identification" label-for="input-2">
                <input type="text" v-model="company.identifier" class="mb-2 form-control"
                  placeholder="Ex: CI-12345678-X">
              </b-form-group>
            </div>
          </div>
          <div class="row">


            <div class="col">
              <b-form-group id="input-group-2" label="Nombre d'employés" label-for="input-2">
                <input type="number" v-model="company.membersNumber" class="mb-2 form-control" placeholder="Ex: 10">
              </b-form-group>
            </div>
            <div class="col">
              <b-form-group id="input-group-2" label="Adresse de l'entreprise" label-for="input-2">
                <input type="text" v-model="company.localisation" class="mb-2 form-control"
                  placeholder="Ex: Abidjan, Cocody Riviera 2">
              </b-form-group>

            </div>

          </div>
          <b-form-group id="input-group-2" label="Adresse email" label-for="input-2">
            <input type="email" v-model="company.email" class="mb-2 form-control"
              placeholder="Ex: contact@entreprise.ci">
          </b-form-group>


          <div>
            <h5 class="text-center">Informations supplementaires</h5>

            <div v-for="(item, index) in company.additionalInfos" :key="index">
              <select class="form-select form-select-sm" v-model="item.type">
                <option selected value="">
                  Selectionnez un type de fichier
                </option>
                <option v-for="(option, index) in optionsInfo" :value="option.value" :key="index">
                  {{ option.text }}
                </option>
              </select>
              <b-input-group class="my-2" v-if="item.type">
                <b-form-input v-model="item.label" placeholder="Libelé"></b-form-input>
  
                <b-form-input v-if="item.type != 'FILE'" v-model="item.value" placeholder=""></b-form-input>
                <div v-else class="mx-3">
                  <FileUpload :currentFileName="item.value"  v-model="item.value" />
                </div>
  
                <b-input-group-append>
                  <div @click="removeInfo(index)" style="
                        border-top-left-radius: 0 !important;
                        border-bottom-left-radius: 0 !important ;
                      " class="btn btn-sm btn-icon btn-danger rounded-end">
                    <i class="fad fa-times"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

          </div>
          <div @click="addInfo"
            class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary">
            Ajouter une information
          </div>
        </form>

      </div>
    </b-modal>


  </template>
  </Base>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";
import { api } from "@/services/Api";
import Loading from "@/components/Loading.vue";
import FileUpload from "../../components/FileUpload.vue";
import Company from "@/entity/Company";
import Handshake from '../../entity/Handshake';
import AdditionalInfo from "@/entity/AdditionalInfo";
import Popup from "@/entity/Popup";
import User from "@/entity/User";
import VueMultiselect from "vue-multiselect/src/Multiselect.vue";
import Swal from "sweetalert2";
import { helper } from "@/services/Helper";


@Component({
  components: { Base, Loading, FileUpload, VueMultiselect , UserView: () => import("@/components/UserView.vue") },
})
export default class DetailsCompanyView extends Vue {
  company: Company = new Company();
  isLoading: boolean = false;
helper = helper
  source = api.uploadRoot;
  managers: User[] = [];
  uploadRoot = api.uploadRoot;

  async load() {
    this.isLoading = true;
    const res = await api.get(
      api.form,
      "company/info/" + this.$route.params.id
    );
    if (res && res.data) {

      this.company = new Company(res.data.company);


      this.isLoading = false;
    } else {
      this.isLoading = false;
    }
    // this.$forceUpdate();
  }

  ViewHandShake(id: number) {
    this.$bvModal.show("viewHandShake" + id);
    this.$forceUpdate;
  }

  statusClient = [
    {
      text: "😒",
      value: "UNVALID",
    },
    {
      text: "🥶",
      value: "COLD",
    },

    {
      text: "😑",
      value: "WARM",
    },
    {
      text: "😍",
      value: "HOT",
    },
  ];

  optionsInfo = [
    {
      value: "INFO",
      text: "Informations",
    },
    {
      value: "FILE",
      text: "Fichiers",
    },
  ];


  tabIndex = 0;





  addInfo() {
    const additionnaInfo = new AdditionalInfo();
    console.log(additionnaInfo , 'addition');
    this.company?.additionalInfos.push(additionnaInfo);

    this.$forceUpdate();
  }

  removeInfo(item: number) {
    this.company?.additionalInfos.splice(item, 1);
    this.$forceUpdate();
  }

  async handleOk(bvModalEvent: { preventDefault: () => void; }) {


    const pop = new Popup(
      "Chargement",
      "Enregistrement d'un entreprise",
      "primary",
      "fad fa-sync",
      true
    );

    try {
      const res = await api.post(api.form, "company/edit",
        this.company
      );

      if (res.status == 200) {
        pop.hide();
        this.load();
      }
    } catch (error) {
      pop.hide();
    }

    bvModalEvent.preventDefault()
    // Trigger submit handler
  }

  async updateManager(e: any) {
    if (e && e.uuid) {
      const u = new User(e);
      const response = await Swal.fire({
        title: "Attribuer à " + u.identifier,
        html: "Êtes-vous sûr de vouloir changer le gestionnaire de cet entreprise ?",
        timer: 10000,
        timerProgressBar: true,
        showCancelButton: true,
        confirmButtonText: "Changer",
        didOpen: () => {
        },
        willClose: () => {
        },
      });

      console.log(e);

      if (response.isConfirmed) {
        this.isLoading = true;
        const res = await api.post(api.form, "company/edit",
        {
          ...this.company,
          manager: e.email,
        }
      );
        if (res.status == 200) {
          this.load();
        }

        this.isLoading = false;
      }
    }
  }


  totalHandshakes: Handshake[] = [];
  getHandshakes(): any {
    return this.company?.uniqProspects.map((item) => {
      return item.handshakes.map((handshake:Handshake) => {
        return new Handshake({
          ...handshake,
          uniqProspect: item,
          // createBy: this.company?.uniqProspects.find((user) => user.id == handshake.createBy)?.name,
          // createdAt: this.helper.readable(handshake.createdAt),
          // type: handshake.type == "a_distance" ? "A distance" : "Rendez-vous",
        
        });
      });
    });
  }
  async mounted() {
    await this.load();

    this.managers = await api.loadManagers();

   this.totalHandshakes = this.getHandshakes().flat()
   console.log(this.totalHandshakes,'totalHandshakes');

  }
}
</script>

<style scoped></style>
