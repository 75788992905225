import router from "@/router";
import Popup from "@/entity/Popup";
import store from "@/store";

class NotificationManager {

    publicPath = process.env.BASE_URL

    defaultAudio: any;
    errorAudio: any;
    cashAudio: any;
    cashFailed: any;
    channel = new BroadcastChannel('notifications');

    constructor() {
        this.defaultAudio = new Audio(this.publicPath + 'sounds/pop.mp3');
        this.errorAudio = new Audio(this.publicPath + 'sounds/error.mp3');
        this.cashAudio = new Audio(this.publicPath + 'sounds/cash.mp3');
        this.cashFailed = new Audio(this.publicPath + 'sounds/cash_failed.mp3');
        this.handleMessage = this.handleMessage.bind(this);
        this.channel.addEventListener('message', this.handleMessage)
        // this.channel.onmessage = (event) => this.handleMessage(event)
        // this.channel.onmessageerror = (event) => {console.log(event);};

    }

    play(type = '') {
        // console.log('try play')
        if (!localStorage.getItem('notification_played')) {
            // console.log('play')
            localStorage.setItem('notification_played', 'true');
            try {
                switch (type) {
                    case 'cash': {
                        // console.log('1')
                        this.cashAudio.play()
                        break;
                    }
                    case 'error': {
                        // console.log('2')

                        this.errorAudio.play()
                        break;
                    }
                    case 'cash_failed': {
                        // console.log('3')

                        this.cashFailed.play()
                        break;
                    }
                    default: {
                        // console.log('4')

                        this.defaultAudio.play()
                        break
                    }
                }
            } catch (e) {
            }
            setTimeout(() => {
                localStorage.removeItem('notification_played');
            }, 5000);
        } else {
            // console.log('localstoraged')
        }
    }

    handleMessage(message: any) {

        if (message && message.data && message.data.data) {
            message = message.data
        }
        if (message.data.mode === 'DATA') {
            return
        }
        let route = null
        try {
            route = JSON.parse(message.data.route)
        } catch (e) {
        }
        const data = message.data;
        switch (data.type) {
            case 'MESSAGE_RECEIVED':
            case 'WHATSAPP_MESSAGE_RECEIVED': {
                data.subtitle = 'Whatsapp'
                data.photo = 'fab fa-whatsapp'
                if (route && router.currentRoute.name !== route.name) {
                    new Notification(data)
                    if (data.mode !== 'SILENT') {
                        notificationManager.play()
                    }
                }
                break;
            }
            case 'ENGINE_GENERATED': {
                let color = data.color ? data.color : 'secondary';
                let message = data.message ? data.message : 'Un moteur de calcul a été généré'
                let title = data.title ? data.title : 'UNKNOW BRANCH'
                let icon = data.icon ? data.icon : 'fad fa-thumbs-up'
                new Popup(title, message, color, icon);
                notificationManager.play()
                break;
            }
            case 'CHECKOUT_FAILED': {
                let color = data.color ? data.color : 'danger';
                let message = data.message ? data.message : 'Un paiement a échoué'
                let title = data.title ? data.title : 'Echec paiement'
                new Popup(title, message, color, 'fad fa-exclamation-triangle', false);
                notificationManager.play('cash_failed')
                break;
            }
            case 'ENGINE_GENERATION_ERROR': {
                let color = data.color ? data.color : 'danger';
                let message = data.message ? data.message : "La génération d'un moteur de calcul a échou"
                let title = data.title ? data.title : 'Echéc génération de moteur'
                new Popup(title, message, color, 'fad fa-exclamation-triangle', false);
                notificationManager.play('error')
                break;
            }
            case 'PAYMENT_SUCCEED': {
                let color = data.color ? data.color : 'success';
                let message = data.message ? data.message : 'Un paiement a été effectué'
                let title = data.title ? data.title : 'Paiement Effectué'
                new Popup(title, message, color, 'fad fa-cash-register');
                notificationManager.play('cash')
                break;
            }
            case 'CONTRACT_EMITTED': {
                let color = data.color ? data.color : 'success';
                let message = data.message ? data.message : 'Emission'
                let title = data.title ? data.title : 'Un contract a été émis'
                new Popup(title, message, color, 'fad fa-cash-register');
                notificationManager.play()
                break;
            }
            case 'AVENANT_EMITTED': {
                let color = data.color ? data.color : 'success';
                let message = data.message ? data.message : 'Emission'
                let title = data.title ? data.title : 'Un avenant a été émis'
                new Popup(title, message, color, 'fad fa-cash-register');
                notificationManager.play()
                break;
            }
            case 'MENU_UPDATED': {
                store.getters.menus()
                break;
            }
            default: {
                console.log('UNKNOWN NOTIFICATION TYPE ' + data.type)
                console.log(data)
            }
        }
        return true
    }
}

export const notificationManager = new NotificationManager()
