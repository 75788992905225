<template>
  <div class="d-flex justify-content-between mb-5 align-items-center" v-if="maxPage > 1 || showEmpty">
    <h4>Page {{ value }} ▪️ De {{ from }} à {{ to }} sur {{ matchCount }} </h4>
    <div :key="uKey+'_page_'+value">

      <paginate :active-class="'btn-primary text-inverse-primary'" :click-handler="setPage"
                :container-class="'btn-group'" v-model="page" :hide-prev-next="true"
                :next-link-class="'btn btn-sm btn-white bg-hover-primary text-hover-white'" :nextText="'Suivant'"
                :no-li-surround="true" :page-link-class="'btn btn-sm btn-white bg-hover-primary text-hover-white'"
                :pageCount="maxPage" :prev-link-class="'btn btn-sm btn-white bg-hover-primary text-hover-white'"
                :prevText="'Précédent'">
      </paginate>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {helper} from "@/services/Helper";

@Component({})
export default class
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
Pagination extends Vue {
  @Prop({default: 1}) readonly value!: number
  @Prop() count!: number;
  @Prop() matchCount!: number;
  @Prop() itemLength!: number;
  page = 1;
  uKey = helper.generateId()
  @Prop() perPage!: number;
  @Prop({default: false}) showEmpty!: boolean

  setPage(num: number): void {
    // this.$store.state.filter.page = num;
    // this.$store.commit('saveFilter');
    console.log('go to page ' + num)
    this.$emit('input', num)
  }

  mounted(): void {
    this.page = this.value
  }

  get from(): number {
    return this.matchCount > 0 ? ((this.value - 1) * this.perPage) + 1 : 0;
  }

  get maxPage(): number {
    const max = this.matchCount > 0 ? Math.ceil(this.matchCount / this.perPage) : 1;
    if (this.value > max) {
      this.$emit('input', max)
    }
    return max;
  }

  get to(): number {
    return this.matchCount > 0 ? this.from + this.itemLength - 1 : 0;
  }
}
</script>
<style scoped></style>
