import Vue from "vue";
import Vuex, {StoreOptions} from "vuex";
import {api} from "@/services/Api";
import Popup from "@/entity/Popup";
import BranchCategory from "@/entity/BranchCategory";
import {auth} from "@/services/Auth";
import User from "@/entity/User";
import ResponseGroup from "@/entity/ResponseGroup";
import Survey from "@/entity/Survey";
import PackCategory from "@/entity/PackCategory";
import Response from "@/entity/Response";
import Question from "@/entity/Question";
import ItemProperty from "@/entity/ItemProperty";
import ItemType from "@/entity/ItemType";
import Pack from "@/entity/Pack";
import DataType from "@/entity/DataType";
import EngineMethod from "@/entity/EngineMethod";
import Branch from "@/entity/Branch";
import Insurer from "@/entity/Insurer";
import EngineArgument from "@/entity/EngineArgument";
import Guarantee from "@/entity/Guarantee";
import {helper} from "@/services/Helper";
import Order from "@/entity/Order";
import Role from "@/entity/Role";
import Tenant from "../entity/Tenant";
import Partner from "@/entity/Partner";
import Notification from "@/entity/Notification";
import Duration from "@/entity/Duration";
import menus from "@/router/Menu";
import CONSTANTS from "@/Utils/constant";

const defaultTenant = new Tenant();
defaultTenant.code = "wiassur";
defaultTenant.label = "WiASSUR";

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        isLoading: false,
        imageZoom: '',
        user: localStorage.getItem("user") && localStorage.getItem("user") !== "undefined" && localStorage.getItem("user") !== "null" ? new User(JSON.parse(localStorage.getItem("user")!)) : null,
        userToken: localStorage.getItem("userToken") || null,
        userRefreshToken: localStorage.getItem("userRefreshToken") || null,

        popups: [] as Popup[],
        durations: [] as any[],
        users: [] as User[],
        notifications: [] as Notification[],
        notificationsPopups: [] as Notification[],
        encryptionKey: '',
        menus: localStorage.getItem("menus") ? JSON.parse(localStorage.getItem("menus")!) : menus,
        routes: localStorage.getItem("routes") && JSON.parse(localStorage.getItem("routes")!),
        partners: localStorage.getItem("partners")
            ? JSON.parse(localStorage.getItem("partners")!)
            : ([] as Partner[]),
        partnerUsers: localStorage.getItem("partnerUsers")
            ? JSON.parse(localStorage.getItem("partnerUsers")!)
            : ([] as Partner[]),
        loadingPartners: false,
        loadingPartnerUsers: false,
        partnerLoadingTimeout: null as any,
        partnerUserLoadingTimeout: null as any,
        userLoadingTimeout: null as any,
        itemProperties: [] as ItemProperty[],
        itemTypes: [] as ItemType[],
        sources: [] as ItemType[],
        stepStatus: null,
        packs: [] as Pack[],
        engineMethod: EngineMethod,
        engineMethods: [] as EngineMethod[],
        engineBranch: Branch as any,
        engineBranchGuarantees: [] as Guarantee[],
        engineInsurer: Insurer as any,
        returnArguments: [] as EngineArgument[],
        showCode: false,
        arguments: [] as EngineArgument[],
        rgStatus: null,
        onlineRgStatus: null,
        hierarchy: localStorage.getItem("hierarchy")
            ? new Role(JSON.parse(localStorage.getItem("hierarchy")!))
            : new Role(),
        managers: [],
        contractInsurer: null as any,
        recalculated: [] as any[],
        contractUpdateComparison: null,
        tenants: [],
        currentTenant: localStorage.getItem("currentTenant")
            ? new Tenant(JSON.parse(localStorage.getItem("currentTenant")!))
            : defaultTenant,
        partner: localStorage.getItem("partner")
            ? new Partner(JSON.parse(localStorage.getItem("partner")!))
            : null,
        source: null,
        affiliation: null,
        copiedConditionGroups: null as any,
        responseDeletions: [] as any[],
        orders: [],
        deliveries: [],

        currentResponseGroup: ResponseGroup as any,
        currentSurvey: Survey as any,
        currentSurveys: [] as Survey[],
        currentBranch: Branch as any,
        engineClasses: [] as any[],

        questionDragged: null as any,

        lineCounter: 1,
        dataTypes: [] as DataType[],
        insurers: [] as Insurer[],

        rgs: [],
        surveys: [],
        responses: [] as Response[],
        loaded: 0,
        currency: {
            name: "CFA",
            symbol: "CFA",
            left: false,
            factor: 1,
        },
        packCategories: [] as PackCategory[],
        loadingUsers: false,
        loadingHtml: '',
        branches: [], // JSON.parse(localStorage.getItem('branches') || '[]'),
        branchCategories: [], // JSON.parse(localStorage.getItem('branches') || '[]'),
        backend: process.env.VUE_APP_BACKEND_URL,
        selfcare: process.env.VUE_APP_SELFCARE_URL,
        comparator: process.env.VUE_APP_COMPARATOR_URL,
        platform: localStorage.getItem("platform") || "configuration",
        lang: localStorage.getItem("lang") || "fr",
        sleep: (ms: number) => {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        isConnected(): boolean {
            return auth.isConnected();
        },
    },
    mutations: {
        removePopup(state: any, id) {
            const index = state.popups.findIndex((popup: Popup) => {
                return popup.id === id;
            });
            if (index !== -1) {
                state.popups.splice(index, 1);
            }
        },
        zoomImage(state: any, url) {
            state.imageZoom = url
        },
        unzoomImage(state: any) {
            state.imageZoom = ''
        },
        removeResponse(state: any, uuid) {
            console.log('remove ' + uuid)
            if (state.responseDeletions.indexOf(uuid) === -1) {
                state.responseDeletions.push(uuid)
            }
        },
        removeResponseDeletion(state: any, uuid) {

        },
        removeNotification(state: any, uuid) {
            const index = state.notifications.findIndex((notif: Notification) => {
                return notif.uuid === uuid;
            });
            if (index !== -1) {
                state.notifications.splice(index, 1);
            }


        },
        pushNotification(state: any, notification) {
            const index = state.notifications.findIndex((notif: Notification) => {
                return notif.uuid === notification.uuid;
            });
            if (index === -1) {
                state.notifications.push(notification)
            }
        },
        removeNotificationPopup(state: any, uuid) {
            let index = state.notificationsPopups.findIndex((notif: Notification) => {
                return notif.uuid === uuid;
            });
            if (index !== -1) {
                state.notificationsPopups.splice(index, 1);
            }

        },
        pushNotificationPopup(state: any, notification) {
            let index = state.notificationsPopups.findIndex((notif: Notification) => {
                return notif.uuid === notification.uuid || (notif.title === notification.title && notif.content === notification.content);
            });
            if (index !== -1) {
                state.notificationsPopups.splice(index, 1);
            }
            state.notificationsPopups.push(notification)

        },
        loading(state: any, html = '') {
            state.isLoading = true;
            state.loadingHtml = html
        },
        stopLoading(state: any) {
            state.isLoading = false;
            state.loadingHtml = ''
        },
        setSources(state: any, sources) {
            state.sources = sources;
        },
        setTenantByCode(state: any, code: string) {
            let tenant = state.tenants.find((t: Tenant) => {
                return t.code === code;
            });
            if (!tenant) {
                tenant = new Tenant();
                tenant.code = code;
            }
            localStorage.setItem("currentTenant", JSON.stringify(tenant));
            state.currentTenant = tenant;
            window.location.reload();
        },
        async loadUsers(state: any) {
            const res = await api.get(api.auth, "user/list");
            if (res && res.data && res.data.users) {
                state.users = [];
                res.data.users.forEach((i: User) => {
                    state.users.push(new User(i));
                });
            }
        },
        appendRg(state: any, payload: ResponseGroup) {
            const index = state.rgs.findIndex((saved: ResponseGroup) => {
                return saved.uuid === payload.uuid;
            });
            if (index === -1) {
                // console.log('saving rg '+payload.uuid)
                state.rgs.push(payload);
            } else {
                // state.rgs[index] = payload
                // console.log('duplicated rg '+payload.survey.label)
            }
        },
        deleteSubResponseGroup(state: any, uuid: string) {
            const index = state.rgs.findIndex((saved: ResponseGroup) => {
                return saved.uuid === uuid;
            });
            if (index !== -1) {
                state.rgs.splice(index, 1);
            }
        },
        appendOrder(state: any, payload: Order) {
            const index = state.orders.findIndex((order: Order) => {
                return order.uuid === payload.uuid;
            });
            if (index === -1) {
                state.orders.splice(index, 1);
                // console.log('saving rg '+payload.uuid)
            }
            state.orders.push(payload);
        },
        responseSaved(state: any, payload: any) {
            state.rgStatus = helper.generateId();
        },
        onlineResponseSaved(state: any, payload: any) {
            state.onlineRgStatus = helper.generateId();
        },
        checkStepValidation(state: any, payload: any) {
            state.stepStatus = helper.generateId();
        },
        addResponse(state: any, question: Question) {
            state.rgs.forEach((rg: ResponseGroup) => {
                if (rg.uuid === question.response.responseGroupUuid) {
                    const index = rg.responses.findIndex((re: Response) => {
                        if (re.question) {
                            return re.question!.uuid === question.uuid;
                        }
                        if (re.questionUuid) {
                            return re.questionUuid === question.uuid;
                        }
                    });
                    if (index === -1) {
                        // console.log('response inexistante dans la cotation')
                        rg.responses.push(question.response);
                    } else {
                        // console.log('ecraser la reponse '+index)
                        rg.responses[index] = question.response;
                    }
                    // rg.responses.forEach((r:Response)=>{
                    //     console.log(r.question!.label +' => '+r.value)
                    // })
                }
            });
        },
        appendSurvey(state: any, payload: Survey) {
            if (payload && payload.uuid) {
                const index = state.surveys.findIndex((saved: Survey) => {
                    return saved.uuid === payload.uuid;
                });
                if (index === -1) {
                    // console.log('saving survey '+payload.slug)
                    state.surveys.push(payload);
                }
            }
        },
        appendResponse(state: any, payload: Response) {
            const index = state.responses.findIndex((saved: Response) => {
                return saved.uuid === payload.uuid;
            });
            if (index === -1) {
                // console.log('saving survey '+payload.slug)
                state.responses.push(payload);
            }
        },
        async resetResponse(state: any, question: Question) {
            question.isSending = true;
            const res = await api.get(
                api.form,
                "response/reset/" + question.response.uuid
            );
            if (res) {
                const response = state.responses.find((r: Response) => {
                    return r.uuid === question.response.uuid;
                });
                response.value = null;
                response.currentValue = null;
                question.response.value = null as any;
                question.response.currentValue = null as any;
                question.validate();
                state.rgStatus = helper.generateId();
                // console.log('reset response end')
            }
            question.isSending = false;
        },

        setForm(state: any, payload: any) {
            state.form = payload;
        },
        setResponses(state: any, payload: any) {
            state.responses = payload;
        },
        updateContractItems(state: any, uuid) {
            state.contractUpdateComparison = uuid;
        },

        async handleSource(state: any) {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            if (urlParams.has("source")) {
                const source = urlParams.get("source");
                if (source) {
                    if (source) {
                        const res = await api.get(api.core, "api/source/infos/" + source);
                        // console.log(data)
                        // return
                        if (res && res.data.status === "success") {
                            sessionStorage.setItem(
                                "partner",
                                JSON.stringify(res.data.partner)
                            );
                        }
                    }
                    // sessionStorage.setItem('partner', source)
                }
            }
            const partner = sessionStorage.getItem("partner");
            if (partner) {
                state.partner = JSON.parse(partner);
            }
        },
        logout(state: any) {
            localStorage.removeItem("userToken");
            localStorage.removeItem("userRefreshToken");
            localStorage.removeItem("token");
            state.user = null;
            state.userToken = null;
        },
    },
    actions: {
        async resetResponse({commit, state}, payload) {
            // console.log(payload)
            await commit("resetResponse", payload);
            // console.log('reset end')
        },
    },
    modules: {},
    getters: {
        branchCategories: (state, getters) => async () => {
            if (state.branchCategories.length > 0) {
                return state.branchCategories;
            }
            const res = await api.get(api.core, "branch/category/list");
            if (res && res.data && res.data.categories) {
                state.branchCategories = [];
                res.data.categories.forEach((c: BranchCategory) => {
                    state.branchCategories.push(new BranchCategory(c));
                });
            }
            return state.branchCategories;
        },
        menus: (state, getters) => async () => {
            const savedMenus = localStorage.getItem('menus') ? JSON.parse(localStorage.getItem('menus')!) : menus
            api.axios.get(api.auth + 'api/menu/list').then((res: any) => {
                if (res && res.data && res.data.menu && !helper.empty(res.data.menu)) {
                    state.menus = res.data.menu
                }
            })

            return savedMenus
        },

        loadRanges: (state, getters) => () => {
            const today = new Date()
            today.setHours(0, 0, 0, 0)
            const yesterday = new Date()
            yesterday.setDate(today.getDate() - 1)
            yesterday.setHours(0, 0, 0, 0);
            return {
                "Aujourdhui": [today, today],
                'Hier': [yesterday, yesterday],
                'Ce mois': [CONSTANTS.dateConstants.first_day_current_month(), CONSTANTS.dateConstants.last_day_current_month()],
                'Le mois dernier': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
                'Dernier Trimestre': [CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 2)), CONSTANTS.dateConstants.last_day_current_month()],
                'Dernier Semestre': [CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 5)), CONSTANTS.dateConstants.last_day_current_month()],
                "Cette année": [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
                "L'année passée": [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)],
                "2 Dernières années": [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear(), 11, 31)],
            }
        },
        packCategories: (state, getters) => async (slug: string) => {
            if (state.packCategories.length > 0) {
                return state.packCategories;
            }
            const res = await api.get(api.core, "api/pack/category/list/" + slug);
            if (res && res.data && res.data.categories) {
                state.packCategories = [];
                res.data.categories.forEach((pc: PackCategory) => {
                    state.packCategories.push(new PackCategory(pc));
                });
            }
            return state.packCategories;
        },
        insurers: (state, getters) => async () => {
            if (state.insurers.length > 0) {
                return state.insurers;
            }
            const res = await api.get(api.core, "insurer/list/min");
            if (res && res.data && res.data.insurers) {
                state.insurers = [];
                res.data.insurers.forEach((i: Insurer) => {
                    state.insurers.push(new Insurer(i));
                });
            }
            return state.insurers;
        },
        durations: (state, getters) => async () => {
            if (state.durations.length > 0) {
                return state.durations;
            }
            const res = await api.get(api.master, 'api/get/durations')
            if (res && res.data && res.data.durations) {
                state.durations = [];
                res.data.durations.forEach((dur: Duration) => {
                    state.durations.push(new Duration(dur))
                })
            }
            return state.durations;
        },
        tenants: (state, getters) => async () => {
            if (state.tenants.length > 0) {
                return state.tenants;
            }
            const tenants: any = localStorage.getItem("tenants")
                ? JSON.parse(localStorage.getItem("tenants")!)
                : [];
            const formatted = [];
            for (let tenant of tenants) {
                formatted.push(new Tenant(tenant));
            }
            state.tenants = formatted;
            return state.tenants;
        },
        deliveries: (state, getters) => async () => {
            const res = await api.get(api.form, "order/my/delivery/list");
            const orders: Order[] = [];
            let i = 0;
            if (res && res.data && res.data.orders) {
                res.data.orders.forEach((o: any) => {
                    const occ = new Order(o);
                    const exist = state.deliveries.find((order: Order) => {
                        return order.uuid === occ.uuid;
                    });
                    if (!exist) {
                        i++;
                    }
                    orders.push(occ);
                });
            }
            if (i > 0) {
                new Popup(
                    "Livraison",
                    "Vous avez de nouvelles demandes de livraison",
                    "danger",
                    "fad fa-bell",
                    true
                );
            }
            state.deliveries = orders;
            return state.deliveries;
        },
        currentTenant: (state, getters) => () => {
            if (state.currentTenant instanceof Tenant) {
                // console.log('is defined')
                return state.currentTenant;
            }
            const current: any = localStorage.getItem("currentTenant")
                ? JSON.parse(localStorage.getItem("currentTenant")!)
                : {
                    code: "wiassur",
                    label: "WiASSUR",
                };
            // console.log('current ', current)
            state.currentTenant = new Tenant(current);
            return state.currentTenant;
        },
        users:
            (state, getters) =>
                async (force = false) => {
                    const users = localStorage.getItem("users")
                        ? JSON.parse(localStorage.getItem("users")!)
                        : [];
                    if (users && !force) {
                        state.users = [];
                        users.forEach((u: any) => {
                            const occ = new User(u);
                            state.users.push(u);
                        });
                    }
                    if (state.users.length > 0 && !force) {
                        return state.users;
                    }
                    if (state.loadingUsers) {
                        return state.users;
                    }
                    state.loadingUsers = true;
                    const ut = localStorage.getItem("userType");
                    let endpoint = "user/list";
                    if (ut === "partner") {
                        return [];
                    }
                    const res = await api.get(api.auth, endpoint);
                    if (res && res.data && res.data.users) {
                        state.users = [];
                        res.data.users.forEach((i: User) => {
                            state.users.push(new User(i));
                        });
                        localStorage.setItem("users", JSON.stringify(state.users));
                    }
                    state.loadingUsers = false;
                    return state.users;
                },
        partners:
            (state, getters) =>
                async (force = false) => {
                    const partners = localStorage.getItem("partners")
                        ? JSON.parse(localStorage.getItem("partners")!)
                        : [];
                    if (partners && !force) {
                        state.partners = [];
                        partners.forEach((u: any) => {
                            const occ = new Partner(u);
                            state.partners.push(occ);
                        });
                    }
                    if (state.partners.length > 0 && !force) {
                        return state.partners;
                    }
                    if (state.loadingPartners) {
                        return state.partners;
                    }
                    state.loadingPartners = true;

                    clearTimeout(state.partnerLoadingTimeout);
                    state.partnerLoadingTimeout = setTimeout(async () => {
                        clearTimeout(state.partnerLoadingTimeout);
                        const res = await api.get(api.auth, "partner/list");
                        if (res && res.data && res.data.partners) {
                            state.partners = [];
                            res.data.partners.forEach((i: Partner) => {
                                state.partners.push(new Partner(i));
                            });
                            if (res.data.users) {
                                res?.data?.users.forEach((i: any) => {
                                    const occ = new Partner(i)
                                    state.partnerUsers.push(occ)
                                })
                            }

                            localStorage.setItem("partners", JSON.stringify(state.partners));
                            localStorage.setItem("partnerUsers", JSON.stringify(state.partnerUsers));
                        }
                        state.loadingPartners = false;
                    }, 500);
                    return state.partners;
                },
        partnerUsers:
            (state, getters) =>
                async (force = false) => {
                    const partners = localStorage.getItem("partnerUsers") ? JSON.parse(localStorage.getItem("partnerUsers")!) : [];
                    if (partners && !force) {
                        state.partnerUsers = [];
                        partners.forEach((u: any) => {
                            const occ = new Partner(u);
                            state.partnerUsers.push(occ);
                        });
                    }
                    if (state.partnerUsers.length > 0 && !force) {
                        return state.partnerUsers;
                    }
                    if (state.loadingPartnerUsers) {
                        return state.partnerUsers;
                    }
                    state.loadingPartnerUsers = true;

                    clearTimeout(state.partnerUserLoadingTimeout);
                    state.partnerUserLoadingTimeout = setTimeout(async () => {
                        clearTimeout(state.partnerUserLoadingTimeout);
                        const res = await api.get(api.auth, "partner/user/list");
                        if (res && res.data && res.data.users) {
                            state.partnerUsers = [];
                            res.data.users.forEach((i: any) => {
                                state.partnerUsers.push(new Partner(i));
                            });
                            localStorage.setItem("partnerUsers", JSON.stringify(state.partnerUsers));
                        }
                        state.loadingPartnerUsers = false;
                    }, 500);
                    return state.partnerUsers;
                },
        roles:
            (state, getters) =>
                async (force = false) => {
                    const hierarchy = localStorage.getItem("hierarchy")
                        ? JSON.parse(localStorage.getItem("hierarchy")!)
                        : null;
                    if (hierarchy && !force) {
                        state.hierarchy = new Role(hierarchy);
                        return state.hierarchy;
                    }

                    const res = await api.get(api.auth, "role/list");
                    if (res && res.data && res.data.role) {
                        state.hierarchy = new Role(res.data.role);
                        localStorage.setItem("hierarchy", JSON.stringify(state.hierarchy));
                    }
                    return state.hierarchy;
                },
        role: (state, getters) => async (code: string) => {
            return state.hierarchy.findByCode(code);
        },
        user: (state, getters) => async (uuid: string) => {
            let manager = state.users.find((u: User) => {
                return u.email === uuid || u.uuid === uuid;
            });
            if (manager) {
                return manager;
            }

            const res = await api.get(api.auth, "user/list");
            if (res && res.data && res.data.users) {
                state.users = [];
                res.data.users.forEach((i: User) => {
                    state.users.push(new User(i));
                });
            }
            manager = state.users.find((u: User) => {
                return u.email === uuid || u.uuid === uuid;
            });
            if (manager) {
                return manager;
            }
        },
        dataTypes: (state, getters) => async () => {
            if (state.dataTypes.length > 0) {
                return state.dataTypes;
            }
            const res = await api.get(api.master, "api/get/data/types");
            if (res && res.data && res.data.types) {
                state.dataTypes = [];
                res.data.types.forEach((pc: DataType) => {
                    state.dataTypes.push(new DataType(pc));
                });
            }
            return state.dataTypes;
        },
        branchBySlug: (state, getters) => async (slug: string) => {
            const categories = await getters.branchCategories;
            // console.log(categories)
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                // console.log('checking cat '+category.label)
                for (let j = 0; j < category.branches.length; j++) {
                    const branch = category.branches[j];
                    // console.log('checkin branch '+branch.label)
                    if (branch.slug === slug) {
                        return branch;
                    }
                }
            }
            new Popup(
                "",
                Vue.prototype.trans(
                    "An_error_occurred_when_trying_to_load_this_branch_data"
                ),
                "danger"
            );
            return null;
        },
    },
});
