<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div class="container-fluid d-flex flex-stack" id="kt_toolbar_container">
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link :to="{ name: 'dashboard' }" class="text-muted text-hover-primary">
                    <i class="fad fa-home"></i>
                    {{ trans('Survey_list') }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>


          <div class="d-flex align-items-center">
            <div @click="saveState(true,true)" class="btn btn-sm btn-danger me-5">
              <i class="fad fa-file-download"></i>
              Télécharger
            </div>
            <label class="btn btn-sm btn-info me-5">
              <i class="fad fa-file-upload"></i>
              Charger
              <input type="checkbox" v-model="showStates" class="hidden">

            </label>
            <router-link :to="{ name: 'item-list', params: { slug: $route.params.branchSlug } }"
                         class="d-flex align-items-center py-1" v-if="hasRole('ROLE_ADMIN')">
              <button class="btn btn-primary btn-sm">
                <i class="fad fa-object-group"></i>
                {{ trans('Objets') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <h1 class="d-flex align-items-center justify-content-between" v-if="branch">
        <div class="d-flex align-items-center">
          <i :class="branch.icon + ' fs-2x me-5'" :style="{ 'color': branch.color }"></i>
          <span>{{ branch.label }} ▪ {{ branch.category.label }}</span>
        </div>
        <div>
          <span class="badge badge-danger">Devis</span>
        </div>
      </h1>
      <div :key="uKey" class="d-flex">
        <div class="" style="max-width: 70%">
          <!--                    Formuliare-->
          <template v-for="item in items">
            <template v-if="item.model.required">
              <ItemManager :insurers="insurers" :item="item" :items="items" :packCategories="packCategories"
                           :packGuarantees="packGuarantees" :packs="packs" :premiumDetailModels="premiumDetailModels"
                           v-on:update="update" :commissionModels="commissionModels"/>
            </template>
          </template>
          <template v-for="item in items">
            <template v-if="item.model.isRoot">
              <ItemManager :lockComparison="lockComparison" :certificateRemaining="certificateRemaining"
                           :branch="branch" :insurers="insurers" :item="item"
                           :items="items" :commissionModels="commissionModels"
                           :packCategories="packCategories" :packGuarantees="packGuarantees" :packs="packs"
                           :premiumDetailModels="premiumDetailModels" v-on:appendItem="appendItem"
                           v-on:update="update"/>
            </template>
          </template>
        </div>
        <div class=" overflow-auto mh-100 mx-5 flex-grow-1 align-self-start" style="position: sticky;top:130px;flex:1;">
          <div class="card my-3">
            <template>
              <div class="card-body p-0">
                <div class="text-center px-5 pt-5">
                  <div v-if="result && result.insurer">
                    <div style="max-width:90%;max-height:50px;margin:0 auto" v-if="result.insurer.photo">
                      <img :src="uploadRoot + result.insurer.photo" alt=""
                           class="w-100 h-100 object-contain" style="max-height: 50px">
                    </div>
                    <h1>{{ result.insurer.label }}</h1>
                  </div>
                  <div>
                    <h1 class="pt-2 " style="white-space: nowrap">Affaire Nouvelle</h1>
                  </div>
                  <!--                                    =>{{result}}<<-->
                  <div class="text-primary fw-bolder">
                    <div :key="result.uuid" class="fs-3x d-flex align-items-center" v-if="result">
                      <transition mode="out-in" name="slide-left">
                        <input type="number" class="form-control text-end" v-if="editPremium" v-model="result.premium"/>
                        <div class="fw-bolder cotation-detail flex-grow-1" v-else>
                          {{ helper.currencyFormat(result.premium) }}
                        </div>
                      </transition>

                      <div class="btn btn-success btn-icon btn-sm ms-5" @click="editPremium=false" v-if="editPremium">
                        <i class="fad fa-save"></i>
                      </div>
                      <div class="btn btn-primary btn-icon btn-sm ms-5" @click="editPremium=true" v-else>
                        <i class="fad fa-edit"></i>
                      </div>
                    </div>
                    <div>
                      {{ getDuree() }}
                    </div>
                  </div>

                  <div
                      class="text-primary fw-bolder d-flex flex-wrap justify-content-center align-items-center">
                    <div class="" v-if="start">Du {{ readableDate(start, 'dmY') }}</div>
                    <div class="ms-2" v-if="end">Au {{ readableDate(end, 'dmY') }}</div>
                    <div class="ms-2" v-if="dureeEnJour()">({{ dureeEnJour() }}
                      <template v-if="dureeEnJour() > 1">jours</template>
                      <template v-else>jour</template>
                      )
                    </div>
                  </div>

                </div>
                <div class="py-2 text-center" v-if="result && result.premium">
                  <div @click="save(result)" class="btn btn-primary">
                    Souscrire
                  </div>
                </div>
                <div class="py-5">
                  <div class="fw-bolder p-5 fs-2 d-flex justify-content-between">
                    <div>
                      Détails de facture
                    </div>
                    <div>
                    </div>
                  </div>

                  <div class="mh-500px overflow-auto mx-5">
                    <table
                        class="table g-2 table-head-custom text-center table-head-bg table-borderless table-vertical-center table-hover table-striped">
                      <thead>
                      <tr>
                        <th>Label</th>
                        <th>Opérateur</th>
                        <th>Montant</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-for="detail in premiumDetailModels">
                        <tr>
                          <td>
                            {{ detail.label }}
                          </td>
                          <td>
                            {{ detail.operator }}
                          </td>
                          <td class="text-end">
                            <div class="d-flex align-items-center" :set="premiumDetail = getDetail(detail)">
                              <transition mode="out-in" name="slide-left">
                                <input type="number" class="form-control text-end" :key="'input_' + detail.id"
                                       v-if="detail.edit || !getDetail(detail)" v-model="detail.value"/>
                                <div
                                    :key="premiumDetail.detail ? helper.slugify(premiumDetail.detail.id) : helper.generateId()"
                                    class="fw-bolder cotation-detail flex-grow-1" v-else-if="premiumDetail">
                                  {{ helper.currencyFormat(premiumDetail.premium, false, false) }}
                                </div>
                              </transition>
                              <label class="btn btn-icon btn-sm btn-warning ms-5" v-if="premiumDetail && !detail.edit"
                                     @click="editDetail(detail)">
                                <input type="checkbox" v-model="detail.edit" class="hidden"/>
                                <i class="fad fa-edit"></i>
                              </label>
                              <label class="btn btn-icon btn-sm btn-success ms-5" v-else
                                     @click="saveDetail(detail)" :key="'save_' + detail.id">
                                <input type="checkbox" v-model="detail.edit" class="hidden">
                                <i class="fad fa-save"></i>
                              </label>
                            </div>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>

                  <div class="overflow-auto border pb-5">
                    <div class="fw-bolder p-5 fs-2 d-flex justify-content-between">
                      <div>
                        Commissions
                      </div>
                      <div>
                      </div>
                    </div>

                    <div class="mx-5">
                      <table
                          class="table g-2 table-head-custom text-center table-head-bg table-borderless table-vertical-center table-hover table-striped">
                        <thead>
                        <tr>
                          <th>Label</th>
                          <th>Montant</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="commission in commissions">
                          <td>
                            {{ commission.model.label }}
                          </td>
                          <td>
                            <input type="number" v-model="commission.premium" class="form-control text-end">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="overflow-auto border pb-5">
                    <div class="fw-bolder p-5 fs-2 d-flex justify-content-between">
                      <div>
                        Echéancier
                      </div>
                      <div>
                        {{ currency(reste) }}
                      </div>
                    </div>
                    <div class="mx-5">
                      <table v-if="deadlines.length > 0"
                             class="table g-2 table-head-custom text-center table-head-bg table-borderless table-vertical-center table-hover table-striped">
                        <thead>
                        <tr>
                          <th>Date</th>
                          <th>Montant</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(deadline,index) in deadlines">
                          <td>
                            <input type="date" class="form form-control" v-model="deadline.date">
                          </td>
                          <td>
                            <input type="number" class="form form-control" v-model="deadline.premium">
                          </td>
                          <td>
                            <div class="btn btn-danger btn-icon btn-xs" @click="removeDeadline(index)">
                              <i class="fad fa-times"></i>
                            </div>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div>
                      <div class="d-flex justify-content-center pb-5">
                        <div class="btn btn-sm btn-primary" @click="addDeadline">
                          <i class="fad fa-plus"></i>
                          Ajouter une échéance
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="overflow-auto" v-if="showAllDetails">
                    <div class="fw-bolder p-5 fs-2 d-flex justify-content-between">
                      <label class="cursor-pointer">
                        <span class="me-2">
                          <i class="fad fa-caret-down fs-2" v-if="expandDetails"></i>
                          <i class="fad fa-caret-right fs-2" v-else></i>
                          </span>
                        <input type="checkbox" v-model="expandDetails" class="hidden">
                        Tous les Détails
                      </label>
                      <div>
                        <div class="btn btn-icon btn-xs btn-primary" v-b-modal="'detailModal'">
                          <i class="fad fa-plus"></i>
                        </div>
                      </div>
                    </div>
                    <table class="table table-striped gs-5 gy-2" v-if="result && expandDetails">
                      <thead></thead>
                      <tbody>
                      <template v-for="detail in result.details">
                        <tr class="bg-hover-secondary" :key="detail.code">
                          <td>
                            {{ detail.name }}
                          </td>
                          <td class="text-end fw-bolder text-primary">
                            <div class="d-flex">
                              <b-form-input :value="detail.value" class="text-end"
                                            @change="updateUserData(detail,$event)"></b-form-input>
                            </div>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>

                  <div class="d-flex justify-content-center py-5">
                    <label class="btn btn-primary" v-if="!showAllDetails">
                      <input type="checkbox" v-model="showAllDetails" class="hidden">
                      Afficher tous les details
                    </label>
                    <label class="btn btn-primary btn-sm" v-else>
                      <input type="checkbox" v-model="showAllDetails" class="hidden">
                      Masquer tous les details
                    </label>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="d-flex justify-content-center">
            <div class="py-2 text-center">
              <button :data-kt-indicator="calculating ? 'on' : 'off'" :disabled="calculating" @click.prevent="compare"
                      class="btn btn-lg btn-danger">
                <span class="indicator-label">Recalculer</span>
                <span class="indicator-progress">
                  En cours...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <b-modal :title="trans('Ajouter un detail')" centered id="detailModal" ref="detailModal">
        <form>
          <div class="row">
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'label'" class="required form-label">{{ trans('Label') }}</label>
              <b-form-input :id="'label'" :state="!helper.empty(detail.name)"
                            v-model="detail.name"></b-form-input>
            </div>
            <div class="col-auto m-2 flex-grow-1">
              <label :for="'code'" class="required form-label">{{ trans('Code') }}</label>
              <b-form-input :id="'code'" v-model="detail.value"></b-form-input>
            </div>
          </div>
        </form>
        <template #modal-footer="{ ok, cancel, hide }">
          <b-button @click="cancel()" size="sm" variant="secondary">
            <i class="fad fa-times"></i>
            {{ trans('Cancel') }}
          </b-button>
          <b-button @click="addDetail()" size="sm" variant="primary">
            <i class="fad fa-save"></i>
            {{ trans('Save') }}
          </b-button>
        </template>
      </b-modal>

      <transition name="slide-left-back">
        <div class="position-fixed bg-white shadow-lg top-0 h-100" style="right:0;z-index:100" v-if="showStates">

          <div class="d-flex justify-content-between align-items-center  p-5">
            <h1 class="mb-0">{{ trans('Etats') }}</h1>
            <div class="btn btn-danger btn-xs ms-5 btn-icon" @click="showStates = false">
              <i class="fad fa-times"></i>
            </div>
          </div>
          <div>

            <div class="border-top">
              <div v-for="state in states" class="d-flex px-10 py-2 align-items-center border-bottom">
                <div>
                  <div class="text-uppercase fw-bolder">
                    {{ state.branch }}
                  </div>
                  <div>
                    {{ datify(state.date, 'W dMy H:i') }}
                  </div>
                </div>
                <div class="px-10">
                  <div>
                    {{ state.subscriber }}
                  </div>
                  <div class="text-uppercase fw-bolder">
                    {{ state.alias }}
                  </div>
                </div>
                <div>
                  <div class="btn btn-xs btn-primary btn-icon" @click="loadState(state)">
                    <i class="fad fa-play"></i>
                  </div>
                  <div class="btn btn-xs ms-2 btn-danger btn-icon" @click="deleteState(state)">
                    <i class="fad fa-times"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex py-5 justify-content-center px-10">
              <label class="btn btn-primary">
                Charger un Fichier
                <input type="file" accept=".devis" @change="loadJsonData" class="hidden">
              </label>
            </div>
          </div>

        </div>
      </transition>
    </template>
  </Base>
</template>
<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Popup from "@/entity/Popup";
import {api} from "@/services/Api";
import DataType from "@/entity/DataType";
import ItemType from "@/entity/ItemType";
import Branch from "@/entity/Branch";
import Item from "@/entity/Item";
import PropertyManager from "@/views/Income/Avenant/PropertyManager.vue";
import ItemManager from "@/views/Income/Avenant/ItemManager.vue";
import {helper} from "@/services/Helper";
import PremiumDetailModel from "@/entity/PremiumDetailModel";
import Pack from "@/entity/Pack";
import Insurer from '@/entity/Insurer';
import PackCategory from "@/entity/PackCategory";
import PackGuarantee from "@/entity/PackGuarantee";
import ItemProperty from "@/entity/ItemProperty";
import PremiumDetail from "@/entity/PremiumDetail";
import BranchCategory from "@/entity/BranchCategory";
import PropertyChoice from "@/entity/PropertyChoice";
import Guarantee from "@/entity/Guarantee";

import Order from '@/entity/Order';
import Mapping from '@/entity/Mapping';
import Duration from '@/entity/Duration';
import {auth} from '@/services/Auth';
import store from "@/store";
import CommissionModel from "@/entity/CommissionModel";
import Commission from "@/entity/Commission";

@Component({
  components: {ItemManager, PropertyManager, Base}
})
export default class AffaireNouvelle extends Vue {
  [x: string]: any;

  editPremium = false
  expandDetails = false
  dataTypes: DataType[] = [];
  itemTypes: ItemType[] = [];
  items: Item[] = []
  showStates = false
  branch: Branch | null = null
  lockComparison = false
  objects: Item[] = []
  uKey = helper.generateId()
  packCategories: PackCategory[] = []
  insurers: Insurer[] = []
  packs: Pack[] = []
  detail = {
    name: '',
    value: 0
  }
  userData: any = {}
  insurer: any = null
  packCategory: any = null
  requirements: Item[] = []
  root: any = null
  showAllDetails = true
  packGuarantees: PackGuarantee[] = []
  premiumDetailModels: PremiumDetailModel[] = []
  commissionModels: CommissionModel[] = []
  avenant: any = null
  guarantees: Guarantee[] = []
  uploadRoot = api.uploadRoot
  mappings: Mapping[] = []
  durations: Duration[] = []
  engineLoaded = false
  deadlines: any[] = []

  checkDuration() {
    // console.log('start ' + this.start)
    // console.log('end ' + this.end)
  }

  getId() {
    return helper.generateId()
  }

  updateUserData(detail: any, event: any) {
    // console.log(detail)
    // console.log(event)
    // console.log(auth.getCurrentUser())
    this.userData[detail.code] = {
      label: detail.name,
      value: parseFloat(event + ''),
      source: auth.getCurrentUser().uuid
    }
    // console.log(this.userData)
    // console.log(this.result)
  }

  get reste() {
    if (this.result) {
      let premium = this.result.premium
      for (let deadline of this.deadlines) {
        if (!isNaN(deadline.premium)) {
          premium -= deadline.premium
        }
      }
      return premium
    }
    return 0
  }

  addDeadline() {
    let total = 0
    for (let deadline of this.deadlines) {
      if (helper.empty(deadline.date) || deadline.premium == 0) {
        new Popup('Erreur', 'Veuillez renseignez les échéances précédentes');
        return
      }
      if (!isNaN(deadline.premium)) {
        total += parseFloat(deadline.premium)
      }
    }
    if (this.result && this.deadlines.length > 0 && total >= this.result.premium) {
      new Popup('Erreur', "La somme des échéances est supérieur ou égal au montant de l'avenant");
      return
    }
    const deadline = {date: null, premium: this.reste}
    this.deadlines.push(deadline)
  }

  removeDeadline(index: any) {
    this.deadlines.splice(index, 1)
  }

  addDetail() {
    if (this.result) {
      this.result.details.push(this.detail)
      this.detail = {name: '', value: 0};
      (this.$refs.detailModal as any).hide()
    }
    this.saveState(true)
  }

  getDetail(model: PremiumDetailModel) {
    if (this.result && this.premiumDetails) {
      return this.premiumDetails.find((pd: PremiumDetail) => {
        return pd.model.id === model.id
      })
    }
  }

  get validResultCount() {
    let i = 0
    this.results.forEach((r: any) => {
      if (!helper.empty(r.premium) && !isNaN(r.premium)) {
        i++
      }
    })
    return i
  }

  saveDetail(detail: PremiumDetailModel) {
    this.lockComparison = true
    let index = this.premiumDetails.findIndex((pd: PremiumDetail) => {
      return pd.model.id === detail.id
    })
    if (index === -1) {
      let pd = new PremiumDetail()
      pd.model = detail
      pd.premium = detail.value
      this.premiumDetails.push(pd)
    } else {
      this.premiumDetails[index].premium = detail.value
    }
    if (detail.method) {
      this.userData[detail.method.code] = {
        label: detail.label,
        value: parseFloat(detail.value + ''),
        source: auth.getCurrentUser().uuid
      }
    } else {
      this.userData[helper.slugify(detail.label)] = {
        label: detail.label,
        value: parseFloat(detail.value + ''),
        source: auth.getCurrentUser().uuid
      }
    }
    this.compare()
    this.saveState(true)
  }

  editDetail(detail: PremiumDetailModel) {
    const premiumDetail = this.getDetail(detail)
    if (premiumDetail) {
      detail.value = premiumDetail.premium
    }
  }

  async mounted() {
    this.$store.state.contractInsurer = null
    this.result = null
    // this.loadMapping()
    this.$store.commit('loading')
    // const res = await Promise.all([this.loadDurations(), this.loadData(), this.loadEngine()])
    this.loadDurations()
    await this.loadData()
    this.loadEngine()
    this.loadCountCertificate()
    this.$store.commit('stopLoading')
    this.connectRealTimeAttestationCount()
    this.getSessionStates()
  }

  async loadMapping() {
    // const res = await api.get(api.form, 'mapping/list/' + this.$route.params.branchSlug)
    // console.log(res)
  }

  // subscribeToPack(result: any) {
  //     console.log(result)
  // }

  states: any[] = []

  uuid = helper.generateId()


  async loadData() {
    const res = await api.get(api.core, 'contract/new/' + this.$route.params.branchSlug)
    if (res && res.data) {
      // console.log(res.data)
      this.branch = new Branch(res.data.branch)
      this.branch.category = new BranchCategory(res.data.branchCategory)

      res.data.premiumDetailModels.forEach((pdm: PremiumDetailModel) => {
        const occ = new PremiumDetailModel(pdm)
        this.branch?.premiumDetailModels.push(occ)
      })

      this.items = []
      res.data.items.forEach((itemType: any) => {
        const item = new Item(itemType)
        this.items.push(item)
        if (item.model!.isRoot) {
          this.root = item
        }
      })

      this.guarantees = []
      res.data.guarantees.forEach((g: Guarantee) => {
        const occ = new Guarantee(g)
        this.guarantees.push(occ)
      })
      this.$store.state.engineBranchGuarantees = this.guarantees
      this.$store.state.itemProperties = []
      const propModels: ItemProperty[] = []
      res.data.itemProperties.forEach((c: ItemProperty) => {
        const occ = new ItemProperty(c)
        propModels.push(occ)
      })
      this.$store.state.itemProperties = propModels

      this.packCategories = []
      res.data.packCategories.forEach((c: PackCategory) => {
        const occ = new PackCategory(c)
        this.packCategories.push(occ)
      })

      this.packs = []
      res.data.packs.forEach((i: Pack) => {
        const occ = new Pack(i)
        this.packs.push(occ)
      })
      this.$store.state.packs = this.packs

      this.insurers = []
      res.data.insurers.forEach((i: Insurer) => {
        const occ = new Insurer(i)
        this.insurers.push(occ)
      })
      this.$store.state.insurers = this.insurers


      this.premiumDetailModels = []
      res.data.premiumDetailModels.forEach((pdm: PremiumDetailModel) => {
        const occ = new PremiumDetailModel(JSON.parse(JSON.stringify(pdm)))
        this.premiumDetailModels.push(occ)
      })
      this.commissionModels = []
      res.data.commissionModels.forEach((c: any) => {
        const occ = new CommissionModel(c)
        this.commissionModels.push(occ)
      })
      // console.log(this.commissionModels.length)
    }

    ////////////////////////////////////////////////////

    ///////////////////////////////////////////////////
    this.checkPremiumDetails()
    this.checkCommissions()

    // this.$store.commit('stopLoading')
  }

  async loadJsonData(e: any) {
    this.lockComparison = true
    this.showStates = false;
    const file = e.target.files[0]
    var reader = new FileReader();
    let data: any = null;
    reader.onload = async (evt: any) => {
      const result = JSON.parse(evt.target.result)
      this.loadState(result)
    }
    reader.readAsText(file, "UTF-8");

  }

  async loadState(result: any) {
    this.showStates = false
    this.lockComparison = true
    if (!result) {
      new Popup('Erreur', 'Données incompatibles')
      return
    }
    if (this.$route.params.branchSlug !== result.branch) {
      new Popup('Erreur Branche', 'Ces données correspondent à la branche ' + result.branch)
      return
    }
    if (this.$store.getters.currentTenant().code !== result.zone) {
      new Popup('Erreur Zone', 'Ces données correspondent à la zone ' + result.zone)
      return
    }
    this.uuid = result.uuid
    const data = result.items
    if (data) {
      data.forEach((r: any) => {
        const item = new Item(r)
        const index = this.items.findIndex((i: Item) => {
          return i.model.code === item.model.code
        })
        if (index !== -1) {
          item.model = new ItemType({...this.items[index].model})
          this.items.splice(index, 1)
          this.items.push(item)
        }
        if (item.model!.isRoot) {
          this.root = new Item(item)
        }
      })
    }
    this.userData = result.userData ?? {}
    this.premiumDetails = result.premiumDetails ?? [];
    this.avenant.premiumDetails = this.premiumDetails
    if (result.deadlines) {
      this.deadlines = result.deadlines
    }
    if (result.commissions) {
      this.commissions = result.commissions
    }
    // console.log(result)
    this.checkPremiumDetails()
    this.checkCommissions()
    this.loadPremiumDetails()
    // this.compare()
    this.storeState(result)
    if (result.result) {
      this.result = result.result
      this.$store.state.currentInsurer = result.result.insurer
    }

  }

  async saveState(isStored = false, download = false) {
    const items: any = []
    this.items.forEach((i: Item) => {
      if (i.model.required || i.model.isRoot) {
        items.push(i.simplify())
      }
    })

    const now = new Date()

    const subscriber: Item = this.items.find((i: Item) => {
      return i.model.code === 'souscripteur'
    }) as any

    const root: Item = this.items.find((i: Item) => {
      return i.model.isRoot
    }) as any

    const ct = store.getters.currentTenant();
    let state = {
      uuid: this.uuid,
      subscriber: subscriber ? subscriber.alias : '',
      alias: root.getAssetAliases(),
      date: now.toISOString(),
      userData: this.userData,
      premiumDetails: this.premiumDetails,
      branch: this.branch?.slug,
      zone: ct.code,
      items: items,
      deadlines: this.deadlines,
      result: this.result,
      commissions: this.commissions
    }
    if (isStored) {
      this.storeState(state)
    }
    if (download) {
      this.$store.commit('loading')
      const blob = new Blob([JSON.stringify(state)], {type: 'application/json'});
      let url = window.URL || window.webkitURL;
      let link = url.createObjectURL(blob);
      let a = document.createElement('a');
      a.setAttribute("download", 'AffaireNouvelle_' + helper.generateId() + '.devis');
      a.setAttribute("href", link);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.$store.commit('stopLoading')
    }
  }

  storeState(state: any) {
    let states: any = sessionStorage.getItem('states')
    if (states) {
      states = JSON.parse(states)
    }
    if (helper.empty(states)) {
      states = []
    }
    const index = states.findIndex((s: any) => {
      return s.uuid === this.uuid
    })
    if (index !== -1) {
      states.splice(index, 1)
    }
    states.push(state)
    if (states.length > 9) {
      states.shift()
    }
    sessionStorage.setItem('states', JSON.stringify(states))
    this.getSessionStates()
  }


  deleteState(state: any) {
    let states: any = sessionStorage.getItem('states')
    if (states) {
      states = JSON.parse(states)
    }
    if (helper.empty(states)) {
      states = []
    }
    const index = states.findIndex((s: any) => {
      return s.uuid === state.uuid
    })
    if (index !== -1) {
      states.splice(index, 1)
    }
    sessionStorage.setItem('states', JSON.stringify(states))
    this.getSessionStates()
  }

  getSessionStates() {
    let states: any = sessionStorage.getItem('states')
    if (states) {
      states = JSON.parse(states)
    }
    if (helper.empty(states)) {
      states = []
    }
    this.states = states
  }


  async connectRealTimeAttestationCount() {
    // if (this.eventSource instanceof EventSource) {
    //   this.eventSource.close()
    // }
    const baseUrl = api.mercure;
    const hubUrl = new URL(baseUrl);
    hubUrl.searchParams.append('topic', '/comparisons');

    this.eventSource = new EventSource(hubUrl.toString());

    this.eventSource.onerror = (e: any) => {
      console.log("An error occurred while attempting to connect.");
      this.eventSource.close()
    };
    this.eventSource.onmessage = (event: any) => {
      // console.log(event)
      var data = JSON.parse(event.data);
      if (data.hasOwnProperty('updates')) {
        let updates = data.updates
        // console.log(updates);
        if (updates.hasOwnProperty('used') && updates.hasOwnProperty('insurer')) {
          for (let data of this.certificateRemaining) {
            if (data.uuid === updates.insurer) {
              // console.log(data)
              if (updates.used.yellow > 0) {
                data.eAttestationCountYellow--
              }
            }
          }
        }
      }


      // if (data.hasOwnProperty('voted') && data.hasOwnProperty('percents')) {
      //   addLike(data.voted, data.percents);
      // }
    }
    // this.eventSource.onerror = (event: any) => {
    //   console.log(event)
    // }
  }


  async save(result: any) {
    this.$store.commit('loading')
    const order = new Order()
    this.compare()
    if (!result) {
      new Popup('Erreur', 'Veuillez selectionner un produit')
      this.$store.commit('stopLoading')
      return
    }

    const total = this.deadlines.reduce((sum, deadline) => sum + parseFloat(deadline.premium), 0);
    if (this.deadlines.length > 0 && total != result.premium) {
      new Popup('Erreur', "La somme des montants échéances est différent du montant de l'avenant");
      this.$store.commit('stopLoading')
      return
    }
    const items: any = []
    this.items.forEach((i: Item) => {
      if (i.model.required || i.model.isRoot) {
        items.push(i.simplify())
      }

    })
    order.entities = items
    order.branch = this.$route.params.branchSlug
    order.insurer = this.$store.state.currentInsurer.slug
    order.premium = result.premium
    order.premiumDetails = this.premiumDetails as any
    order.details = JSON.parse(JSON.stringify(result.details))
    order.userData = this.userData
    order.deadlines = this.deadlines
    order.commissions = JSON.parse(JSON.stringify(this.commissions))
    const res = await api.post(api.core, 'contract/create/' + this.$route.params.branchSlug, {order})
    this.$store.commit('stopLoading')
    if (res && res.data && res.data.uuid) {
      this.$router.push({name: 'contract-show', params: {contractUuid: res.data.uuid}})
    }
  }

  appendItem(occ: Item) {
    this.objects.push(occ)
  }

  async loadDurations() {
    const res = await api.get(api.master, 'api/get/durations')
    if (res && res.data && res.data.durations) {
      this.durations = []
      res.data.durations.forEach((dur: Duration) => {
        this.durations.push(new Duration(dur))
      })
    }
  }

  getDuree() {
    if (this.avenant instanceof Item) {
      const prop = this.avenant.getPropertyByLink('duree')
      if (!prop) {
        console.log('Propriété duree introuvable')
        return
      }
      const choice = prop.model.choices.find((c: PropertyChoice) => {
        return c.code === prop.value
      })
      return choice ? choice.label : ''
    }
  }

  getStart() {
    if (this.avenant instanceof Item) {
      // console.log('start ' + this.avenant.getPropertyByLink('dateEffet').value)
      return this.avenant.getPropertyByLink('dateEffet')?.value
    } else {
      // console.log('this.avenant is not item, cannot determinate start date')
    }
  }

  get start() {
    return this.getStart()
  }

  get end() {
    return this.getEnd()
  }

  getEnd() {
    if (this.avenant instanceof Item) {
      var debut = this.avenant.getPropertyByLink('dateEffet')?.value
      var duree = this.avenant.getPropertyByCode('duree')?.value
      if (!helper.empty(debut)) {
        debut = new Date(debut)
        const duration: Duration = this.durations.find((duration: Duration) => {
          return duration.code === duree
        }) as any
        // console.log('duration = ' + duration?.label)
        // console.log(duration)
        var endDate = new Date(debut);
        if (duration.year > 0) {
          endDate.setFullYear(endDate.getFullYear() + duration.year)
        }
        if (duration.month > 0) {
          endDate.setMonth(endDate.getMonth() + duration.month)
        }
        if (duration.day > 0) {
          endDate.setDate(endDate.getDate() + duration.day)
        }
        endDate.setSeconds(endDate.getSeconds() - 1)
        // console.log('end at ' + endDate)
        return endDate
      }
    } else {
      // console.log('this.avenant is not item, cannot calculate end date')
    }

  }

  dureeEnJour() {
    if (this.avenant instanceof Item) {
      var debut = this.avenant.getPropertyByLink('dateEffet')?.value
      debut = new Date(debut)
      var end = this.getEnd()
      if (end) {
        var diff = end.getTime() - debut.getTime()
        var delta = Math.ceil(diff / (1000 * 3600 * 24))
        return delta
      }
      return null
    }
  }


  copyFrom(item: Item, shallow: Item) {

  }


  checkPremiumDetails() {
    const avenant: Item = this.items.find((i: Item) => {
      return i.model.code === 'avenant'
    }) as any
    this.avenant = avenant
    this.premiumDetailModels.forEach((pdm: PremiumDetailModel) => {
      const pd = avenant.premiumDetails.find((p: PremiumDetail) => {
        return p.model.id === pdm.id
      })
      if (!pd) {
        const occ = new PremiumDetail()
        occ.model = pdm
        avenant.premiumDetails.push(occ)
      }
    })
  }

  checkCommissions() {
    this.commissionModels.forEach((cm: CommissionModel) => {
      const c = this.commissions.find((c: Commission) => {
        return c.model.id === cm.id
      })
      if (!c) {
        const occ = new Commission()
        occ.model = cm
        occ.premium = 0
        this.commissions.push(occ)
      }
    })
  }

  loadPremiumDetails() {
    const avenant: Item = this.items.find((i: Item) => {
      return i.model.code === 'avenant'
    }) as any
    this.avenant = avenant
    this.premiumDetails.forEach((pdm: PremiumDetail) => {
      const pd = avenant.premiumDetails.find((p: PremiumDetail) => {
        return p.model.id === pdm.id
      })
      if (pd) {
        pd.premium = pdm.premium
      }
    })
  }

  async loadEngine() {
    let classes: any[] = window['classes' as any] as any
    store.state.engineClasses = null
    await api.loadEngine(this.branch!.slug)
    this.$store.state.recalculated = []
    this.uKey = helper.generateId()
    this.compare()
    this.engineLoaded = true
    return
  }

  updated(): void {
    // console.log('updated')
    // this.compare()
  }

  update(event: any) {
    if (event && event.type === 'unlockComparison') {
      this.lockComparison = false
    }
    this.saveState(true, false)
    this.compare()
  }


  certificateRemaining: any[] = []


  async loadCountCertificate() {
    const res = await api.get(api.core, 'insurer/certificate/list')
    this.certificateRemaining = res.data.remaining

  }


  results: any[] = []
  result: any = null

  calculating = false

  premiumDetails: any[] = []
  commissions: Commission[] = []

  // @Watch('items', {immediate: true, deep: true})
  compare() {
    // if (!this.engineLoaded) {
    //     return
    // }
    this.calculating = true
    let classes: any[] = window['classes' as any] as any
    console.log(classes)
    console.log('calcul avenant')
    let p = new Popup('', 'Calcul en cours', 'info', 'fad fa-cogs', true);
    if (classes) {
      const currentInsurer = this.$store.state.contractInsurer
      if (!currentInsurer) {
        p.hide()
        // p = new Popup('', 'Aucun assureur selectionné', 'secondary', 'fad fa-exclamation-circle');
        // console.log('no current Insurer')
        this.calculating = false
        return
      }
      // console.log(currentInsurer)
      const engine: any = classes.find((c: any, index: any) => {
        return c.code === currentInsurer.slug
      })
      // console.log(engine)
      if (!engine) {
        this.calculating = false
        if (classes.length > 0) {
          p = new Popup('Erreur', 'Le moteur de calcul ' + currentInsurer.label + ' est introuvable', 'secondary', 'fad fa-exclamation-circle');
        }
        return
      }
      const insurerEngine = new engine.prototype()
      insurerEngine.userData = this.userData
      const keys = Object.keys(insurerEngine)
      // console.log(this.items)
      this.items.forEach((i: Item) => {
        keys.forEach((k: string) => {
          if (i.model.code === k) {
            const data = classes.find((c: any) => {
              return c.code === k
            })
            if (!data) {
              const pop = new Popup('Erreur', 'La définition de l\'objet ' + k + ' est introuvable');
              return
            }
            const prototype = new data.prototype(i)
            prototype.engine = insurerEngine
            insurerEngine[k] = prototype
          }
        })
      })
      if (insurerEngine.AvenantAffaireNouvelle) {
        insurerEngine.details = []
        try {
          const premium = insurerEngine.AvenantAffaireNouvelle()

          if (!this.lockComparison) {
            this.premiumDetails = []
            this.branch?.premiumDetailModels.forEach((pdm: PremiumDetailModel) => {
              if (pdm.method) {
                const method = pdm.method?.code
                if (insurerEngine[method]) {
                  try {
                    const p = insurerEngine[method]()
                    const d = JSON.parse(JSON.stringify({model: pdm, premium: p, code: method}))
                    this.premiumDetails.push(d)
                  } catch (e) {
                    console.log(e)
                  }
                }
              }
            })
          }
          let expand = true
          insurerEngine.details.forEach((d: any) => {
            d.show = true
          })
          const result = {
            uuid: helper.generateId(),
            premium: premium,
            details: this.lockComparison && this.result ? this.result.details : insurerEngine.details,
            expand: expand,
            insurer: currentInsurer
          }
          this.result = JSON.parse(JSON.stringify(result))
        } catch (e) {
          console.log('ImpossibLe de calculer la prime TTC pour le moment ')
        }
      } else {
        console.log('Calcul Affaire nouvelle ' + currentInsurer.label + ' introuvable')
      }
    } else {
      new Popup('Erreur', 'Le moteur de calcul es en cours de chargement')
    }
    try {
      p.hide()
    } catch (e) {
    }
    // if (!this.$store.state.contractUpdateComparison) {
    //     this.$store.state.contractUpdateComparison = helper.generateId()
    // }
    // this.uKey = helper.generateId()
    // this.$forceUpdate()
    // this.$store.commit('updateContractItems', null)
    // this.$store.state.recalculated = []
    this.calculating = false
  }
}


</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s cubic-bezier(0.85, 0.05, 0.18, 1.35);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(50%);
  color: red;
}

.slide-left-leave {
  opacity: 1;
  color: red
}

.slide-left-leave-active {
  transition: opacity .5s;
  color: red
}

.slide-left-leave-to {
  opacity: 0;
  color: red
}

.slide-left-enter {
  opacity: 0;
  color: mediumseagreen
}

.slide-left-enter-active {
  transition: opacity .5s, color 10s;
  color: cornflowerblue;
}

.slide-left-enter-to {
  opacity: 1;

}
</style>
