<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div
          class="container-fluid d-flex flex-stack"
          id="kt_toolbar_container"
        >
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link
                    :to="{ name: 'prospection-index' }"
                    class="text-muted text-hover-primary"
                  >
                    <i class="fad fa-headset"></i>
                    {{ trans("Ajouter un rapport de prise de contact") }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <b-row @change="Change">
        <b-col cols="8">
          <b-card>
            <h1 class="mb-8">Informations de la prospection</h1>
            <div>
              <b-form>
                <div class="row">
                  <div class="mb-3 col-8">
                    <label for="title-handshake" class="form-label">{{
                      trans("Raison de la prise de contact")
                    }}</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="handShake.title"
                      id="title-handshake"
                    />
                  </div>

                  <div class="mb-3 col-4">
                    <label for="title-handshake" class="form-label">{{
                      trans("Étape de prospection")
                    }}</label>
                    <select
                      @change="$forceUpdate()"
                      class="form-select form-select-sm"
                      v-model="handShake.step"
                    >
                      <option selected value="">Selectionnez une étape</option>

                      <option value="LEAD">Leading</option>

                      <option value="CONTACTÉ">Contacté</option>

                      <option value="RENDEZVOUS">Rendez-vous</option>
                      <option value="COTATION">Cotation</option>

                      <option value="CONTRACT_SIGNÉ">Contract signé</option>
                    </select>
                  </div>
                </div>

                <b-row>
                  <b-col>
                    <div class="fw-bolder my-3">Type de prospection:</div>

                    <div class="d-flex rounded-1 overflow-hidden">
                      <div
                        v-for="(item, index) in typeContact"
                        :key="index"
                        class=""
                      >
                        <input
                          :value="item.value"
                          class="hidden"
                          v-model="handShake.type"
                          type="radio"
                          name="typeContact"
                          :id="'typeContact' + index"
                        />
                        <label
                          :class="
                            index == typeContact.length - 1
                              ? 'rounded-end'
                              : 'border-end-0 rounded-start'
                          "
                          class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                          :for="'typeContact' + index"
                        >
                          {{ item.text }}
                        </label>
                      </div>
                    </div>
                  </b-col>

                  <!-- <b-col>
                    <div class="fw-bolder my-3">Cible de vente</div>
                    <div class="d-flex container-check" label="">
                      <div
                        v-for="(item, index) in typeClient"
                        :key="index"
                        class="choiceContent"
                      >
                        <input
                          :value="item.value"
                          class="hidden"
                          v-model="handShake.category"
                          type="radio"
                          name="typeClient"
                          :id="'typeClient' + index"
                        />
                        <label
                          :class="
                            index == typeClient.length - 1
                              ? ''
                              : 'border-end-0 '
                          "
                          class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                          :for="'typeClient' + index"
                        >
                          {{ item.text }}
                        </label>
                      </div>
                    </div>
                  </b-col> -->
                </b-row>

                <div class="d-flex flex-column my-8">
                  <label class="fw-bolder my-3">Notes</label>

                  <!-- <ckeditor class="border" :editor="editor" v-model="handShake.comment" :config="editorConfig" /> -->

                  <div class="d-flex flex-column align-items-end">
                    <div
                      v-for="(item, index) in handShake.notes"
                      :key="index"
                      class="w-100 mb-5"
                    >
                      <vue-editor
                        @change="console.log('object')"
                        v-if="index == handShake.notes.length - 1"
                        v-model="item.comment"
                      />

                      <div v-else>
                        <iframe
                          :srcdoc="item.comment"
                          width="100%"
                          height="100%"
                        ></iframe>
                      </div>
                    </div>

                    <div class="btn btn-sm btn-primary" @click="addNote">
                      Enregistrer la note
                    </div>
                  </div>
                </div>
              </b-form>
            </div>
          </b-card>

          <b-card class="mt-5">
            <div class="d-flex flex-column">
              <!-- <b-row>
              <h2>
                {{ trans("Cotations") }}
              </h2>
              <b-col cols="7">
                <b-dropdown no-caret no-flip right size="sm" tag="div" toggle-class="topbar-item text-decoration-none"
                  variant="link">
                  <template v-slot:button-content>
                    <div class="btn btn-clean btn-dropdown btn-primary">
                      <i class="fad fa-file-signature fs-2"></i>
                      {{ trans("Créer une cotation") }}
                    </div>
                  </template>
<b-dropdown-text class="min-w-md-350px p-0" tag="div">
  <Loading v-if="isLoading"></Loading>
  <b-tabs active-nav-item-class="bg-primary text-white" active-tab-class="" fill nav-wrapper-class="" v-else>
    <b-tab :title="cat.label" :title-link-class="'py-4'" :key="cat.id" v-for="cat in branchCategories">
      <div class="row g-0 overflow-auto mh-325px">
        <div class="col-6" v-for="branch in cat.branches">
          <div :style="{ '--bg-hover': branch.color }"
            class="cursor-pointer d-flex flex-column flex-center h-100 p-6 border-end border-bottom branch-button">
            <span class="svg-icon svg-icon-3x svg-icon-success mb-2">
              <i :class="branch.icon + ' fs-3x'" v-if="branch.icon"></i>
              <i class="fad fa-question-circle" v-else></i>
            </span>
            <span class="fs-5 fw-bold text-gray-400 mb-0">
              {{ branch.label }}
            </span>
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</b-dropdown-text>
</b-dropdown>
</b-col>

<b-col>
  <b-button variant="secondary" v-b-modal.otherHandShake>
    <i class="fad fa-calendar fs-2"></i>
    {{
    trans("Programmer une autre prise de contact")
    }}</b-button>

  <b-modal centered title="Programmer une prise de contact" id="otherHandShake" aria-hidden="true">
    <div class="mb-3">
      <label for="title-handshake" class="form-label">{{
        trans("Date de la prochaine prise de contact")
        }}</label>
      <input type="datetime-local" class="form-control" id="title-handshake" />
    </div>

    <label for="title-handshake" class="form-label">{{
      trans("Type")
      }}</label>

    <div class="d-flex rounded-1 overflow-hidden">
      <div v-for="(item, index) in typeContact" :key="index" class="">
        <input :value="item.value" class="hidden" type="radio" name="typeNextContact" :id="'typeNextContact' + index" />
        <label :class="index == typeContact.length - 1
                  ? 'rounded-end'
                  : 'border-end-0 rounded-start'
                  " class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
          :for="'typeNextContact' + index">
          {{ item.text }}
        </label>
      </div>
    </div>
  </b-modal>
</b-col>
</b-row> -->

              <button
                class="align-self-end mt-5 btn btn-primary"
                type="submit"
                @click="save"
                :disabled="loadingEdit"
              >
                Enregistrer
              </button>
            </div>
          </b-card>
        </b-col>

        <b-col cols="4" v-if="!isLoadingCustomer">
          <b-card>
            <h2>Informations du client</h2>

            <p>
              Integrer les informations pertinentes pour la prise de contact
            </p>

            <div v-if="handShake?.uniqProspect">
              <div class="my-3">
                <div class="">Type de client</div>
                <div class="d-flex container-check" label="">
                  <div
                    v-for="(item, index) in statusClient"
                    :key="index"
                    class="choiceContent"
                  >
                    <input
                      :value="item.value"
                      class="hidden"
                      type="radio"
                      v-model="handShake.uniqProspect.status"
                      name="statusClient"
                      :id="'statusClient' + index"
                    />
                    <label
                      style="font-size: 2.5rem"
                      :class="
                        index == statusClient.length - 1 ? '' : 'border-end-0 '
                      "
                      class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                      :for="'statusClient' + index"
                    >
                      {{ item.text }}
                    </label>
                  </div>
                </div>
              </div>

              <!-- <div class="my-3">
                <div class=" ">Categorie de client</div>

                <div class="d-flex container-check mb-5" label="">
                  <div
                    v-for="(item, index) in typeClient2"
                    :key="index"
                    class="choiceContent"
                  >
                    <input
                      :value="item.value"
                      class="hidden"
                      v-model="handShake.uniqProspect.type"
                      type="radio"
                      name="categoryClient"
                      :id="'categoryClient' + index"
                    />
                    <label
                      :class="
                        index == typeClient2.length - 1 ? '' : 'border-end-0 '
                      "
                      class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                      :for="'categoryClient' + index"
                    >
                      {{ item.text }}
                    </label>
                  </div>
                </div>
              </div> -->
              <b-row>
                <b-col>
                  <b-form-group
                    id="input-group-1"
                    label="Nom et prenoms"
                    label-for="input-1"
                  >
                    <b-form-input
                      id="input-1"
                      v-model="handShake.uniqProspect.name"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                id="input-group-1"
                label="Numero de telephone"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  v-model="handShake.uniqProspect.mobile"
                  type="tel"
                  required
                ></b-form-input>
              </b-form-group>
            </div>

            <div></div>
          </b-card>

          <b-card class="mt-5" v-if="handShake.uniqProspect">
            <h2>Informations supplementaires</h2>

            <p>
              Integrer les informations supplementaires que le client a
              mentionne lors de la prise de contact
            </p>

            <div
              v-for="(item, index) in handShake.uniqProspect.additionnalInfo"
              :key="index"
            >
              <select
                @change="$forceUpdate()"
                class="form-select form-select-sm"
                v-model="item.type"
              >
                <option selected value="">
                  Selectionnez un type de fichier
                </option>

                <option
                  v-for="(option, index) in optionsInfo"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.text }}
                </option>
              </select>
              <b-input-group class="my-2" v-if="item.type">
                <b-form-input
                  v-model="item.label"
                  placeholder="Libelé"
                ></b-form-input>

                <b-form-input
                  v-if="item.type != 'FILE'"
                  v-model="item.value"
                  placeholder=""
                ></b-form-input>
                <div v-else class="mx-3">
                  <FileUpload
                    :currentFileName="item.value"
                    v-model="item.value"
                  />
                </div>

                <b-input-group-append>
                  <div
                    @click="loadingEdit ?? removeInfo(index)"
                    style="
                      border-top-left-radius: 0 !important;
                      border-bottom-left-radius: 0 !important ;
                    "
                    class="btn btn-sm btn-icon btn-danger rounded-end"
                  >
                    <i class="fad fa-times"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div
              @click="addInfo"
              class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary"
            >
              Ajouter une information
            </div>
          </b-card>

          <b-card class="mt-5" v-if="handShake.uniqProspect && handShake.uniqProspect.company || $route.params.entrepriseId">
            <h2>Informations de l'entreprise</h2>

            <p>
              Integrer les informations pertinentes de l'entreprise pour la
              prise de contact
            </p>

            <template
              v-if="!isLoadingCompany && handShake.uniqProspect.company"
            >
              <b-form-group
                id="input-group-1"
                label="Nom de l'entreprise"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  disabled
                  v-model="handShake.uniqProspect.company.name"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Secteur"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  disabled
                  v-model="handShake.uniqProspect.company.sector"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Email"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  disabled
                  v-model="handShake.uniqProspect.company.email"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Numero de telephone"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  disabled
                  v-model="handShake.uniqProspect.company.number"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-1"
                label="Localisation"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  disabled
                  v-model="handShake.uniqProspect.company.localisation"
                  type="text"
                  required
                ></b-form-input>
              </b-form-group>
            </template>

            <!-- <template v-else>
            <div @click="addCompany"
              class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary">
              Lier a une une entreprise
            </div>
          </template> -->
          </b-card>

          <b-card
            class="mt-5"
            v-if="handShake.uniqProspect && handShake.uniqProspect.company"
          >
            <h2>Informations supplementaires de l'entrepise</h2>

            <p>
              Integrer les informations supplementaires que le client a
              mentionne lors de la prise de contact
            </p>

            <div
              v-for="(item, index) in handShake.uniqProspect.company
                .additionalInfos"
              :key="index"
            >
              <select
                @change="$forceUpdate()"
                class="form-select form-select-sm"
                v-model="item.type"
              >
                <option selected value="">
                  Selectionnez un type de fichier
                </option>

                <option
                  v-for="(option, index) in optionsInfo"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.text }}
                </option>
              </select>
              <b-input-group class="my-2" v-if="item.type">
                <b-form-input
                  v-model="item.label"
                  placeholder="Libelé"
                ></b-form-input>

                <b-form-input
                  v-if="item.type != 'FILE'"
                  v-model="item.value"
                  placeholder=""
                ></b-form-input>
                <div v-else class="mx-3">
                  <FileUpload
                    :currentFileName="item.value"
                    v-model="item.value"
                  />
                </div>

                <b-input-group-append>
                  <div
                    @click="removeInfoCompany(index)"
                    style="
                      border-top-left-radius: 0 !important;
                      border-bottom-left-radius: 0 !important ;
                    "
                    class="btn btn-sm btn-icon btn-danger rounded-end"
                  >
                    <i class="fad fa-times"></i>
                  </div>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div
              @click="addInfoCompany"
              class="border-dashed border border-primary btn w-100 mt-3 text-primary fw-bolder text-hover-white bg-hover-primary"
            >
              Ajouter une information
            </div>
          </b-card>
        </b-col>
        <b-col v-else>
          <Loading />
        </b-col>
      </b-row>
    </template>
  </Base>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Loading.vue";

import Base from "@/layouts/Base.vue";
import BranchCategory from "@/entity/BranchCategory";
import { api } from "@/services/Api";
import Survey from "@/entity/Survey";
import ResponseGroup from "@/entity/ResponseGroup";
import Branch from "@/entity/Branch";
import { auth } from "@/services/Auth";
import FileUpload from "@/components/FileUpload.vue";
import Popup from "@/entity/Popup";
import { VueEditor } from "vue2-editor";
import User from "@/entity/User";
import UniqProspect from "../../entity/UniqProspect";
import Handshake from "../../entity/Handshake";
import AdditionalInfo from "../../entity/AdditionalInfo";
import Note from "../../entity/Note";
import Company from "@/entity/Company";

@Component({
  components: { Base, Loading, FileUpload, VueEditor },
})
export default class HandShakeForm extends Vue {
  // editor = ClassicEditor;
  // editorConfig = {
  //   plugins: [Essentials, Bold, Italic, Link, Paragraph],
  //   toolbar: {
  //     items: ["bold", "italic", "link", "undo", "redo"],
  //   },
  // };
  // editorData = '<p>Content of the editor.</p>'

  handshakeId = null;

  nextEvent = {
    dateHandShake: null,
    type: null,
  };

  async ProgramHandshake() {}

  infoSup = [] as any;

  addInfo() {
    const additionnaInfo = new AdditionalInfo();
    this.handShake.uniqProspect.additionnalInfo.push(additionnaInfo);

    this.$forceUpdate();
  }

  removeInfo(item: number) {
    this.handShake.uniqProspect.additionnalInfo.splice(item, 1);
    this.$forceUpdate();
  }

  addInfoCompany() {
    const additionnaInfo = new AdditionalInfo();
    this.handShake.uniqProspect.company.additionalInfos.push(additionnaInfo);

    this.$forceUpdate();
  }

  removeInfoCompany(item: number) {
    this.handShake.uniqProspect.company.additionalInfos.splice(item, 1);
    this.$forceUpdate();
  }

  addCompany() {
    const company = new Company();
    this.handShake.uniqProspect.company = company;

    this.$forceUpdate();
  }

  removeCompany() {
    this.handShake.uniqProspect.company = null as any;

    this.$forceUpdate();
  }

  Change(event: { preventDefault: () => void }) {
    event.preventDefault();
    if (this.loadingEdit != true) {
      this.onSubmit();
    }
  }

  // forceUpdate(){
  //   console.log('update');
  //   this.$forceUpdate();
  // }

  optionsInfo = [
    {
      value: "INFO",
      text: "Informations",
    },
    {
      value: "FILE",
      text: "Fichiers",
    },
  ];

  handShake = new Handshake();

  branchCategories: BranchCategory[] = [];

  isLoading = false;

  typeContact = [
    {
      text: "Par email",
      value: "email",
    },
    {
      text: "Telephone",
      value: "telephone",
    },
    {
      text: "Terrain",
      value: "terrain",
    },
  ];

  typeClient = [
    {
      text: "B2C",
      value: "B2C",
    },
    {
      text: "B2B",
      value: "B2B",
    },
    {
      text: "B2B2C",
      value: "B2B2C",
    },
  ];

  typeClient2 = [
    {
      text: "B2C",
      value: "B2C",
    },
    {
      text: "B2B",
      value: "B2B",
    },
    {
      text: "B2B2C",
      value: "B2B2C",
    },
  ];

  user: User = new User();

  statusClient = [
    {
      text: "😒",
      value: "UNVALID",
    },
    {
      text: "🥶",
      value: "COLD",
    },

    {
      text: "😑",
      value: "WARM",
    },
    {
      text: "😍",
      value: "HOT",
    },
  ];

  isLoadingCustomer = false;

  isLoadingCompany = false;

  async loadCustomer() {
    this.isLoadingCustomer = true;
    const res = await api.get(
      api.form,
      "prospect/info/" + this.$route.params.clientId
    );
    if (res && res.data) {
      this.handShake.uniqProspect = new UniqProspect(res.data.prospect);

      // if (this.handShake.uniqProspect.company == null) {
      //   this.handShake.uniqProspect.company = new SellCompany()
      // }
      this.isLoadingCustomer = false;
    } else {
      this.isLoadingCustomer = false;
    }

    // this.$forceUpdate();
  }

  async loadCompany() {
    this.isLoadingCompany = true;
    const res = await api.get(
      api.form,
      "company/info/" + this.$route.params.entrepriseId
    );
    if (res && res.data) {
      if (this.handShake.uniqProspect) {
        console.log(this.handShake.uniqProspect);
        this.handShake.uniqProspect.company = new Company(res.data.company);
        this.$forceUpdate;
      }
      // if (this.handShake.uniqProspect.company == null) {
      //   this.handShake.uniqProspect.company = new SellCompany()
      // }

      this.isLoadingCompany = false;
    } else {
      this.isLoadingCompany = false;
    }

    // this.$forceUpdate();
  }

  isLoadingHandShake = false;

  async loadHandShake() {
    this.isLoadingCustomer = true;
    const res = await api.get(
      api.form,
      "handshake/info/" + this.$route.params.uuid
    );
    if (res && res.data) {
      this.handShake = new Handshake(res.data.handshake);

      // if (this.handShake.uniqProspect.company == null) {
      //   this.handShake.uniqProspect.company = new SellCompany()
      // }
      this.isLoadingHandShake = false;

      this.isLoadingCustomer = false;
    } else {
      // this.isLoadingHandShake = false
    }
    this.$forceUpdate();
  }

  surveys: Survey[] = [];
  notes = [
    {
      placeholder: "Veuillez entrez un recapitilatif de votre prospection",
      value: "",
    },
  ];

  loading = false;
  change() {
    console.log(this.notes);
  }
  addNote() {
    if (
      this.handShake.notes[this.handShake.notes.length - 1] &&
      this.handShake.notes[this.handShake.notes.length - 1].comment != ""
    ) {
      let note: Note = new Note();
      this.handShake.notes.push(note);
    }

    if (this.handShake.notes.length == 0) {
      let note: Note = new Note();
      this.handShake.notes.push(note);
    }
  }

  removeNote(item: number) {
    this.handShake.notes.splice(item, 1);
    this.$forceUpdate();
  }

  // addContact() {
  //   const contact = new SellContact();
  //   this.handShake.client.contacts.push(contact);

  //   this.$forceUpdate();
  // }

  // removeContact(item: number) {
  //   console.log(item);
  //   this.handShake.client.contacts.splice(item, 1);
  //   this.$forceUpdate();
  // }

  // addEmail() {
  //   const email = new SellEmail();
  //   this.handShake.client.emails.push(email);
  //   this.$forceUpdate();
  // }

  // removeEmail(item: number) {
  //   console.log(item);
  //   this.handShake.client.emails.splice(item, 1);

  //   this.$forceUpdate();
  // }

  loadingEdit = false;
  async save() {
    if (
      this.handShake.title == "" &&
      this.handShake.uniqProspect.name == "" &&
      this.handShake.uniqProspect.mobile == ""
    ) {
      return;
    }
    const pop = new Popup(
      "Chargement",
      "Enregistrement de la prise de contact",
      "primary",
      "fad fa-sync",
      true
    );

    this.loadingEdit = true;

    try {
      const res = await api.post(api.form, "handshake/edit", {
        ...this.handShake,
        id: this.handshakeId,
        uniqProspect: this.clientId
          ? {
              ...this.handShake.uniqProspect,
              id: this.clientId,
              company: this.companyId
                ? {
                    ...this.handShake.uniqProspect.company,
                    id: this.companyId,
                  }
                : null,
            }
          : {
              ...this.handShake.uniqProspect,
            },
      });

      if (res.status == 200) {
        pop.hide();
        this.$router.go(-1);
      }
    } catch (error) {
      pop.hide();

      this.loadingEdit = false;
    }

    // console.log(this.handShake);
  }

  clientId = null;

  companyId = null;

  async onSubmit() {
    this.loadingEdit = true;

    console.log(this.$route.params);
    console.log(
      this.$route.params.clientId,
      " ",
      this.$route.params.clientId,
      this.handShake.id !== null
    );

    if (
      (this.$route.params.clientId && this.$route.params.clientId !== null) ||
      this.handShake.id !== null
    ) {
      const pop = new Popup(
        "Chargement",
        "Modification de la prise de contact",
        "primary",
        "fad fa-sync",
        true
      );

      try {
        const res = await api.post(api.form, "handshake/edit", {
          ...this.handShake,
          id: this.handshakeId ?? this.handShake.id,
          uniqProspect: this.clientId
            ? {
                ...this.handShake.uniqProspect,
                id: this.clientId,
                company: this.companyId
                  ? {
                      ...this.handShake.uniqProspect.company,
                      id: this.companyId,
                    }
                  : this.handShake.uniqProspect.company.name != ""
                  ? {
                      ...this.handShake.uniqProspect.company,
                    }
                  : null,
              }
            : {
                ...this.handShake.uniqProspect,
              },
        });

        if (res.status == 200) {
          this.handshakeId = res.data.handshake.id;
          this.clientId = res.data.handshake.uniqProspect.id;
          this.companyId = res.data.handshake.uniqProspect.company.id;

          this.handShake = new Handshake(res.data.handshake);

          pop.hide();

          this.loadingEdit = false;
        }
      } catch (error) {
        pop.hide();

        this.loadingEdit = false;
      }
    }
  }

  branchSurveys: Survey[] = [];

  async compare(branch: Branch) {
    const surveys: Survey[] = this.getSurveys(branch);
    if (surveys.length > 0) {
      this.branchSurveys = surveys;
      if (auth.hasRole("ROLE_MANAGER")) {
        // console.log(this.$refs);
        if (surveys.length === 1) {
          this.createResponseGroup(surveys[0]);
        } else {
          (this.$refs as any).surveyModal!.show();
        }
      } else {
        this.createResponseGroup(surveys[0]);
      }
    } else {
      console.log("no survey match");
    }
  }

  async createResponseGroup(survey: Survey) {
    this.$store.commit("loading");

    const partner = sessionStorage.getItem("partner");
    let res = null;

    if (partner) {
      res = await api.get(
        api.form,
        "api/response/group/new/" + survey.slug + "/null/" + partner
      );
    } else {
      res = await api.get(api.form, "api/response/group/new/" + survey.slug);
    }

    if (res && res.data && res.data.responseGroup) {
      const rg = new ResponseGroup(res.data.responseGroup);

      // console.log(rg)
      const cotation = {
        uuid: rg.uuid,
        state: 0,
        createdAt: rg.createdAt,
        lastUpdate: rg.updatedAt,
        branch: rg.branchSlug,
        survey: rg.surveySlug,
      };
      const cotations = localStorage.getItem("cotations")
        ? JSON.parse(localStorage.getItem("cotations")!)
        : [];
      cotations.push(cotation);
      localStorage.setItem("cotations", JSON.stringify(cotations));
      // console.log(cotations)
      // return
      let route = this.$router.resolve({
        name: "response-group-manager",
        params: {
          surveySlug: survey.slug,
          rgUuid: res.data.responseGroup.uuid,
        },
      });

      //     window.open(route.href)
      // this.$router.push({
      //     name: 'response-group-manager',
      //     params: {
      //         surveySlug: survey.slug,
      //         rgUuid: res.data.responseGroup.uuid
      //     }

      // })

      window.open(route.href);
    }
    this.$store.commit("stopLoading");
  }

  async loadBranches() {
    this.isLoading = true;
    this.branchCategories = await this.$store.getters.branchCategories();
    this.isLoading = false;
  }
  getSurveys(branch: Branch) {
    const surveys: Survey[] = [];
    this.surveys.forEach((s: Survey) => {
      if (s.branch === branch.slug) {
        surveys.push(s);
      } else {
      }
    });
    return surveys;
  }

  async loadSurveys() {
    const res = await api.get(api.form, "api/get/surveys/comparateur");
    if (res && res.data && res.data.surveys) {
      this.surveys = [];
      res.data.surveys.forEach((s: Survey) => {
        const occ = new Survey(s);
        this.surveys.push(occ);
      });
    }
  }

  async mounted() {
    this.loadBranches();
    this.loadSurveys();

    if (this.$route.params.clientId && this.$route.params.clientId != "null") {
      this.loadCustomer();
    } else {
      this.handShake.uniqProspect = new UniqProspect();
      // this.handShake.uniqProspect.company = new SellCompany();
    }

    if (this.$route.params.uuid && this.$route.params.uuid !== "null") {
      console.log(this.$route.params);
      this.loadHandShake();
    }

    if (
      this.$route.params.entrepriseId &&
      this.$route.params.entrepriseId != "null"
    ) {
      if (this.handShake.uniqProspect) {
        this.loadCompany();
      }
    }

    // this.addContact();
    // this.addEmail();
    this.addNote();
  }
}
</script>

<style>
.custom-label {
  transition: all 0.3s;
  width: 100%;
  text-align: center;
}

input[type="radio"]:checked + label {
  transition: all 0.3s;
}

input[type="radio"]:checked + label {
  background-color: var(--primary) !important;
  color: white !important;
}

.container-check {
  width: 100%;
}

.container-check .choiceContent:nth-child(1) > label {
  border-bottom-left-radius: 0.475rem !important;
  border-top-left-radius: 0.475rem !important;
}

.container-check .choiceContent:last-child > label {
  border-bottom-right-radius: 0.475rem !important;
  border-top-right-radius: 0.475rem !important;
}

.container-check .choiceContent {
  flex: 1;
}
</style>
<style>
.ck-editor__editable {
  min-height: 200px;
}
</style>
