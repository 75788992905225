<template>
  <div :class="{ 'animating': animating }" class="aside aside-light aside-hoverable" id="kt_aside">
    <div class="aside-logo flex-column-auto" id="kt_aside_logo">
      <router-link :to="{ name: 'home' }">
        <img alt="Logo" class="h-15px logo" src="../assets/logo.png"/>
      </router-link>

      <div @click="toggle()" class="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle"
           id="kt_aside_toggle">
        <span class="svg-icon svg-icon-1">
          <i class="fad fa-chevron-double-right" v-if="isMinimized()"></i>
          <i class="fad fa-chevron-double-left" v-else></i>
        </span>
      </div>
    </div>
    <div class="aside-menu">
      <div class="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper">
        <div
            class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
            data-kt-menu="true" id="#kt_aside_menu">


          <template v-for="(menu , index) in store.state.menus">
            <div :key="index" v-if="isMenuSelected(menu)">
              <!-- <div class="menu-item">
                <div class="menu-content pt-8 pb-2">
                  <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{ trans(menu.label) }}</span>
                </div>
              </div> -->
              <div class="menu-item menu-title-gray-700" v-for="(sub , index) in menu.subMenus" :key="index">

                <template v-if="sub.title && isMenuAllowed(sub)" >
                  <div>
                    <div class="menu-content  pb-2">
                      <span class="menu-section text-muted text-uppercase fs-8 ls-1">{{ trans(sub.title) }}</span>
                    </div>
                  </div>
                </template>

                <template v-if="sub.name">
                  <router-link :class="{ 'active': isActive(menu,sub) }"  :to="{name : sub.name}" class="menu-link" v-if="isMenuAllowed(sub)">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <i :class="sub.icon+' '" v-if="sub.icon"></i>
                      <i class="fad fa-cogs" v-else></i>
                    </span>
                  </span>
                    <span class="menu-title">
                    {{ sub.label ? trans(sub.label) : sub.name }}
                  </span>
                  </router-link>
                </template>
                <template v-else-if="sub.href">
                  <a :href="sub.href" target="_blank" :class="{ 'active': isActive(menu,sub) }" class="menu-link"
                     v-if="hasRole('ROLE_USER')">
                  <span class="menu-icon">
                    <span class="svg-icon svg-icon-2">
                      <i :class="sub.icon+' '"></i>
                    </span>
                  </span>
                    <span class="menu-title">
                    {{ trans(sub.label) }}
                  </span>
                  </a>
                </template>

               

              </div>
            </div>
          </template>

        </div>
      </div>
    </div>
    <div class="aside-footer flex-column-auto" id="kt_aside_footer"></div>
  </div>
</template>
<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import {auth} from "@/services/Auth";
import ComparatorMenu from "@/layouts/Menus/ComparatorMenu.vue";
import menus from "@/router/Menu";
import {helper} from "@/services/Helper";
import store from "@/store";

@Component({
  computed: {
    store() {
      return store
    }
  },
  methods: {
    menus() {
      let m = localStorage.getItem('menus')
      if (m) {
        m = JSON.parse(m)
        if (m) {
          return m
        }
      }
      return menus
    }
  },
  components: {ComparatorMenu}
})
export default class LeftAside extends Vue {
  public route = 'dashboard';
  public selected = '';
  public minimized = 'on';
  public animating = false;

  // public isAdmin = true;

  isAdmin(): boolean {
    return auth.isAdmin();
  }



  isMenuSelected(menu: any) {
    if (menu && menu.path) {
      const path = menu.path.replace(/^\/(.*)/, "$1");
      const currentPath = this.$route.path.replace(/^\/(.*)/, "$1")
      let root = path.split('/')[0]
      let currentRoot = currentPath.split('/')[0]
      return currentRoot === root
    }
    return false
  }
  isMenuAllowed(menu: any) {
    // console.log('check menu '+menu.name)
    return auth.checkRoleTabs(menu.roles)
  }
  toggle(): void {
    const state = document.body.getAttribute('data-kt-aside-minimize');
    if (state === 'on') {
      document.body.setAttribute('data-kt-aside-minimize', 'off');
    } else {
      this.animating = true;
      document.body.setAttribute('data-kt-aside-minimize', 'on');
      const t = setTimeout(() => {
        this.animating = false;
        clearTimeout(t);
      }, 500);
    }
    const newState = document.body.getAttribute('data-kt-aside-minimize');
    if (newState) {
      localStorage.setItem('navState', newState);
    }
    this.$forceUpdate();

  }

  isMinimized(): boolean {
    return this.minimized === 'on';
  }

  isActive(menu: any, sub: any): boolean {
    if (!helper.empty(sub.route)) {
      if (this.$route.name === sub.route.name) {
        return true
      }
      const menuRoute: any = this.$router.getRoutes().find((r: any) => {
        return r.name === sub.route.name
      })
      if (this.$route.fullPath?.startsWith(menuRoute.path)) {
        return true
      }
    }

    if (!helper.empty(sub.pathMatch)) {
      return this.$router.currentRoute.path.includes(sub.pathMatch)
    }

    return false
  }

  isOpen(name: string): boolean {
    return this.selected.trim().length > 0 && this.selected.includes(name);
  }


  isExactRoute(name: string): boolean {
    return name === this.route;
  }

  isRouted(array: Array<any>) {
    return array.includes(this.route)
  }

  select(name: string): void {
    if (this.selected && this.selected.includes(name)) {
      this.selected = '';
    } else {
      this.selected = name;
    }
  }

  pathIncludes(name: string) {
    return this.$router.currentRoute.path.includes(name)
  }

  mounted(): void {


    if (this.$router.currentRoute.name) {
      this.route = this.$router.currentRoute.name;
      this.selected = this.$router.currentRoute.name;
    }
    // console.log(this.route)
    // console.log(this.$router.currentRoute.path)


    if (localStorage.getItem('navState')) {
      const navState = localStorage.getItem('navState');
      if (navState !== null) {
        this.minimized = navState;
        if (this.minimized === 'on') {
          document.body.setAttribute('data-kt-aside-minimize', 'on');
        } else {
          document.body.setAttribute('data-kt-aside-minimize', 'off');
        }
      }
    }

    // this.$store.getters.menus()
  }

  beforeUnmount() {

  }


}
</script>
<style scoped>
.open .menu-arrow {
  transform: rotateZ(90deg);
}

.open + .menu-sub {
  display: flex !important;
}

.router-link-exact-active {
  background: var(--bs-light-primary);
}

.router-link-exact-active .menu-title {
  color: var(--bs-primary) !important;
}
</style>
