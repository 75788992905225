<template>
  <div class="d-flex flex-wrap ">
    <div v-for="level in levels" :key="'level_'+level.code" class="px-10"
         style="border-right: 1px gainsboro solid;">
      <div class="card my-5 min-w-500px" v-for="item in level.items" :key="item.uuid">
        <template v-if="item.uuid">
          <transition-group appear class="flex-grow-1 question mw-100" name="slide-up" tag="div">
            <div class="card-body" :key="'body_'+item.uuid">
              <h2 v-if="item.parent" class="d-flex align-items-center cursor-pointer text-hover-primary"
                  @click="loadItem(item.parentUuid, (item.level-1))">
                <i class="fad fa-angle-left fs-3x"></i>
              </h2>
              <h2>{{ item.model.name }} ▪️ {{ item.alias }}</h2>
              <h4 class="text-gray-400 fs-5">
                Créer le {{ readable(item.createdAt) }}
              </h4>
              <h4 class="text-gray-400 fs-5">
                Par {{ item.createBy }}
              </h4>
              <table class="table table-striped g-3">
                <thead>
                <tr>
                  <th>Propriété</th>
                  <th>Valeur</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="prop in item.properties">
                  <tr v-if="prop.isVisibleIn(items)" :key="prop.uuid">
                    <td class="fw-bolder" valign="middle">{{ prop.name }}</td>
                    <td class="text-end fw-bolder fs-2">
                      <template v-if="prop.model.type.code !== 'OBJECT'">
                        <div class="d-flex align-items-center justify-content-end">
                          <template v-if="prop.model.type.code === 'TEXT'">
                            <div class="d-flex align-items-center justify-content-end">
                              <div>
                                <input type="text" class=" form-control form-control-lg" v-model="prop.newValue">
                              </div>
                            </div>
                          </template>
                          <div v-else-if="prop.model.type.code.match(/RADIO/)">
                            <select class="form-control form-select text-end" v-model.trim="prop.newValue">
                              <b-form-select-option :value="null">{{ trans('Selectionnez une option') }}
                              </b-form-select-option>
                              <b-form-select-option :key="choice.id" :value="choice.code"
                                                    v-for="choice in prop.model.choices">
                                {{ trans(choice.label) }}
                              </b-form-select-option>
                            </select>
                          </div>
                          <div v-else-if="prop.model.type.code.match(/NUMBER|AMOUNT/)">
                            <input class="form-control text-end" type="number" v-model="prop.newValue">
                          </div>
                          <div v-else-if="prop.model.type.code.match(/DATE|DATETIME/)">
                            <input class="form-control" type="date" v-model="prop.newValue">
                          </div>
                          <div v-else-if="prop.model.type.code.match(/PACK_CATEGORY/)">
                            <div class="input-container flex-grow-1 rounded  d-flex">
                              <select class="form-control form-select" v-model.trim="prop.newValue">
                                <b-form-select-option :value="null">{{ trans('Séléctionnez une catégorie') }}
                                </b-form-select-option>
                                <b-form-select-option :key="category.uuid" :value="category.slug"
                                                      v-for="(category, key) in packCategories">
                                  {{ trans(category.label) }}
                                </b-form-select-option>
                              </select>
                              <span :title="trans('Chargement')" class="input-status" v-b-tooltip.hover
                                    v-if="isLoadingPackCategories">
                              <i class="fad fa-spinner-third spin text-secondary"
                                 style="text-shadow: 0 0 1px black"></i>
                            </span>
                            </div>
                          </div>

                          <div v-else-if="prop.model.type.code.match(/PACK/)">
                            <select class="form-control form-select" v-model="prop.newValue">
                              <b-form-select-option :value="null">{{ trans('Selectionnez un pack') }}
                              </b-form-select-option>
                              <template v-for="(pack, key) in packs">
                                <b-form-select-option :key="pack.id" :value="JSON.stringify(pack)">
                                  {{ pack.insurer.label }} ▪ {{ pack.label ? pack.label : pack.pack.label }}
                                </b-form-select-option>
                              </template>
                            </select>
                          </div>

                          <div v-else-if="prop.model.type.code.match(/BOOLEAN/)">
                            <label class="form-check form-switch form-check-custom form-check-solid d-flex flex-grow-1">
                              <span class="btn btn-sm">{{ trans('Non') }}</span>
                              <input class="form-check-input" type="checkbox" v-model="prop.newValue"
                                     value=""/>
                              <span class="form-check-label">{{ trans('Oui') }}</span>
                            </label>
                          </div>

                          <template v-else>
                            {{ prop.formattedValue }}
                          </template>
                          <div class="ms-2">
                            <button class="btn btn-icon btn-success btn-sm"
                                    :disabled="prop.value === prop.newValue" @click="updateProperty(prop)">
                              <i class="fad fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="prop.children.length > 0">
                        <button class="btn btn-sm btn-primary"
                                @click="loadItem(prop.children[0].uuid, (item.level+1))">
                          Charger {{ prop.model.name }}
                        </button>
                      </template>

                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </transition-group>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Base from "@/layouts/Base.vue";
import Item from "@/entity/Item";
import {api} from "@/services/Api";
import Loading from "@/components/Loading.vue";
import Property from "@/entity/Property";
import Swal from "sweetalert2";
import {auth} from "@/services/Auth";
import Popup from "@/entity/Popup";
import PackCategory from "@/entity/PackCategory";
import Branch from "@/entity/Branch";
import Pack from "@/entity/Pack";

@Component({
  components: {Loading, Base}
})
export default class ItemViewer extends Vue {
  @Prop({default: null}) uuid?: string
  @Prop({
    default: () => {
      return []
    }
  }) packCategories!: PackCategory[]

  item: Item | null = null
  items: Item[] = []
  branch: any = null
  levels: any[] = []
  packs: any[] = []

  isLoadingPackCategories = false

  mounted() {
    if (this.uuid) {
      this.loadItem(this.uuid, 0)
    } else if (this.$route.params.uuid) {
      this.loadItem(this.$route.params.uuid, 0)
    }
  }

  getConvertedValue() {

  }

  async updateProperty(property: Property) {
    if (!auth.isAdmin()) {
      const pop = new Popup('Accès non autorisé', "Vous ne disposez pas des accès necessaire pour effectuer cette opération");
      return
    }
    const response = await Swal.fire({
      title: 'Mise à jour',
      html: 'êtes-vous sûr de vouloir mettre à jour la propriété ' + property.name + ' => ' + property.newValue,
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      confirmButtonText: 'Mettre à jour',
    })
    if (response.isConfirmed) {
      this.$store.commit('loading')
      const res = await api.post(api.core, 'property/update', {id: property.id, value: property.newValue})
      this.$store.commit('stopLoading')
      if (res && res.data) {
        property.value = property.newValue as any
      }


    }
  }

  async loadPackCategories() {
    // console.log(this.branch)
    this.isLoadingPackCategories = true;
    this.packCategories = await this.$store.getters.packCategories(this.branch.slug);
    this.isLoadingPackCategories = false
  }

  async loadItem(uuid: string, level: number) {

    this.$store.commit('loading')
    const res = await api.get(api.core, 'item/get/data/' + uuid)
    if (res && res.data && res.data.item) {

      let currentLevel = this.levels.find((l: any) => {
        return l.code === level
      })
      if (!currentLevel) {
        currentLevel = {code: level, items: []}
        this.levels.push(currentLevel)

      }
      this.branch = new Branch(res.data.branch)
      currentLevel.items = currentLevel.items.filter((item: Item) => {
        return item.uuid !== res.data.item.uuid
      })
      const occ = new Item(res.data.item)

      occ.level = level
      this.items.push(occ)

      currentLevel.items.push(occ)

      this.levels.sort((a, b) => (a.code > b.code) ? 1 : ((b.code > a.code) ? -1 : 0))
      for (let p of occ.properties) {
        if (p.model.type.code.match(/PACK_CATEGORY/)) {
          this.loadPackCategories()
        }
        if (p.model.type.code.match(/PACK/)) {
          if (typeof p.value === 'string') {
            let value = JSON.parse(p.value)
            console.log(value)
            if (value.insurer && value.insurer.slug) {
              this.loadPacks(this.branch.slug, value.insurer.slug)
            }
          }
        }
      }
    }
    this.$store.commit('stopLoading')
  }

  async loadPacks(branch: string, insurer: any) {
    const res = await api.get(api.core, 'insurer/get/packs/' + insurer + '/' + branch)
    if (res && res.data && res.data.packs) {
      this.packs = []
      res.data.packs.forEach((i: any) => {
        const insurer = {id: i.insurer.id, slug: i.insurer.slug, label: i.insurer.label}
        const data = {
          pack: {id: i.id, code: i.code, label: i.label, insurer: insurer},
          insurer: insurer,
          optionals: [],
          category: {id: i.category.id, slug: i.category.slug, label: i.category.label}
        }
        this.packs.push(data)
      })
    }
  }
}
</script>
<style scoped>
</style>
