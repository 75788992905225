import {RouteConfig} from "vue-router";

const prefix = '/comptabilite'
const accountingRoutes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'accounting-index',
        component: () => import("@/views/Accounting/AccountingIndex.vue"),
    },

    {
        path: '/comptes',
        name: 'accounting-list',
        component: () => import("@/views/Accounting/AccountList.vue"),
    },
    {
        path: '/reversements',
        name: 'accounting-repayment-list',
        component: () => import("@/views/Accounting/RepaymentList.vue"),
    },
    {
        path: '/reversement/:uuid',
        name: 'accounting-repayment',
        component: () => import("@/views/Accounting/RepaymentEdit.vue"),
    },



]
accountingRoutes.forEach((route) => {
    route.path = prefix + route.path
})
export default accountingRoutes;
