<template>
  <div class="Statistics">

    <div class="row g-5 g-xl-10 mb-xl-10">
      <div class="d-flex col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
        <div class="card card-flush overflow-hidden h-lg-100 flex-1 mb-5 mb-xl-10">
          <div class="card-header pt-5">
            <h3 class="card-title align-items-start flex-column">
              <div>
                <span class="card-label fw-bold text-dark">{{ trans('Contracts') }}</span>
                <span class="mt-1 fw-semibold fs-6 badge badge-primary text-white">
                  {{ contractCount }}
                </span>
              </div>
              <div class="mt-5">
                <span class="text-gray-400 mt-1 fw-semibold fs-2 mt-5 fw-bolder">
                  {{ currency(contractAmount) }}
                </span>
              </div>
            </h3>
            <div class="card-toolbar">
              <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left"
                   data-kt-daterangepicker-range="today" class="btn btn-sm btn-light d-flex align-items-center px-4"
                   data-kt-initialized="1">
                <div class="text-gray-600 fw-bold">
                  ...
                </div>
                <i class="ki-duotone ki-calendar-8 fs-1 ms-2 me-0"><span class="path1"></span><span
                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                    class="path5"></span><span class="path6"></span></i>
              </div>
            </div>
          </div>
          <div class="card-body d-flex align-items-end p-0 h-350px">
            <div class="w-100 h-100 ">
              <apexchart type="line" :options="data.contracts" :series="data.contracts.series"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row g-5 g-xl-10 mb-xl-10">
      <div class="d-flex col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
        <div class="card card-flush mb-5 mb-xl-10 flex-1 h-lg-100">
          <div class="card-header pt-5">
            <div class="card-title d-flex flex-column">
              <div class="d-flex align-items-center">
                <i class="fad fa-arrow-up fs-2 text-primary me-2"></i>
                <span class="fs-2hx fw-bold text-primary me-2 lh-1 ls-n2">
                  {{ currency(emissionGlobale(), false, false) }}
                </span>
                <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                  {{ $store.state.currency.symbol }}
                </span>
              </div>
              <span class="text-gray-400 pt-1 fw-semibold fs-6">Emissions Globale</span>
            </div>
          </div>
          <div class="card-body pt-2 pb-4 px-2 d-flex flex-wrap align-items-center">
            <b-tabs class="flex-grow-1">
              <b-tab v-for="category in categories" :key="category.uuid">
                <template #title>
                  {{ category.label }}
                  <br>
                  <span class="badge badge-primary">
                  {{ miniCurrency(getEmissionByCategory(category), false, false) }}
                    </span>
                </template>
                <div class="d-flex flex-wrap pt-2 justify-content-start">
                  <div :key="branch.uuid" class="d-flex fw-bolder align-items-center p-3 w-50"
                       v-for="branch in category.branches"
                       style="flex-basis: 50%">
                    <div class="bullet w-8px h-3px rounded-2 me-3"
                         :style="{'background' : branch.color}"></div>
                    <router-link
                        :to="{name: 'income-state', query:{branch: branch.slug,startDate: reversedDateToHtml(interval.startDate),endDate: reversedDateToHtml(interval.endDate),
                        platform: filter && filter.platform ? filter.platform : null}}"
                        class="text-gray-500 flex-grow-1 me-4 whitespace-no-wrap">
                      {{ branch.label }}
                    </router-link>
                    <div class="fw-bolder text-gray-700 text-xxl-end  whitespace-no-wrap">
                      {{ getEmissionByBranch(branch) }}
                    </div>
                  </div>

                </div>
              </b-tab>
            </b-tabs>

          </div>
        </div>
      </div>
      <div class="d-flex col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
        <div class="card card-flush mb-5 mb-xl-10 flex-1 h-lg-100">
          <div class="card-header pt-5">
            <div class="card-title d-flex flex-column">
              <div class="d-flex align-items-center">
                <i class="fad fa-file-signature fs-2 text-primary me-2"></i>
                <span class="fs-2hx fw-bold text-primary me-2 lh-1 ls-n2">
                  {{ currency(commission(), false, false) }}
                </span>
                <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start">
                  {{ $store.state.currency.symbol }}
                </span>
              </div>
              <span class="text-gray-400 pt-1 fw-semibold fs-6">Commissions</span>
            </div>
          </div>
          <div class="card-body pt-2 pb-4 px-2 d-flex flex-wrap align-items-start">
            <b-tabs class="flex-grow-1">
              <b-tab :key="category.uuid" v-for="category in categories">
                <template #title>
                  {{ category.label }}
                  <br>
                  <span class="badge badge-primary">
                  {{ miniCurrency(commission(category)) }}
                    </span>
                </template>
                <div class="d-flex flex-wrap pt-2 justify-content-start">
                  <div :key="'b_'+branch.id" class="d-flex fw-bolder align-items-center p-3 w-50"
                       v-for="branch in category.branches">
                    <div class="bullet w-8px h-3px rounded-2 me-3"
                         :style="{'background' : branch.color}"></div>
                    <div class="text-gray-500 flex-grow-1 me-4 whitespace-no-wrap">
                      {{ branch.label }}
                    </div>
                    <div class="fw-bolder text-gray-700 text-xxl-end  whitespace-no-wrap">
                      {{ miniCurrency(commission(null, branch)) }}
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>

          </div>
        </div>
      </div>

      <!--      <div class="d-flex col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">-->
      <!--        <div class="card card-flush mb-5 mb-xl-10 flex-1 h-lg-100">-->
      <!--          <div class="card-header pt-5">-->
      <!--            <div class="card-title d-flex flex-column">-->
      <!--              <div class="d-flex align-items-center">-->
      <!--                <i class="fad fa-file-signature fs-2 text-primary me-2"></i>-->
      <!--                <span class="fs-2hx fw-bold text-primary me-2 lh-1 ls-n2">-->
      <!--                  {{ avenantCount() }}-->
      <!--                </span>-->
      <!--              </div>-->
      <!--              <span class="text-gray-400 pt-1 fw-semibold fs-6">Reversements</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="card-body pt-2 pb-4 px-2 d-flex flex-wrap align-items-start">-->
      <!--            <b-tabs class="flex-grow-1">-->
      <!--              <b-tab :key="'c_'+category.id" v-for="category in categories">-->
      <!--                <template #title>-->
      <!--                  {{ category.label }}-->
      <!--                  <br>-->
      <!--                  <span class="badge badge-primary">-->
      <!--                  {{ miniCurrency(avenantCount(category)) }}-->
      <!--                    </span>-->
      <!--                </template>-->
      <!--                <div class="d-flex flex-wrap pt-2 justify-content-start">-->
      <!--                  <div :key="'b__'+branch.id" class="d-flex fw-semibold align-items-center p-3 flex-1"-->
      <!--                       v-for="branch in category.branches"-->
      <!--                       style="flex-basis: 50%">-->
      <!--                    <div class="bullet w-8px h-3px rounded-2 me-3"-->
      <!--                         :style="{'background' : branch.color}"></div>-->
      <!--                    <div class="text-gray-500 flex-grow-1 me-4 whitespace-no-wrap">-->
      <!--                      {{ branch.label }}-->
      <!--                    </div>-->
      <!--                    <div class="fw-bolder text-gray-700 text-xxl-end  whitespace-no-wrap">-->
      <!--                      {{ avenantCount(null, branch) }}-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </b-tab>-->
      <!--            </b-tabs>-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="d-flex col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">-->
      <!--        <div class="card card-flush mb-5 mb-xl-10 flex-1 h-lg-100">-->
      <!--          <div class="card-header pt-5">-->
      <!--            <div class="card-title d-flex flex-column">-->
      <!--              <div class="d-flex align-items-center">-->
      <!--                <i class="fad fa-file-signature fs-2 text-primary me-2"></i>-->
      <!--                <span class="fs-2hx fw-bold text-primary me-2 lh-1 ls-n2">-->
      <!--                  {{ avenantCount() }}-->
      <!--                </span>-->
      <!--              </div>-->
      <!--              <span class="text-gray-400 pt-1 fw-semibold fs-6">Clients</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="card-body pt-2 pb-4 px-2 d-flex flex-wrap align-items-start">-->
      <!--            <b-tabs class="flex-grow-1">-->
      <!--              <b-tab :key="'c2-'+category.id" v-for="category in categories">-->
      <!--                <template #title>-->
      <!--                  {{ category.label }}-->
      <!--                  <br>-->
      <!--                  <span class="badge badge-primary">-->
      <!--                  {{ miniCurrency(avenantCount(category)) }}-->
      <!--                    </span>-->
      <!--                </template>-->
      <!--                <div class="d-flex flex-wrap pt-2 justify-content-start">-->
      <!--                  <div :key="'b2_'+branch.id" class="d-flex fw-semibold align-items-center p-3 flex-1"-->
      <!--                       v-for="branch in category.branches"-->
      <!--                       style="flex-basis: 50%">-->
      <!--                    <div class="bullet w-8px h-3px rounded-2 me-3"-->
      <!--                         :style="{'background' : branch.color}"></div>-->
      <!--                    <div class="text-gray-500 flex-grow-1 me-4 whitespace-no-wrap">-->
      <!--                      {{ branch.label }}-->
      <!--                    </div>-->
      <!--                    <div class="fw-bolder text-gray-700 text-xxl-end  whitespace-no-wrap">-->
      <!--                      {{ avenantCount(null, branch) }}-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </b-tab>-->
      <!--            </b-tabs>-->

      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="row g-5 g-xl-10 mb-xl-10">
      <div class="d-flex col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10">
        <div class="card card-flush overflow-hidden h-lg-100 flex-1 mb-5 mb-xl-10">
          <div class="card-header pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label fw-bold text-dark">{{ trans('Cotations') }}</span>
              <span class="text-gray-400 mt-1 fw-semibold fs-6">{{ rgCount }}</span>
            </h3>
            <div class="card-toolbar">
              <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left"
                   data-kt-daterangepicker-range="today" class="btn btn-sm btn-light d-flex align-items-center px-4"
                   data-kt-initialized="1">
                <div class="text-gray-600 fw-bold">
                  ...
                </div>
                <i class="ki-duotone ki-calendar-8 fs-1 ms-2 me-0"><span class="path1"></span><span
                    class="path2"></span><span class="path3"></span><span class="path4"></span><span
                    class="path5"></span><span class="path6"></span></i>
              </div>
            </div>
          </div>
          <div class="card-body d-flex align-items-end p-0 h-350px">
            <div class="w-100 h-100 ">
              <apexchart type="line" :options="data.cotations" :series="data.cotations.series"></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>
<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {api} from "@/services/Api";
import Avenant from "@/entity/Avenant";
import store from "@/store";
import BranchCategory from "@/entity/BranchCategory";
import Branch from "@/entity/Branch";
import {helper} from "@/services/Helper";
import Commission from "@/entity/Commission";

@Component({
  computed: {
    store() {
      return store
    }
  }
})
export default class ManagerStatistic extends Vue {

  @Prop() interval!: any
  @Prop() filter?: any


  data = {
    emissions: [] as any,
    commissions: [] as any,
    emissionTTCGlobale: 0,
    emissionNetteGlobale: 0,
    payments: [] as any,
    cotations: {} as any,
    contracts: {} as any
  }
  categories: BranchCategory[] = []


  mounted() {
    // console.log('mount')
    this.load()
  }

  emissionGlobale() {
    let total = 0
    this.data.emissions.forEach((avenant: Avenant) => {
      total += avenant.primeTTC
    })
    return total
  }

  avenantCount(category: any = null, branch: any = null) {
    let total = 0
    this.categories.forEach((cat: BranchCategory) => {
      if (category === null || category.id === cat.id) {

        cat.branches.forEach((b: Branch) => {
          if (branch === null || branch.id === b.id) {
            total += b.avenantCount
          }
        })
      }
    })
    return total
  }

  commission(category: any = null, branch: any = null) {
    let total = 0
    this.categories.forEach((cat: BranchCategory) => {
      if (category === null || category.id === cat.id) {
        cat.branches.forEach((b: Branch) => {
          if (branch === null || branch.id === b.id) {
            total += b.commissions
          }
        })
      }
      // total += cat.commissions
    })
    return total
  }

  soldeGlobale() {
    let total = 0
    this.data.emissions.forEach((avenant: Avenant) => {
      total += Math.abs(avenant.balance)
    })
    return total
  }

  getEmissionByBranch(branch: Branch) {
    let total = 0
    let payed = 0
    this.data.emissions.forEach((avenant: Avenant) => {
      if ((avenant.branch as any).id === branch.id) {
        total += avenant.primeTTC
      }
    })

    this.data.payments.forEach((p: any) => {
      if (p.slug === branch.slug) {
        payed = p.premium;
      }
    })
    if (payed !== total) {
      return helper.minifyCurrency(payed) + '/' + helper.minifyCurrency(total)
    }
    return helper.minifyCurrency(payed)
  }

  getPayedByBranch(branch: Branch) {
    let total = 0
    for (let payment of this.data.payments) {
      if (payment.slug === branch.slug) {
        return helper.minifyCurrency(payment.premium)
      }
    }
    return total
  }

  getEmissionByCategory(cat: BranchCategory) {
    let total = 0
    this.data.emissions.forEach((avenant: Avenant) => {
      cat.branches.forEach((branch: Branch) => {
        if ((avenant.branch as any).id === branch.id) {
          total += avenant.primeTTC
        }
      })
    })
    return total
  }

  setCotationStatistic() {
  }

  async loadFormData() {
    let data = {
      start: new Date(this.interval.startDate),
      end: new Date(this.interval.endDate)
    }
    if (!helper.empty(this.filter)) {
      data = Object.assign({}, data, this.filter);
    }
    const res = await api.post(api.form, 'data/dashboard', {
      search: data
    });
    if (res && res.data) {


      const cotations = {
        series: [
          {
            name: "Desktops",
            data: [10, 41, 35, 51]
          },
          // {
          //   name: "Mobile",
          //   data: [0, 21, 15, 71, 69, 42, 99, 21, 18]
          // },
          // {
          //   name: "Portable",
          //   data: [20, 31, 65, 81, 19, 22, 19, 51, 48]
          // }
        ],
        chart: {
          width: '100%',
          height: '100%',
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        legend: {
          position: 'top'
        },
        // grid: {
        //   row: {
        //     colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        //     opacity: 0.5
        //   },
        // },
        xaxis: {
          categories: [] as any[],
        },
        yaxis: {
          forceNiceScale: false
        }
      }
      const rgs = res.data.rgs

      cotations.xaxis.categories = [];
      const data: any[] = []
      const series: any[] = []
      this.rgCount = 0
      rgs.forEach((rg: any) => {
        const date = helper.readable(rg.createdAt, 'dMy')
        const index = cotations.xaxis.categories.indexOf(date);
        if (index === -1) {
          cotations.xaxis.categories.push(date)
        }
        this.rgCount += rg.count
        if (!(rg.branch in data)) {
          data[rg.branch] = {slug: rg.branch, data: []}
          series.push({name: rg.branch, slug: rg.branch, data: []})
        }
        data[rg.branch]['data'][date] = rg.count
      })


      cotations.xaxis.categories.forEach((d: string) => {
        series.forEach((serie: any) => {
          // console.log(data[serie.slug])
          if (!(serie.slug in data)) {
            serie.data.push(0)
          } else if (!(d in data[serie.slug]['data'])) {
            serie.data.push(0)
          } else {
            serie.data.push(data[serie.slug]['data'][d])
          }
        })
      })
      // const a = series[0]
      // a.name = 'test'
      // series.push(a)

      // cotations.xaxis.categories.forEach((d: string) => {
      //   const date = helper.readable(d, 'dMy')
      //   this.series.forEach((serie: any) => {
      //     console.log(d+'=== '+date)
      //     console.log(serie.slug+'=== ')
      //   })
      // })
      cotations.series = series
      // console.log(cotations)
      this.data.cotations = cotations
      // console.log(this.data.cotations.xaxis.categories)
      // console.log(this.series)
    }
  }

  async load() {
    this.loadCoreData()
    this.loadFormData()
  }

  series: any[] = []
  rgCount = 0
  contractCount = 0
  contractAmount = 0

  async loadCoreData() {
    let data = {
      start: new Date(this.interval.startDate),
      end: new Date(this.interval.endDate)
    }
    if (!helper.empty(this.filter)) {
      data = Object.assign({}, data, this.filter);
    }
    const res = await api.post(api.core, 'data/dashboard', {
      search: data
    });
    if (res && res.data) {

      this.categories = []
      res.data.categories.forEach((a: any) => {
        const cat = new BranchCategory(a)
        this.categories.push(cat)
        // cat.branches.forEach((branch: Branch) => {
        //
        // })
      })

      res.data.emissions.forEach((e: any) => {
        const avenant = new Avenant(e)
        this.data.emissions.push(avenant)
        this.categories.forEach((cat: BranchCategory) => {
          cat.branches.forEach((branch: Branch) => {
            if (branch.id === e.branchId) {
              avenant.branch = branch as any
              branch.avenantCount = e.count
            }
          })
        })
      })
      res.data.commissions.forEach((e: any) => {
        const com = new Commission(e)
        this.data.commissions.push(com)
        this.categories.forEach((cat: BranchCategory) => {
          cat.commissions = 0
          cat.branches.forEach((branch: Branch) => {
            if (branch.id === e.branchId) {
              branch.commissions = e.premium
              cat.commissions += e.premium
            }
          })
        })
      })
      this.data.payments = res.data.payments
      this.data.emissionTTCGlobale = 0
      this.data.emissionNetteGlobale = 0
      this.data.emissions.forEach((avenant: Avenant) => {
        this.data.emissionTTCGlobale += avenant.primeTTC
        this.data.emissionNetteGlobale += avenant.primeNette
      })
      this.setCotationStatistic()

      const contracts = {
        series: [] as any[],
        chart: {
          width: '100%',
          height: '100%',
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false,
          formatter: function (value: any, options: any) {
            return helper.minifyCurrency(value)
          }
        },
        tooltip: {
          x: {
            show: false
          },
          y: {
            formatter: function (value: any) {
              return helper.currencyFormat(value)
            }
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        legend: {
          position: 'top'
        },
        xaxis: {
          categories: [] as any[],
        },
        yaxis: {
          forceNiceScale: false,
          labels: {
            formatter: (value: any) => {
              return helper.minifyCurrency(value)
            }
          }
        }
      }
      contracts.xaxis.categories = [];

      const series = [] as any
      const data = {} as any
      this.contractCount = 0
      res.data.contracts.forEach((c: any) => {
        const date = helper.readable(c.createdAt, 'dMy')
        const index = series.findIndex((s: any) => {
          return s.slug === c.branchSlug
        })
        if (index === -1) {
          series.push({name: c.branchLabel, slug: c.branchSlug, data: []})
          data[c.branchSlug] = {data: []}
        }
        if (!contracts.xaxis.categories.includes(date)) {
          contracts.xaxis.categories.push(date)
        }
        this.contractCount += c.count
        this.contractAmount += c.primeTTC
        data[c.branchSlug]['data'][date] = c.primeTTC
      })

      contracts.xaxis.categories.forEach((d: string) => {
        series.forEach((serie: any) => {
          if (!(serie.slug in data)) {
            serie.data.push(0)
          } else if (!(d in data[serie.slug]['data'])) {
            serie.data.push(0)
          } else {
            serie.data.push(data[serie.slug]['data'][d])
          }
        })
      })
      contracts.series = series
      // const keys = Object.keys(series);
      // keys.forEach((k: any) => {
      //   contracts.series.push(series[k])
      // })
      // console.log(contracts.series)
      this.data.contracts = contracts
    }
  }

}
</script>
<style scoped>
</style>
