<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div
          class="container-fluid d-flex flex-stack"
          id="kt_toolbar_container"
        >
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link
                    :to="{ name: 'prospection-index' }"
                    class="text-muted text-hover-primary"
                  >
                    <i class="fad fa-headset"></i>
                    {{ trans("Prise de contact") }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>

          <div class="d-flex align-items-center py-1"></div>
        </div>
      </div>
    </template>

    <template v-slot:content>
      <div class="card">
        <div
          class="card-header d-flex justify-content-between align-items-center"
        >
          <h3>
            {{ count }} Prospection effectuées du
            {{ readableDate(range.startDate, "dmY") }} au
            {{ readableDate(range.endDate, "dmY") }}
          </h3>

          <div class="d-flex align-items-center">

            <button class="btn btn-icon btn-danger me-2" @click="resetSearch()">
              <i class="fad fa-trash"></i>
            </button>

            <b-dropdown
            id="dropdown-1"
            no-caret
            no-flip
            ref="cotationFilter"
            right
            size="sm"
            style="padding: 0"
            tag="div"
            toggle-class="topbar-item text-decoration-none"
            variant="link"
          >
            <template v-slot:button-content>
              <b-button id="popover-search" variant="primary"
                >Rechercher</b-button
              >
            </template>

            <b-dropdown-text class="min-w-750px" tag="div">
              <div class="row w-100 align-items-center">
                <div class="col-4">
                  <label class="my-4 fw-bolder" for="">{{
                    trans("Gestionnaire")
                  }}</label>
                  <b-form-select
                    class="form-control form-select"
                    v-model="searched.manager"
                    @keyup.enter="load()"
                  >
                    <b-form-select-option :value="null"
                      >{{ trans("Selectionner un gestionnaire") }}
                    </b-form-select-option>
                    <b-form-select-option value="all"
                      >{{ trans("Tous") }}
                    </b-form-select-option>

                    <b-form-select-option
                      :key="manager.uuid"
                      :value="manager.email"
                      v-for="manager in managers"
                    >
                      {{ manager.name + " " + manager.prename }}
                    </b-form-select-option>
                  </b-form-select>
                </div>
                <div class="col-8">
                  <label class="my-4 fw-bolder" for="">
                    {{ trans("Nom ou Numéro du prospect ou de l'entreprise") }}
                  </label>
                  <input
                    class="form-control"
                    type="text"
                    v-model="searched.contain"
                  />
                </div>

                <div class="col">
                  <label class="my-4 fw-bolder" for="">
                    {{ trans("Periode") }}
                  </label>
                  <date-range-picker
                    ref="picker"
                    :opens="'left'"
                    :locale-data="localData"
                    :singleDatePicker="false"
                    :autoApply="false"
                    :ranges="ranges"
                    @update="load"
                    :timePicker="false"
                    :showWeekNumbers="true"
                    :showDropdowns="true"
                    v-model="range"
                  >
                    <template v-slot:input="picker" style="min-width: 350px">
                      <template>
                        {{ readableDate(picker.startDate, "dmY") }} ❖
                        {{ readableDate(picker.endDate, "dmY") }}
                      </template>
                    </template>
                  </date-range-picker>
                </div>

                <div v-if="hasRole('ROLE_ADMIN')" class="col-4">
                  <label class="my-4 fw-bolder" for="">
                    {{ trans("Periode") }}
                  </label>

                  <div
                    style="width: 100px"
                    class="d-flex container-check me-5"
                    label=""
                  >
                    <div
                      v-for="(item, index) in typeClient"
                      :key="index"
                      class="choiceContent"
                    >
                      <input
                        :value="item.value"
                        class="hidden"
                        @change="load"
                        v-model="searched.type"
                        type="radio"
                        name="categoryClient"
                        :id="'categoryClient' + index"
                      />
                      <label
                        :class="
                          index == typeClient.length - 1 ? '' : 'border-end-0 '
                        "
                        class="border border-primary text-primary py-3 px-6 cursor-pointer fw-bolder custom-label"
                        :for="'categoryClient' + index"
                      >
                        {{ item.text }}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-end align-items-center my-5">
                  <button
                    @click="load()"
                    class="btn btn-sm btn-primary"
                    type="submit"
                  >
                    Rechercher
                  </button>
                </div>
              </div>
            </b-dropdown-text>
          </b-dropdown>
          </div>
        
        </div>
        <div class="card-body py-3">
          <div class="row flex-nowrap overflow-auto" >
            <div
              v-for="(handshake, index) in handShakes"
              :key="index"
              class="px-2"
              style="max-width: 500px"
            >
              <div class="card border-primary">
                <div
                  :style="'background-color:' + getStepColor(index)"
                  class="card-header text-white text-center fw-bold d-flex justify-content-center align-items-center"
                >
                  {{ index }} ({{ handshake.total }})
                </div>

                <div class="card-body bg-light" style="min-height: 500px">
                  <p
                    v-if="handShakes[index].handshakes.length === 0"
                    class="text-muted text-center"
                  >
                    Aucune prise de contact
                  </p>
                  <div
                    v-for="item in handShakes[index].handshakes"
                    :key="item.id"
                    class="card mb-3 shadow-sm"
                  >
                    <div
                      class="card-header bg-light text-center fw-bold d-flex justify-content-between align-items-center flex-nowrap"
                    >
                    <h5 class="card-title text-primary ellipsis" style="max-width: 100%;">{{ item.title }}</h5>

                      <div class="d-flex flex-nowrap">
                        <button
                          @click="ViewHandShake(item.id)"
                          class="btn btn-icon btn-info btn-sm"
                        >
                          <i class="fad fa-eye"></i>
                        </button>

                        <router-link
                          class="mx-2"
                          :to="{
                            name: 'handshake-form',
                            params: { uuid: item.id, idClient: 'null' },
                          }"
                        >
                          <button
                            class="btn btn-icon btn-secondary btn-sm mx-2"
                          >
                            <i class="fad fa-edit"></i>
                          </button>
                        </router-link>

                        <b-modal :id="'viewHandShake' + item.id" :title="item.title" size="xl" class="modal-elegant">
                          <div class="p-4">
                            <!-- Étape Badge -->
                            <span class="badge badge-step px-4 py-2 fw-bold shadow-sm" :style="'background-color:' + getStepColor(index)">
                              {{ item.step }}
                            </span>
                        
                            <div class="row mt-4 align-items-center">
                              <!-- Informations générales -->
                              <div class="col-md-4 d-flex flex-column align-items-center justify-content-center text-center">
                                <div class="avatar-container shadow-lg">
                                  <h1 class="status-icon">
                                    {{
                                      item.uniqProspect?.status === "HOT" ? "😍" :
                                      item.uniqProspect?.status === "WARM" ? "😑" :
                                      item.uniqProspect?.status === "COLD" ? "🥶" :
                                      item.uniqProspect?.status === "UNVALID" ? "😒" : "🫥"
                                    }}
                                  </h1>
                                  <span class="status-indicator bg-success"></span>
                                </div>
                                
                                <router-link :to="{ name: 'details-clients', params: { id: item.uniqProspect.id } }" class="text-decoration-none mt-3">
                                  <h3 class="fw-bold text-dark">{{ item.uniqProspect.name }}</h3>
                                </router-link>
                                
                                <router-link v-if="item.uniqProspect?.company?.name" :to="{ name: 'detail-company', params: { id: item.uniqProspect?.company?.id } }" class="text-decoration-none my-3">
                                  <p class="fw-bold text-primary my-1">Entreprise:

                                  
                                  <span class="text-muted">{{ item.uniqProspect.company.name }} </span></p>
                                </router-link>
                                
                                <div class="contact-info d-flex align-items-center">
                                  <i class="fas fa-phone-alt text-primary me-2"></i>
                                  <span class="badge bg-primary">{{ item.uniqProspect.mobile }}</span>
                                </div>
                              </div>
                              
                              <!-- Détails supplémentaires -->
                              <div class="col-md-8">
                                <div class="mb-3 p-3 bg-light rounded shadow-sm ">
                                  <h4 class="fw-bold mb-1 text-dark">Contacté par: {{ item.type }}</h4>
                                  <p class="text-muted">Par {{ item.createBy }} le {{ helper.readable(item.createdAt) }}</p>
                                </div>
                                
                                <h4 class="fw-bold mt-4 text-primary">Notes</h4>
                                <div v-for="(note, index) in item.notes" :key="index" class="note-container p-3 my-2 bg-light border rounded shadow-sm">
                                  <p class="small text-muted mb-1">{{ note.createBy }} - {{ helper.readable(note.createdAt) }}</p>
                                  <div class="border rounded p-3 bg-white">
                                    <iframe :srcdoc="note.comment" class="w-100 border-0" style="height: 100px;"></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-modal>
                        
                      </div>
                    </div>
                    <div class="card-body">
                      <p class="card-text">
                        <strong>Type:</strong> {{ item.type }} <br />
                        <strong>Gestionnaire du prospect:</strong>
                        {{ item.uniqProspect.manager }} <br />
                        <template v-if="item.uniqProspect.company">
                          <div class="d-flex flex-column">
                            <span>
                              <strong>Entreprise:</strong>
                              {{ item.uniqProspect.company.name }}
                            </span>
                            <span>
                              <strong>Agent de contact:</strong>
                              {{ item.uniqProspect?.name || "N/A" }}
                            </span>
                          </div>
                        </template>
                        <template v-else>
                          <strong>Prospect:</strong>
                          {{ item.uniqProspect?.name || "N/A" }}
                        </template>
                      </p>
                    </div>

                    <div
                      class="card-footer f-flex align-items-center justify-content-between"
                    >
                      <span>
                        Contacté le {{ helper.readable(item.createdAt) }} par
                      </span>

                      <user-view v-model="item.createBy"></user-view>
                    </div>
                  </div>
                </div>

                <div class="card-footer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Base>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import Base from "@/layouts/Base.vue";
import SellHandShake from "@/entity/SellHandShake";
import { api } from "@/services/Api";
import Popup from "@/entity/Popup";
import { helper } from "@/services/Helper";
import Handshake from "../../entity/Handshake";
import Pagination from "@/components/Pagination.vue";
import UserView from "@/components/UserView.vue";
import Step from "../../entity/Step";
import CONSTANTS from "@/Utils/constant";

import DateRangePicker from "vue2-daterange-picker";
import User from "@/entity/User";
import Contact from "../Income/Comparator/FormComponents/Contact.vue";
@Component({
  components: { Base, Pagination, UserView, DateRangePicker },
})
export default class HandShakeList extends Vue {
  handShakes: any[] = [];
  params: any = {
    limit: 20,
    page: 1,
  };
  total: number = 0;

  typeClient = [
    {
      text: "B2C",
      value: "B2C",
    },
    {
      text: "B2B",
      value: "B2B",
    },
  ];

  localData = {
    direction: "ltr",
    format: "mm/dd/yyyy",
    separator: " - ",
    applyLabel: "Valider",
    cancelLabel: "Annuler",
    weekLabel: " ",
    customRangeLabel: "Custom Range",
    daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    firstDay: 0,
  };

  ranges: any = false;
  range = {
    startDate: CONSTANTS.dateConstants.getLast7DaysInterval().start,
    endDate: CONSTANTS.dateConstants.getLast7DaysInterval().end,
  };

  searched: any = {
    start: this.range.startDate,
    end: this.range.endDate,
    type: null,
    manager: null,
    contains: null,
  };

  searchedTemplate: any = {
    start: CONSTANTS.dateConstants.getLast7DaysInterval().start,
    end: CONSTANTS.dateConstants.getLast7DaysInterval().end,
    type: null,
    manager: null,
    contains: null,
  };

  resetSearch(){

    this.searched = this.searchedTemplate

    this.range = {
    startDate: CONSTANTS.dateConstants.getLast7DaysInterval().start,
    endDate: CONSTANTS.dateConstants.getLast7DaysInterval().end,
  };
    this.load()
  }
  count = 0;

  async load() {
    const pop = new Popup(
      "Chargement",
      "Liste des prise de contact",
      "primary",
      "fad fa-sync",
      true
    );

    const res = await api.post(api.form, `handshake/listing-by-step`, {
      ...this.searched,
      start: this.range.startDate,
      end: this.range.endDate,
    });

    if (res && res.data) {
      this.handShakes = res.data.handshakesGroup.handshakes;
      this.count = res.data.handshakesGroup.count;
      // this.total = res.data.total;
      // res.data.handshakes.forEach((h: any) => {
      //   const occ = new Handshake(h);

      //   this.handShakes.push(occ);
      // });

      pop.hide();
    }
  }

  getStepColor(step: any) {
    const STEP_COLORS: any = {
      NON_CATEGORISE: "#808080",
      LEAD: "#FFA500",
      CONTACTÉ: "#007BFF",
      RENDEZVOUS: "#28A745",
      COTATION: "#e0338a",
      CONTRACT_SIGNÉ: "#00939C",
    };

    return STEP_COLORS[step] || "#000000"; // Noir par défaut si non trouvé
  }

  ViewHandShake(id: number) {
    this.$bvModal.show("viewHandShake" + id);

    this.$forceUpdate;
  }

  loadRanges() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.ranges = {
      Aujourdhui: [today, today],
      Hier: [yesterday, yesterday],
      "Ce mois": [
        CONSTANTS.dateConstants.first_day_current_month(),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Le mois dernier": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0),
      ],
      "Dernier Trimestre": [
        CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 2)),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Dernier Semestre": [
        CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 5)),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Cette année": [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],
      "L'année passée": [
        new Date(today.getFullYear() - 1, 0, 1),
        new Date(today.getFullYear() - 1, 11, 31),
      ],
      "2 Dernières années": [
        new Date(today.getFullYear() - 1, 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],

      "Cette semaine": [
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 1
        ), // Lundi de cette semaine
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 7
        ), // Dimanche de cette semaine
      ],

      "La semaine dernière": [
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() - 6
        ), // Lundi de la semaine dernière
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay()
        ), // Dimanche de la semaine dernière
      ],
    };
    this.$forceUpdate();
  }

  managers: User[] = [];

  async mounted() {
    this.load();

    this.managers = await api.loadManagers();
    this.loadRanges();
  }
}
</script>
<style  scoped>


.modal-elegant {
  font-family: 'Poppins', sans-serif;
  background: #f8f9fa;
}
.badge-step {
  font-size: 1rem;
  border-radius: 50px;
}
.avatar-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
}
.status-icon {
  font-size: 4rem;
}
.status-indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  right: 5px;
  border: 2px solid white;
}
.note-container {
  border-left: 5px solid var(--bs-primary);
}

.ellipsis {

  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%; /* Ou une largeur fixe selon ton design */
}



</style>



<!-- 


<table
class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
>
<thead>
  <tr class="fw-bolder text-muted">
    <th class="w-25px">
      <div
        class="form-check form-check-sm form-check-custom form-check-solid"
      >
        <input
          class="form-check-input"
          data-kt-check="true"
          data-kt-check-target=".widget-9-check"
          type="checkbox"
          value="1"
        />
      </div>
    </th>
    <th class="min-w-150px">{{ trans("Prise de contact") }}</th>
    <th class="min-w-140px">{{ trans("Clients") }}</th>
    <!--              <th v-if="!compact">-->
<!--                Prochaine activité-->
<!--              </th>-->
<th class="min-w-120px">{{ trans("Statut") }}</th>
<th class="min-w-100px text-end">Actions</th>
</tr>
</thead>
<tbody>
  <!-- <tr v-if="rgs.length === 0">
  <td colspan="9">
    <div class="d-flex flex-column justify-content-center pb-20 pt-20 flex-grow-1 align-items-center
                                                  border-bottom-dashed border-gray-300">
      <i class="fad fa-empty-set fs-4x"></i>
      <span class="mt-5 fs-2x text-gray-400">
        {{ trans('Aucun resultat') }}
      </span>
    </div>
  </td>
</tr>

-->
  <tr
    v-for="(handshake, index) in handShakes"
    :key="'cotation_' + handshake.id"
  >
    <td class="">
      <div
        class="form-check form-check-sm form-check-custom form-check-solid"
      >
        <input
          class="form-check-input widget-9-check"
          type="checkbox"
          value="1"
        />
      </div>
    </td>
    <td>
      <div class="d-flex flex-column" style="text-transform: uppercase">
        <span>
          {{
            handshake.type
          }}
        </span>
        <span  class="fw-bolder">
          {{ handshake.title }}
        </span>
      </div>
    </td>
    <td>
      <div class="d-flex flex-column">
        <span style="text-transform: uppercase" class="fw-bolder">
          {{ handshake.category }}
        </span>

        <span>
          {{ handshake.uniqProspect.name }}
        </span>
      </div>
    </td>

    <td>
      <span class="btn btn-success btn-sm"
        >Effectué par {{ handshake.createBy }} <br />
        le {{ helper.readable(handshake.createdAt) }}</span
      >
    </td>

    <!--              <td v-if="!compact">-->
    <!--              </td>-->
    <td class="text-gray-400 fw-bolder text-end">
      <div>
        <button
          @click="ViewHandShake(handshake.id)"
          class="btn btn-icon btn-primary btn-sm"
        >
          <i class="fad fa-eye"></i>
        </button>

        <b-modal :id="'viewHandShake' + handshake.id" title="Details de la prospection" size="xl">
          <h2 style="text-transform: uppercase">
            {{ handshake.title }}
          </h2>

          <div class="row g-2">
            <div class="col">
              <div
                class="row justify-content-center align-items-center g-2 mt-5"
              >
                <div class="col">
                  <p class="fw-bolder">
                    Propection
                    {{
                      handshake.type == "a_distance"
                        ? "à distance"
                        : handshake.type == "rendezvous"
                        ? "par Rendez-vous"
                        : null
                    }}
                  </p>

                  <div>
                    {{ handshake.createBy }} <br />
                    le {{ helper.readable(handshake.createdAt) }}
                  </div>
                </div>
                <div class="col">
                  <p>Type de client</p>
                  <h5>
                    {{ handshake.category }}
                  </h5>
                </div>
              </div>

              <div class="mt-5">
                <h3>Notes</h3>

                <div
                  v-for="(item, index) in handshake.notes"
                  class="border-bottom border-top py-5"
                  :key="index"
                >
                  <p>
                    {{ item.createBy }} <br />
                    le {{ helper.readable(item.createdAt) }}
                  </p>
                  <iframe
                    :srcdoc="item.comment"
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              </div>
            </div>

            <div
              class="col-4 d-flex flex-column align-items-center"
            >
              <h1 style="font-size: 6rem">
               

                {{
                  handshake.uniqProspect?.status == "HOT"
                    ? "😍"
                    : handshake.uniqProspect?.status == "WARM"
                      ? "😑"
                      : handshake.uniqProspect?.status == "COLD"
                        ? "🥶"
                        : handshake.uniqProspect?.status == "UNVALID"
                          ? "😒"
                          : "🫥"
                }}
              </h1>
              <h1 class="text-center">
                {{ handshake.uniqProspect.name }}
              </h1>

              <div>
              

                <div class="d-flex flex-wrap">
                  <div
                    class="badge badge-secondary m-1 d-flex align-items-center p-0 ps-2"
                  >
                    {{
                      helper.phoneFormat(handshake.uniqProspect?.mobile)
                    }}
                    <div
                      class="ms-2 btn btn-icon btn-primary btn-xs rounded-left-0"
                    >
                      <i class="fad fa-phone"></i>
                    </div>


                  
                  </div>
                </div>

                <router-link class="me-2" :to="{
                  name: 'details-clients',
                  params: { id: handshake.uniqProspect.id },
                }">
                    <button class="btn  btn-primary mt-5">
                     Voir plus 
                    </button>

                  </router-link>
              </div>
            </div>
          </div>
        </b-modal>

        <router-link
          class="mx-2"
          :to="{
            name: 'handshake-form',
            params: { uuid: handshake.id, idClient: 'null' },
          }"
        >
          <button class="btn btn-icon btn-secondary btn-sm mx-2">
            <i class="fad fa-edit"></i>
          </button>
        </router-link>
      </div>
    </td>
  </tr>
</tbody>
</table> -->
