<template>
  <Base>
    <template v-slot:toolbar>
      <div class="toolbar" id="kt_toolbar">
        <div
          class="container-fluid d-flex flex-stack"
          id="kt_toolbar_container"
        >
          <div class="d-flex align-items-center me-3">
            <slot name="leftToolbar">
              <span class="h-20px border-gray-200 border-start mx-4"></span>
              <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                <li class="breadcrumb-item text-muted">
                  <router-link
                    :to="{ name: 'prospection-index' }"
                    class="text-muted text-hover-primary"
                  >
                    <i class="fad fa-headset"></i>
                    {{ trans("Recapitulatif") }}
                  </router-link>
                </li>
              </ul>
            </slot>
          </div>
          <div class="d-flex align-items-center py-1">
            <date-range-picker
              ref="picker"
              :opens="'left'"
              :locale-data="localData"
              :singleDatePicker="false"
              :autoApply="false"
              :ranges="ranges"
              @update="load"
              :timePicker="false"
              :showWeekNumbers="true"
              :showDropdowns="true"
              v-model="range"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                <template>
                  {{ readableDate(picker.startDate, "dmY") }} ❖
                  {{ readableDate(picker.endDate, "dmY") }}
                </template>
              </template>
            </date-range-picker>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:content v-if="dataDashboard">
      <!-- prise de contact avec le total -->
      <div class="g-5 row">
        <h1>{{ trans("Statistiques Globales") }}</h1>

        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h2>
              {{ trans("Prise de contact effectué") }} ({{
                dataDashboard.handshakeByType.count
              }})
            </h2>
          </div>

          <div class="card-body">
            <div
              class="my-5"
              v-for="(item, index) in dataDashboard.handshakeByType.type"
              :key="item.id"
            >
              <div class="card">
                <div class="card-header d-flex align-items-center" :class="String(index) === 'B2C' ? 'bg-warning' : 'bg-primary'">
                  <h1>{{ index }} ({{ item.count }})</h1>
                </div>

                <div class="card-body">
                  <div class="row gx-5">

                    <div class="card card-custom   flex-grow-1 col">
                      <div class="card-header bg-primary border-0 py-5">
                        <h3 class="card-title fw-bolder fs-2 text-white  flex-grow-1">
                          <div>Prise de contact selon les methode</div>
                        </h3>
                        <!-- <div class="card-toolbar"></div> -->
                      </div>
                      <div class="card-body d-flex flex-column " style="position: relative">
                        <div
                            class="card-spacer  card-rounded flex-grow-1  align-items-center">
                          <div v-if="createChartData({...item.byMethod})">
                            <apexchart type="treemap" 
                                       :options="createChartData({...item.byMethod}).chartOptions"
                                       :series="createChartData({...item.byMethod}).series"></apexchart>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-1"></div>

                    <div class="card card-custom  gutter-b card-stretch flex-grow-1 col ">
                      <div class="card-header bg-primary border-0 py-5">
                        <h3 class="card-title fw-bolder fs-2 text-white d-flex justify-content-between flex-grow-1">
                          <div>Liste de prospection par etapes d'avancement </div>
                        </h3>
                        <!-- <div class="card-toolbar"></div> -->
                      </div>
                      <div class="card-body  flex-column " style="position: relative">
                        <div
                            class="card-spacer  card-rounded flex-grow-1 ">
                          <div v-if="createChartData({...item.byStep})">
                            <apexchart type="treemap" 
                                       :options="createChartData({...item.byStep}).chartOptions"
                                       :series="createChartData({...item.byStep}).series"></apexchart>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>






       
   


      <!-- fin prise de contact  -->

      <!-- prospect -->

    
      <div class="card">
        <div class="card-header d-flex align-items-center">
          <h2>
            {{ trans("Prospect enregistré") }} ({{
              dataDashboard.prospect.B2B.count +  dataDashboard.prospect.B2C.count
            }})
          </h2>
        </div>

        <div class="card-body">
          <div
            class="my-5"
            v-for="(item, index) in dataDashboard.prospect"
            :key="item.id"
          >
            <div class="card">
              <div class="card-header d-flex align-items-center" :class="String(index) === 'B2C' ? 'bg-warning' : 'bg-primary'">
                <h1>{{ index }} ({{ item.count }})</h1>
              </div>

              <div class="card-body">
                <div class="row gx-5">

                  <div class="card card-custom   flex-grow-1 col">
                    <div class="card-header bg-primary border-0 py-5">
                      <h3 class="card-title fw-bolder fs-2 text-white  flex-grow-1">
                        <div v-if="String(index) === 'B2C'">Prospect enregistré par impression</div>
                        <div v-if="String(index) === 'B2B'">Entreprise enregistré par impression</div>
                      </h3>
                      <!-- <div class="card-toolbar"></div> -->
                    </div>
                    <div class="card-body d-flex flex-column " style="position: relative">
                      <div
                          class="card-spacer  card-rounded flex-grow-1  align-items-center">
                        <div v-if="createChartData(String(index)==='B2B' ? {...item.companyByType} : {...item.prospectByType})">
                          <apexchart type="treemap" 
                          height="400"
                                     :options="createChartData(String(index)==='B2B' ? {...item.companyByType} : {...item.prospectByType}).chartOptions"
                                     :series="createChartData(String(index)==='B2B' ? {...item.companyByType} : {...item.prospectByType}).series"></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>

                 

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- fin prospect -->

      <!-- whatsapp  -->

      <!-- conversation total  -->
      <!-- conversation entame -->

      <!-- conversation non lue  -->

      <!-- conversation venant de partenaire -->

      <!-- fin whatapp -->

      <!-- activite par utlisateur  -->




    <div class="card shadow-sm ">
        <div class="card-header d-flex justify-content-center align-items-center">
          <h2 class="">Activités par utilisateur</h2>
        </div>
        <div class="card-body bg-white row row-cols-1 row-cols-md-3 g-4">


          <div class="col-3 card" v-for="(item, index) in dataDashboard.recapByUser" :key="index">
            <div class="card-header bg-info text-white">
              <user-view :value="String(index)" @input="onUserViewInput(index, $event)">

              </user-view>
            </div>

            <div class="card-body">
              <p>
                Prospects enregistré: <strong>{{item.prospectTotal.count}}</strong>
              </p>

              <p>
                Entreprises enregistré: <strong>{{item.companyTotal.count}}</strong>
              </p>

              <p>
                Prise de contact effectué: <strong>{{item.handshake.count}}</strong>
              </p>
            
            
            </div>


            <div class="card-footer d-flex justify-content-center align-items-center">
              <button  @click="ViewRecap(index)" class="btn btn-link"> Voir plus</button>




              <b-modal :id="'showrecap' + index" :title="'Recapitulatif de ' + index" size="xl" class="modal-elegant">
                <div class="p-4 row">
                 
                  <div class="card card-custom   flex-grow-1 col-6">
                    <div class="card-header bg-primary border-0 py-5">
                      <h3 class="card-title fw-bolder fs-2 text-white  flex-grow-1">
                        <div>Prise de contact selon les methode</div>
                      </h3>
                      <!-- <div class="card-toolbar"></div> -->
                    </div>
                    <div class="card-body d-flex flex-column " style="position: relative">
                      <div
                          class="card-spacer  card-rounded flex-grow-1  align-items-center">
                        <div v-if="createChartData({...item.handshake.byMethod})">
                          <apexchart type="treemap" 
                                     :options="createChartData({...item.handshake.byMethod}).chartOptions"
                                     :series="createChartData({...item.handshake.byMethod}).series"></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card card-custom   flex-grow-1 col-6">
                    <div class="card-header bg-primary border-0 py-5">
                      <h3 class="card-title fw-bolder fs-2 text-white  flex-grow-1">
                        <div>Prise de contact par etapes d'avancement </div>
                      </h3>
                      <!-- <div class="card-toolbar"></div> -->
                    </div>
                    <div class="card-body d-flex flex-column " style="position: relative">
                      <div
                          class="card-spacer  card-rounded flex-grow-1  align-items-center">
                        <div v-if="createChartData({...item.handshake.byStep})">
                          <apexchart type="treemap" 
                                     :options="createChartData({...item.handshake.byStep}).chartOptions"
                                     :series="createChartData({...item.handshake.byStep}).series"></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div v-if="item.companyTotal.count > 0" class="card card-custom   flex-grow-1 col">
                    <div class="card-header bg-primary border-0 py-5">
                      <h3 class="card-title fw-bolder fs-2 text-white  flex-grow-1">
                        <div >Entreprise enregistré par impression</div>
                      </h3>
                    </div>
                    <div class="card-body d-flex flex-column " style="position: relative">
                      <div
                          class="card-spacer  card-rounded flex-grow-1  align-items-center">
                        <div v-if="createChartData({...item.companyTotal.companyByType} )">
                          <apexchart type="treemap" 
                          height="400"
                                     :options="createChartData({...item.companyTotal.companyByType}).chartOptions"
                                     :series="createChartData({...item.companyTotal.companyByType}).series"></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div v-if="item.prospectTotal.count > 0" class="card card-custom   flex-grow-1 col">
                    <div class="card-header bg-primary border-0 py-5">
                      <h3 class="card-title fw-bolder fs-2 text-white  flex-grow-1">
                        <div>Prospect enregistré par impression</div>
                      </h3>
                    </div>
                    <div class="card-body d-flex flex-column " style="position: relative">
                      <div
                          class="card-spacer  card-rounded flex-grow-1  align-items-center">
                        <div v-if="createChartData( {...item.prospectByType})">
                          <apexchart type="treemap" 
                          height="400"
                                     :options="createChartData({...item.prospectTotal.prospectByType}).chartOptions"
                                     :series="createChartData({...item.prospectTotal.prospectByType}).series"></apexchart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-modal>



            </div>
          </div>

          

        </div>
      </div>

      <!-- user  -->

      <!-- prise de contact effectue (voir plus pour les type de prise effectue ) -->
      <!-- prospect enregistrer  -->
      <!-- entreprise enregistre -->
      <!-- conversation entamee -->

      <!-- fin user  -->

      <!-- fin conversation par utlisateur  -->

    </div>
    </template>
  </Base>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import Base from "@/layouts/Base.vue";

import DateRangePicker from "vue2-daterange-picker";
import CONSTANTS from "@/Utils/constant";
import { helper } from "@/services/Helper";
import { api } from "@/services/Api";
import UserView from "@/components/UserView.vue";

@Component({
  components: {
    Base,
    DateRangePicker,
    UserView
  },
})
export default class SellDashboard extends Vue {
  onUserViewInput(index: number, value: any) {
    console.log(`User view input for index ${index}:`, value);
  }
  dataDashboard: any = null;

  async load() {
    this.$store.commit("loading");

    const res = await api.post(api.form, `data/dashboard/prospection`, {
      start: this.range.startDate,
      end: this.range.endDate,
    });

    if (res && res.data) {
      this.dataDashboard = res.data;
    }
    this.$store.commit("stopLoading");
  }

  localData = {
    direction: "ltr",
    format: "mm/dd/yyyy",
    separator: " - ",
    applyLabel: "Valider",
    cancelLabel: "Annuler",
    weekLabel: " ",
    customRangeLabel: "Custom Range",
    daysOfWeek: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    monthNames: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Aout",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ],
    firstDay: 0,
  };
  loadRanges() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    this.ranges = {
      Aujourdhui: [today, today],
      Hier: [yesterday, yesterday],
      "Ce mois": [
        CONSTANTS.dateConstants.first_day_current_month(),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Le mois dernier": [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0),
      ],
      "Dernier Trimestre": [
        CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 2)),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Dernier Semestre": [
        CONSTANTS.dateConstants.first_day(helper.substractMonth(today, 5)),
        CONSTANTS.dateConstants.last_day_current_month(),
      ],
      "Cette année": [
        new Date(today.getFullYear(), 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],
      "L'année passée": [
        new Date(today.getFullYear() - 1, 0, 1),
        new Date(today.getFullYear() - 1, 11, 31),
      ],
      "2 Dernières années": [
        new Date(today.getFullYear() - 1, 0, 1),
        new Date(today.getFullYear(), 11, 31),
      ],

      "Cette semaine": [
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 1
        ), // Lundi de cette semaine
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 7
        ), // Dimanche de cette semaine
      ],

      "La semaine dernière": [
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() - 6
        ), // Lundi de la semaine dernière
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay()
        ), // Dimanche de la semaine dernière
      ],
    };
    this.$forceUpdate();
  }
  ranges: any = false;
  range = {
    startDate: CONSTANTS.dateConstants.getLast7DaysInterval().start,
    endDate: CONSTANTS.dateConstants.getLast7DaysInterval().end,
  };

  ViewRecap(index:any){
    this.$bvModal.show("showrecap" + index);

this.$forceUpdate;
  }

  createChartData(data:any){

    console.log(data,'data');

    
    return{
      series: [
        {
          data:
              data &&
              Object.entries(data).map(([key, value]: [string, unknown], index: number) => ({
                x: key.toUpperCase(),
                y: value,
              })),
        },
      ],


      chartOptions: {
        chart: {
          // width: 380,
          type: "treemap",
        },
        xaxis: {
          categories:
          data &&
          Object.entries(data).map(([key, value]: [string, unknown], index: number)  => key),
        },

        theme: {
          monochrome: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '15px',
          },
          formatter: function (text: any, op: { value: any; }) {
            return [text, op.value]
          },
          offsetY: -4
        },



        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
    }
  }

}

  async mounted() {
    this.load();

    // this.managers = await api.loadManagers();
    this.loadRanges();
  }
}
</script>

<style scoped></style>
